$footer-color: #f8f8f8;
$side-margin: 100px;

.footer {
    background-color: $footer-color;
    padding: 75px 0;
    &__inner {
        display: flex;
        align-items: flex-start;
        position: relative;
    }
    &__nav {
        display: flex;
        margin-right: $side-margin;
    }
    &__list {
        min-width: 240px;
        list-style-type: none;
        margin: 0;
        padding: 0;
        & + & {
            margin-left: $side-margin;
        }
        &_item {
            line-height: 14px;
            & + & {
                margin-top: 15px;
            }
        }
        &_title {
            display: flex;
            align-items: center;
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 18px;
            line-height: 22px;
            padding-bottom: 5px;
        }
        &_link, a {
            color: $nightrider;
            @extend %roboto;
            font-size: 16px;
            line-height: 20px;
            &:hover {
                color: $blue;
                text-decoration: none;
            }
        }
    }
    &__column {
        display: flex;
        flex-direction: column;
        margin-top: auto;
    }
    &__rights {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        line-height: 21px;
        display: flex;
        justify-content: center;
        max-width: 240px;
    }
    &__links {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }
    &__link {
        & + & {
            margin-top: 15px;
        }
    }
    &__social {
        position: absolute;
        bottom: 0;
        left: 0;
        &_title {
            color: $nightrider;
            @extend %roboto;
            font-size: 13px;
            line-height: 17px;
        }
        &_links {
            display: flex;
            margin-top: 10px;
        }
        &_link {
            display: block;
            width: 40px;
            height: 40px;
            & + & {
                margin-left: 15px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .footer {
        padding: 35px 0 40px;
        &__inner {
            flex-direction: column;
        }
        &__nav {
            margin-right: 0;
        }
        &__column {
            margin-top: 30px;
        }
        &__rights {
            max-width: 460px;
            order: 2;
            margin-top: 15px;
        }
        &__links {
            margin-top: 0;
            order: 1;
        }
        &__social {
            bottom: 135px;
        }
    }
}

@media screen and (max-width: 620px) {
    .footer {
        &__list {
            & + & {
                margin-left: 50px;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .footer {
        padding: 25px 0;
        &__nav {
            flex-direction: column;
        }
        &__list {
            &_title {
                font-size: 16px;
                line-height: 20px;
                padding-bottom: 0;
            }
            &_link {
                font-size: 14px;
                line-height: 18px;
            }
            & + & {
                margin-top: 30px;
                margin-left: 0;
            }
        }
        &__column {
            order: 2;
            margin-top: 30px;
        }
        &__rights {
            margin-top: 30px;
        }
        &__social {
            position: static;
            margin-top: 30px;
        }
    }
}
