.printReturn {
    max-width: 1050px;
    color: #333;
    font-family: 'Roboto';
    padding-bottom: 80px;
    .line-number {
        font-size: 12px;
    }
    .uppercase {
        text-transform: uppercase;
    }
    .text--medium {
        font-family: 'Roboto-Medium';
    }
    .text--bold {
        font-family: 'Roboto-Bold';
    }
    .input {
        border-bottom: 1px solid #333;
        position: relative;
        &:after {
            content: attr(data-hint);
            font-size: 9px;
            position: absolute;
            top: 22px;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
        }
    }
    .width-220 {
        width: 220px;
    }
    .width-460 {
        width: 460px;
    }
}

/* printReturnCorner */
.printReturnCorner {
    display: inline-block;
    vertical-align: top;
    max-width: 90px;
    min-width: 90px;
    padding-right: 10px;
}
.printReturnCorner__title {
    font-size: 11px;
}
.printReturnCorner__status {
    font-size: 11px;
    margin-top: 16px;
}
.printReturnCorner__status span {
    border: 1px solid #333;
    padding: 0 10px;
    margin-left: 10px;
}
.printReturnCorner__info {
    font-size: 9px;
    margin-top: 22px;
}
.printReturnCorner__item + .printReturnCorner__item {
    margin-top: 2px;
}
/* /printReturnCorner */

/* printReturnDocInfo */
.printReturnDocInfo {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 0 0 5px 10px;
    border-left: 3px solid #333;
}
.printReturnDocInfo__shortlines {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;
}
.printReturnDocInfo__shortline {
    font-size: 12px;
}
.printReturnDocInfo__first {
    display: inline-block;
    min-width: 110px;
    max-width: 110px;
}
.printReturnDocInfo__shortline + .printReturnDocInfo__shortline {
    margin-top: 8px;
}
.printReturnDocInfo__shortline .inner-value {
    display: inline-block;
    text-align: center;
    font-size: 11px;
    min-width: 100px;
    border-bottom: 2px solid #333;
    margin: 0 5px;
}
.printReturnDocInfo__app {
    display: inline-block;
    vertical-align: top;
    font-size: 10px;
    margin-bottom: 3px;
}
.printReturnDocInfo__app span {
    display: block;
    text-align: right;
}
.printReturnDocInfo__number {
    position: absolute;
    top: 5px;
    right: -30px;
    font-size: 14px;
}
.printReturnDocInfo__lines {
    margin-top: 10px;
}
.printReturnDocInfo__line {
    font-size: 12px;
    white-space: nowrap;
    min-height: 17px;
}
.printReturnDocInfo__line + .printReturnDocInfo__line {
    margin-top: 5px;
}
.printReturnDocInfo__lineTitle {
    display: inline-block;
    vertical-align: top;
    min-width: 240px;
    max-width: 240px;
    margin-right: 10px;
    white-space: normal;
}
.printReturnDocInfo__lineValue {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    min-height: 17px;
    max-width: 620px;
    white-space: normal;
    border-bottom: 1px solid #333;
}
.printReturnDocInfo__line .line-number {
    margin-left: 10px;
}
/* /printReturnDocInfo */

/* printReturnTable */
.printReturnTable {
    max-width: 1040px;
    margin-left: 6px;
    font-size: 0;
}
.printReturnTable__firstCols {
    display: inline-block;
    vertical-align: top;
    max-width: 90px;
    min-width: 90px;
}
.printReturnTable__firstCols .printReturnTable__col:nth-child(1) {
    display: inline-block;
    vertical-align: top;
    min-width: 25px;
    max-width: 25px;
}
.printReturnTable__firstCols .printReturnTable__col:nth-child(2) {
    display: inline-block;
    vertical-align: top;
    min-width: 65px;
    max-width: 65px;
}
.printReturnTable__firstCols + .printReturnTable__col {
    border-left: 3px solid #333;
    margin-left: -2px;
}
.printReturnTable__col {
    display: inline-block;
    vertical-align: top;
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    font-size: 0;
}
.printReturnTable__headline .printReturnTable__col {
    text-align: center;
}
.printReturnTable__headline > .printReturnTable__col,
.printReturnTable__headline > .printReturnTable__firstCols {
    min-height: 150px;
    height: 150px;
    line-height: 150px;
}
.printReturnTable__headline .printReturnTable__col span {
    display: inline-block;
    vertical-align: middle;
    line-height: 16px;
    word-break: break-word;
}
.cols .printReturnTable__col span {
    font-size: 9px;
}
.cols > .printReturnTable__col:nth-child(2) {
    min-width: 120px;
    max-width: 120px;
}
.cols > .printReturnTable__col:nth-child(3) {
    min-width: 30px;
    max-width: 30px;
}
.cols > .printReturnTable__col:nth-child(4) {
    min-width: 110px;
    max-width: 110px;
}
.cols > .printReturnTable__col:nth-child(5) {
    min-width: 40px;
    max-width: 40px;
}
.cols > .printReturnTable__col:nth-child(6) {
    min-width: 50px;
    max-width: 50px;
}
.cols > .printReturnTable__col:nth-child(7) {
    min-width: 80px;
    max-width: 80px;
}
.cols > .printReturnTable__col:nth-child(8) {
    min-width: 60px;
    max-width: 60px;
}
.cols > .printReturnTable__col:nth-child(9) {
    min-width: 60px;
    max-width: 60px;
}
.cols > .printReturnTable__col:nth-child(10) {
    min-width: 60px;
    max-width: 60px;
}
.cols > .printReturnTable__col:nth-child(11) {
    min-width: 80px;
    max-width: 80px;
}
.cols > .printReturnTable__col:nth-child(12) {
    min-width: 110px;
    max-width: 110px;
}
.cols > .printReturnTable__col:nth-child(13) {
    min-width: 100px;
    max-width: 100px;
}
.cols > .printReturnTable__col:nth-child(14) {
    min-width: 50px;
    max-width: 50px;
}
.cols > .printReturnTable__col:last-child {
    border-right: 1px solid #333;
}
.printReturnTable__headline .printReturnTable__colRow {
    font-size: 0;
}
.printReturnTable__headline .printReturnTable__colRow:nth-child(1) {
    height: 50px;
    line-height: 50px;
}
.printReturnTable__headline .printReturnTable__colRow:nth-child(2) {
    height: 100px;
    line-height: 100px;
}
.printReturnTable__headline .printReturnTable__colRow > div:nth-child(1) {
    border-left: 0;
    width: 28%;
}
.printReturnTable__headline .printReturnTable__colRow > div:nth-child(2) {
    width: 72%;
}
.printReturnTable__headline .printReturnTable__colRow span {
    word-break: break-word;
}
.printReturnTable__indexes span {
    display: inline-block;
    width: 100%;
    text-align: center;
}
.printReturnTable__indexes .printReturnTable__colRow,
.printReturnTable__content .printReturnTable__colRow {
    display: inline-block;
    vertical-align: top;
}
.printReturnTable__indexes .printReturnTable__colRow .printReturnTable__col,
.printReturnTable__content .printReturnTable__colRow .printReturnTable__col {
    width: 100%;
    border-top: 0;
}
.printReturnTable__indexes .printReturnTable__colRow:nth-child(1),
.printReturnTable__content .printReturnTable__colRow:nth-child(1) {
    width: 28%;
}
.printReturnTable__indexes .printReturnTable__colRow:nth-child(2),
.printReturnTable__content .printReturnTable__colRow:nth-child(2) {
    width: 72%;
}
.printReturnTable__indexes .printReturnTable__colRow:nth-child(1) .printReturnTable__col,
.printReturnTable__content .printReturnTable__colRow:nth-child(1) .printReturnTable__col {
    border-left: 0;
}
.printReturnTable__content span {
    display: inline-block;
    padding: 2px;
    word-break: break-all;
}
.printReturnTable__item .printReturnTable__col {
    min-height: 27px;
    max-height: 27px;
    overflow: hidden;
}
.printReturnTable__item span {
    display: inline-block;
}
/* /printReturnTable */

/* total */
.printReturnTable__total {
    border-width: 1px;
    border-color: #333;
    border-style: solid;
    border-right: 0;
    border-left: 0;
    max-width: 1039px;
    height: 20px;
}
.printReturnTable__total .printReturnTable__firstCols {
    height: 100%;
}
.printReturnTable__total .printReturnTable__col {
    border-top: 0;
    height: 100%;
}
.printReturnTable__total .printReturnTable__firstCols .printReturnTable__col {
    width: 100%;
    max-width: 100%;
}
.printReturnTable__total .printReturnTable__col:nth-child(2) {
    min-width: 260px;
    max-width: 260px;
}
.printReturnTable__total .printReturnTable__col:nth-child(3) {
    min-width: 40px;
    max-width: 40px;
}
.printReturnTable__total .printReturnTable__col:nth-child(4) {
    min-width: 50px;
    max-width: 50px;
}
.printReturnTable__total .printReturnTable__col:nth-child(5) {
    min-width: 80px;
    max-width: 80px;
}
.printReturnTable__total .printReturnTable__col:nth-child(6) {
    min-width: 120px;
    max-width: 120px;
}
.printReturnTable__total .printReturnTable__col:nth-child(7) {
    min-width: 60px;
    max-width: 60px;
}
.printReturnTable__total .printReturnTable__col:nth-child(8) {
    min-width: 80px;
    max-width: 80px;
}
.printReturnTable__total .printReturnTable__col:nth-child(9) {
    min-width: 260px;
    max-width: 260px;
}
/* /total */

/* subtable */
.printReturn__subtable {
    font-size: 0;
    margin-left: 6px;
    margin-bottom: 10px;
    height: 100px;
}
.printReturnSubtableCorner {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    min-width: 91px;
    max-width: 91px;
    font-size: 12px;
    line-height: 18px;
    padding-top: 3px;
    border-right: 3px solid #333;
}
.printReturnSubtable {
    display: inline-block;
    vertical-align: top;
    width: 940px;
    height: 100%;
    padding: 3px 0 0 3px;
    border-bottom: 3px solid #333;
}
.printReturnSubtable__row {
    font-size: 0;
}
.printReturnSubtable__col {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
}
.printReturnSubtable__col:first-child {
    margin-right: 20px;
}
.printReturnSubtable__line {
    min-height: 30px;
}
.printReturnSubtable__line + .printReturnSubtable__line {
    margin-top: 10px;
}
.printReturnSubtable__text {
    display: inline-block;
    vertical-align: top;
    max-width: 220px;
}
.printReturnSubtable__inputs {
    display: inline-block;
    vertical-align: top;
    height: 100%;
}
.printReturnSubtable__inputs .printReturnSubtable__input {
    display: inline-block;
    vertical-align: top;
}
.printReturnSubtable__input {
    height: 25px;
}
.printReturnSubtable__input::after {
    top: 28px !important;
}
.printReturnSubtable__inputs .printReturnSubtable__input:first-child {
    width: 100px;
}
.printReturnSubtable__inputs .printReturnSubtable__input:last-child {
    width: 130px;
}

/* printReturnAdditional */
.printReturnAdditional {
    color: #333;
    font-size: 12px;
    margin-bottom: 20px;
}
.printReturnAdditional__text,
.printReturnAdditional__input {
    display: inline-block;
    font-size: 12px;
}
.printReturnAdditional__text {
    vertical-align: top;
}
.printReturnAdditional__input {
    vertical-align: bottom;
}
.printReturnAdditional__line:nth-child(1) .printReturnAdditional__text {
    min-width: 350px;
}
.printReturnAdditional__line:nth-child(1) .printReturnAdditional__input {
    min-width: 640px;
}
.printReturnAdditional__line:nth-child(2) .printReturnAdditional__text {
    min-width: 220px;
}
.printReturnAdditional__line:nth-child(2) .printReturnAdditional__input {
    min-width: 770px;
    min-height: 15px;
}
.printReturnAdditional__line + .printReturnAdditional__line {
    margin-top: 16px;
}
/* /printReturnAdditional */

/* printReturn two columns */
.printReturn__row {
    max-width: 1040px;
}
.printReturn__col {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    position: relative;
}
.printReturn__col + .printReturn__col {
    padding-left: 10px;
    border-left: 3px solid #333;
}
.printReturnLine {
    font-size: 11px;
}
.printReturnLine + .printReturnLine {
    margin-top: 25px;
}
.printReturnLine__content {
    margin-top: 10px;
}
.printReturnLine__input {
    display: inline-block;
    vertical-align: top;
    min-width: 140px;
    min-height: 15px;
    margin-right: 10px;
}
.printReturnLine__inputs {
    display: inline-block;
    vertical-align: top;
    width: 470px;
}
.printReturnLine--row .printReturnLine__text,
.printReturnLine--row .printReturnLine__input {
    display: inline-block;
    vertical-align: top;
}
.printReturn__col:first-child .printReturnLine--row .printReturnLine__input {
    margin-right: 79px;
}
.printReturn__col:last-child .printReturnLine--row .printReturnLine__input {
    margin-right: 107px;
}
.printReturn__col:first-child:before,
.printReturn__col:last-child:before {
    content: 'М.П.';
    font-size: 14px;
    position: absolute;
    bottom: -40px;
}
.printReturn__col:first-child:before {
    left: 40px;
}
.printReturn__col:last-child:before {
    right: 40px;
}
/* /printReturn two columns */
