.headline-business-center {
    padding-top: 40px;
    &__title {
        padding-bottom: 20px;
        color: #b1b1b1;
        font-size: 28px;
        &Normal { @extend %roboto; }
        &Bold { @extend %roboto-bold; }
    }
    &__wrap {
        padding: 13px 20px;
        background-color: #f7f7f7;
    }
    &__navigation {
        display: flex;
        align-items: center;
        justify-content: start;
    }
    &__link {
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        & + & {
            margin-left: 30px;
        }
    }
}

.rolesCard {
    width: 445px;
    max-width: 445px;
    border: 1px solid #E5E5E5;
    border-radius: 2px;
    background-color: $white;
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 20px;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
    }
    &__fieldWrap {
        width: 240px;
        max-width: 240px;
    }
    &__field {
        padding-left: 4px;
        width: 100%;
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 20px;
        border: 1px solid transparent;
        outline: none;
        &:disabled {
            background-color: $white;
        }
    }
    &__content {
        display: none;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
    }
    &__contentWrap {
        padding: 20px 20px 25px 25px;
    }
    &__contentHide {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 20px;
        border-top: 1px solid #dfdfdf;
        cursor: pointer;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
    }
    &__items {
        margin-top: 10px;
    }
    &__group {
        padding-right: 30px;
        font-size: 0;
        & + & {
            margin-top: 15px;
        }
    }
    &__subgroup {
        padding-left: 30px;
        margin-top: 10px;
        & + & {
            margin-top: 20px;
        }
        .checkboxField {
            display: flex;
        }
        .checkboxField + .checkboxField {
            margin-top: 8px;
        }
    }
    &__subitems {
        padding-left: 30px;
        margin-top: 10px;
    }
    & + & {
        margin-top: 20px;
    }
    &.edit &__content {
        display: block;
    }
    &.edit &__field {
        border-color: #c1c1c1;
    }
    .js-scrolled-list {
        max-height: 420px;
    }
}

.add-role-btn {
    padding-right: 50px;
    padding-left: 50px;
    position: absolute;
    top: 0;
    right: 0;
}
