.header {
    margin-right: auto;
    margin-left: auto;
    background-color: $white;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.04);
    position: relative;
    z-index: 2000;
    &__top {
        padding: 10px 10px;
        text-align: center;
        background: #f7f7f7;
        transition: margin-top .2s ease, opacity .6s linear;
        &.hide {
            display: none;
            margin-top: -40px;
            opacity: 0;
            transition: margin-top .6s ease, opacity .2s linear;
        }
    }
    &__hidetop {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        transform: rotate(90deg);
        transition: all .3s ease;
        &.isHidden {
            transform: rotate(0);
        }
    }
    &__middle {
        display: flex;
        align-items: center;
        padding: 12px 25px;
        .hbtn {
            display: none;
            margin-bottom: 0;
        }
    }
    &__menuAndLogo {
        display: flex;
        align-items: center;
    }
    &__bottom {
        display: flex;
        align-items: flex-end;
        padding: 0 25px 15px;
    }
    &__deliveryToSelectors {
        display: flex;
    }
    &__bottomContent {
        display: flex;
        align-items: flex-end;
    }
    &__greetings {
        color: $dimgray;
        @extend %roboto;
        font-size: 16px;
        line-height: 20px;
        a {
            display: inline-block;
            color: $dimgray;
            line-height: 16px;
            border-bottom: 1px solid $nobel;
            @extend %hovered-lighter;
            &:hover {
                color: $dimgray;
                //border-color: transparent;
                text-decoration: none;
            }
        }
        span {
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    &__logo {
        display: flex;
        width: 200px;
        min-width: 200px;
        height: 40px;
        min-height: 40px;
        max-width: 200px;
        max-height: 40px;
        cursor: pointer;
        margin-right: 40px;
    }
    &__img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        @extend %hovered-lighter;
    }
    &__search {
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        &_categories {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            color: $dimgray;
            @extend %roboto-medium;
            font-size: 16px;
            white-space: nowrap;
            background-color: #f7f7f7;
            border-radius: 2px;
            position: absolute;
            top: 4px;
            left: 4px;
            cursor: pointer;
            i {
                margin-right: 12px;
            }
        }
        &_input {
            width: 100%;
            padding: 8px 46px 8px 166px;
            border: 1px solid #CBCBCB;
            border-radius: 4px;
            color: $dimgray;
            @extend %roboto;
            font-size: 16px;
            outline: none;
        }
        &_btn {
            width: 22px;
            min-width: 22px;
            height: 22px;
            min-height: 22px;
            padding: 0;
            border: 0;
            @extend %search-gray-icon;
            @extend %hovered-lighter;
            outline: none;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 12px;
        }
    }
    &__nav {
        display: flex;
        align-items: center;
        position: relative;

        &_item {
            display: flex;
            align-items: center;
            padding: 0 30px;
            & + & {
                border-left: 1px solid #e6e6e6;
            }
        }

        &_link {
            display: flex;
            align-items: center;
            color: $nightrider;
            @extend %roboto-medium;
            @extend %hovered-lighter;
            font-size: 16px;
            &:hover {
                color: $nightrider;
                text-decoration: none;
            }
            i {
                color: $nightrider;
                &:hover {
                    color: $nightrider;
                }
            }
            .arrow-down-icon {
                margin-left: 10px;
            }
            &Text{
                margin-left: 10px;
            }
        }
    }
    &__number {
        color: $pumpkin;
        @extend %roboto-medium;
        font-size: 16px;
        margin-left: 5px;
    }
    &__location {
        min-width: 140px;
        max-width: 280px;
        position: relative;
        &_text {
            display: flex;
            align-items: center;
            color: $nobel;
            @extend %roboto;
            font-size: 13px;
            line-height: 18px;
            white-space: nowrap;
            margin-left: 28px;
            position: relative;
            span {
                max-width: 150px;
                padding-left: 8px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        &_address {
            display: flex;
            align-items: center;
            margin-top: -2px;
            white-space: nowrap;
            overflow: hidden;
        }
        & + & {
            margin-left: 20px;
        }
    }
    &__address {
        display: flex;
        width: 100%;
        margin-left: 28px;
        a {
            width: 100%;
        }
        & + i {
            position: absolute;
            right: -20px;
        }
    }
    &__links {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 30px;
    }
    &__link {
        display: flex;
        align-items: center;
        max-width: 270px;
        color: $nightrider;
        @extend %roboto-medium;
        @extend %hovered-lighter;
        font-size: 15px;
        line-height: 15px;
        white-space: nowrap;
        span {
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:hover {
            color: $nightrider;
            text-decoration: none;
        }
        i {
            margin-left: 8px;
        }
        & + & {
            margin-left: 35px;
        }
    }
    i + &__address {
        margin-left: 12px;
    }
}

// burger
.hbtn {
    min-width: 27px;
    max-width: 27px;
    min-height: 21px;
    max-height: 21px;
    cursor: pointer;
    background: url(../img/burger.svg) no-repeat;
    margin-right: 30px;
    margin-bottom: 3px;
    @extend %hovered-lighter;
}

$hmenu-width: 365px;
$hmenu-item-padding: 15px 20px 15px 35px;

.hmenu {
    width: 100%;
    min-width: $hmenu-width;
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: $hmenu-width;
    &-container {
        display: flex;
        background-color: $white;
        position: fixed;
        z-index: 99999;
        top: 0;
        left: -$hmenu-width;
        width: $hmenu-width;
        height: 100vh;
        transition: 0.2s all ease;
        &.show {
            left: 0;
        }
    }
    &-container.show &-close-btn {
        visibility: visible;
    }
    &-content {
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    &-main {
        position: static;
        margin-left: -$hmenu-width;
        &-title {
            width: 100%;
            padding: 15px 35px;
            color: $white;
            @extend %roboto-medium;
            font-size: 14px;
            background-color: $nightrider;
            text-transform: uppercase;
        }
    }
    li {
        width: 100%;
        display: flex;
        align-items: center;
    }
    &-title {
        width: 100%;
        color: $nobel;
        @extend %roboto;
        font-size: 14px;
        padding: $hmenu-item-padding;
    }
    &-back-btn {
        width: 100%;
        display: flex;
        align-items: center;
        padding: $hmenu-item-padding;
        color: $white;
        @extend %roboto-medium;
        font-size: 14px;
        text-transform: uppercase;
        background-color: $nightrider;
        &:before {
            content: '';
            display: block;
            width: 20px;
            height: 12px;
            @extend %arrow-back-white-icon;
            margin-right: 8px;
        }
        &:hover {
            color: $white;
            text-decoration: none;
        }
    }
    &-close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        min-width: 22px;
        height: 22px;
        min-height: 22px;
        max-width: 22px;
        max-height: 22px;
        margin: 15px 0 0 15px;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: $hmenu-width;
        &:before, &:after {
            content: '';
            display: block;
            background-color: $white;
            width: 22px;
            height: 3px;
            position: absolute;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    &-item, &-item-link {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $hmenu-item-padding;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        &:hover {
            color: $nightrider;
            background-color: #f3f3f3;
            text-decoration: none;
        }
    }
    &-main &-item {
        &:after {
            content: '';
            display: block;
            width: 9px;
            height: 14px;
            @extend %arrow-right-gray-icon;
        }
        &:hover {
            &:after {
                @extend %arrow-right-black-icon;
            }
        }
    }
    &-visible {
        margin-left: 0;
        left: 0;
        transition: 0.5s all ease;
    }
}

body.lock-position {
    overflow: hidden;
    &:after {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4000;
        width: 100vw;
        height: 100vh;
        background: $nightrider;
        opacity: 0.6;
    }
}
// /burger

// popups in header
.hNonAuthPopup {
    display: none;
    align-items: flex-start;
    padding: 25px;
    border: 1px solid #DEDEDE;
    border-radius: 2px;
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 100;
    position: absolute;
    top: 50px;
    right: 300px;
    &.show {
        display: flex;
    }
    &__common {
        padding-right: 40px;
        border-right: 1px solid #e9e9e9;
    }
    &__auth {
        padding-left: 20px;
        padding-right: 20px;
        .btn-theme {
            margin-bottom: 12px;
        }
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 18px;
    }
    &__text, &__link {
        display: block;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 18px;
        white-space: nowrap;
        max-width: 127px;
        overflow: hidden;
        text-overflow: ellipsis;
        @extend %hovered-lighter;
        &:hover {
            color: $nightrider;
            text-decoration: none;
        }
    }
    &__common &__item + &__item {
        margin-top: 10px;
    }
    &__auth &__text {
        margin: 4px 0;
    }
    &__auth &__link {
        text-decoration: underline;
    }
}

.hAccountPopup {
    display: none;
    align-items: flex-start;
    height: 310px;
    min-height: 310px;
    padding: 20px 25px;
    background-color: $white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    z-index: 100;
    position: absolute;
    top: 50px;
    right: 130px;
    &.show {
        display: flex;
    }
    &__list {
        display: flex;
        flex-direction: column;
        width: 150px;
        min-width: 150px;
        max-width: 150px;
        height: 100%;
        margin-right: 20px;
        overflow: hidden;
        overflow-y: auto;
        &:last-child {
            margin-right: 0;
        }
        & + & {
            padding-left: 20px;
            border-left: 1px solid #E9E9E9;
        }
        &.scroll-wrapper {
            padding-left: 20px !important;
        }
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 18px;
    }
    &__item {
        max-width: 200px;
        & + & {
            margin-top: 10px;
        }
    }
    &__link {
        display: block;
        min-width: 130px;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:hover {
            color: $blue;
            @extend %roboto-medium;
            text-decoration: none;
        }
        &.active {
            @extend %roboto-medium;
            pointer-events: none;
            text-decoration: none;
            &:hover {
                color: $nightrider;
            }
        }
    }
}

.headerPopup {
    height: 300px;
    min-height: 300px;
    &.btn-theme {
        width: 100%;
    }
    &__inner {
        display: flex;
        align-items: flex-start;
    }
    &__section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 200px;
        max-width: 200px;
        height: 100%;
        position: relative;
        & + & {
            min-width: 220px;
            max-width: 220px;
            margin-left: 20px;
            padding-left: 20px;
            border-left: 1px solid #E9E9E9;
        }
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        padding-right: 15px;
        & > div {
            margin-left: 8px;
        }
    }
    &__select {
        margin-bottom: 15px;
        padding-right: 15px;
    }
    &__items {
        padding-bottom: 10px;
        overflow: hidden;
        overflow-y: auto;
    }
    &__item {
        display: block;
        min-width: 130px;
        max-height: 40px;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        @extend %hovered-lighter;
        &.active {
            @extend %roboto-medium;
            text-decoration: none;
            &:hover {
                color: $nightrider;
            }
        }
        & + & {
            margin-top: 10px;
        }
    }
    &__list {
        height: 100%;
    }
    &__top {
        overflow: hidden;
        height: 100%;
        & + & {
            padding-top: 10px;
            border-top: 1px solid #E9E9E9;
            margin-top: 10px;
        }
    }
    &__contracts {
        border-bottom: 1px solid #E9E9E9;
        margin-bottom: 20px;
    }
    &__bottom {
        padding-top: 10px;
        border-top: 1px solid #E9E9E9;
    }
    &.show {
        display: flex;
    }
}

.height-180 { height: 180px !important; }
.height-130 { height: 130px !important; }

.hListPopup {
    display: none;
    padding: 12px 10px;
    min-width: 180px;
    position: absolute;
    top: 48px;
    left: 0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: $white;
    z-index: 100;
    &.show {
        display: flex;
    }
    &__list {
        width: 100%;
    }
    &__item {
        width: 100%;
        padding-bottom: 7px;
        & + & {
            padding-top: 7px;
            border-top: 1px solid #E9E9E9;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
    &__item.active &__link {
        color: #0f559c;
        @extend %roboto-medium;
    }
    &__link {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        &:hover {
            color: #0f559c;
            text-decoration: none;
        }
        &.active {
            @extend %roboto-medium;
            pointer-events: none;
            text-decoration: none;
            &:hover {
                color: $nightrider;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .header {
        &__nav {
            padding-left: 50px;
            &_item {
                padding: 0 25px;
                & + & {
                    border-left: 0;
                }
            }
            &_link {
                font-size: 0;
                i {
                    margin-right: 0;
                }
            }
        }
    }

    .hNonAuthPopup {
        right: 140px;
    }

    .hAccountPopup {
        right: 80px;
    }
}

@media screen and (max-width: 1200px) {
    .header {
        position: relative;
        box-shadow: none;
        &__middle {
            padding: 20px 18px 85px;
            .hbtn {
                display: block;
            }
        }
        &__bottom {
            display: none;
        }
        &__search {
            position: absolute;
            bottom: 18px;
            left: 15px;
            width: 100%;
            max-width: 100%;
            padding-right: 30px;
            &_btn {
                right: 42px;
            }
        }
        &__nav {
            margin-left: auto;
            &_item {
                padding-right: 20px;
                padding-left: 50px;
            }
        }
    }

    .hNonAuthPopup {
        top: 40px;
        right: 170px;
    }

    .hAccountPopup {
        top: 40px;
    }
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
    .header {
        &__top {
            display: block !important;
            padding: 0;
            position: absolute;
            top: 22px;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, 0);
            background: #fff;

            margin-top: 0 !important;
            opacity: 1 !important;
        }
        &__greetings {
            font-size: 16px;
            span {
                max-width: 120px;
            }
        }
        &__hidetop {
            display: none !important;
        }
        &__nav {
            &_item {
                padding-left: 10px;
            }
        }
    }
}

@media screen and (max-width: 959px) {
    .header {
        &__top {
            padding: 10px 10px;
        }
        &__greetings {
            text-align: center;
            font-size: 16px;
        }
        &__hidetop {
            top: 15px;
            &.isHidden {
                top: 10px;
            }
        }
        &__nav {
            padding-left: 0;
            &_item {
                padding-left: 40px;
                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .header {
        &__nav {
            &_item {
                padding-left: 10px;
            }
        }
    }
    .hAccountPopup {
        right: 0;
    }
}

@media screen and (max-width: 660px) {
    .header {
        &__middle {
            padding-bottom: 52px;
            display: block;
            .hbtn {
                margin-right: 0;
            }
        }
        &__logo {
            flex-grow: 1;
            max-width: none;
        }
        &__nav {
            justify-content: center;
            top: -24px;
            &_link {
                i {
                    &:first-child {
                        margin-right: 0;
                    }
                }
            }
            &_item {
                &:last-child {
                    padding-right: 0;
                }
                &:first-child {
                    padding-right: 13px;
                }
                .arrow-down-icon {
                    display: none;
                }
            }
        }
        &__search {
            position: relative;
            bottom: auto;
            top: 60px;
            left: auto;
            padding-right: 0;
            &_btn {
                right: 10px;
            }
            &_categories {
                display: none;
            }
            &_input {
                padding-left: 15px;
                border-width: 2px;
            }
        }
    }

    .hNonAuthPopup, .hAccountPopup {
        right: auto;
        left: 50%;
        margin-right: -50%;
        margin-left: 0;
        transform: translate(-50%,0);
    }

    .hNonAuthPopup {
        &__auth {
            padding-left: 0;
        }
    }

    .hAccountPopup {
        height: auto;
        flex-direction: column;
        &__list {
            & + & {
                padding-left: 0;
                margin-top: 12px;
                border-left: 0;
            }
        }
    }
}

@media screen and (max-width: 420px) {
    $hmenu-width: 270px;
    .hmenu-container {
        width: $hmenu-width;
    }
    .hmenu {
        min-width: $hmenu-width;
        left: $hmenu-width;
    }
    .hmenu-main {
        margin-left: -$hmenu-width;
    }
    .hmenu-visible {
        margin-left: 0;
        left: 0;
    }
    .hmenu-close-btn {
        left: $hmenu-width;
    }
}

@media screen and (max-width: 360px) {
    .header {
        &__logo {
            margin-left: 20px;
        }
        &__nav {
            justify-content: space-around;
            &_item {
                padding-right: 10px;
                &:first-child {
                    padding-right: 5px;
                }
            }
        }
    }

    .hNonAuthPopup {
        padding: 15px 10px;
        &__common {
            padding-right: 10px;
        }
        &__auth {
            padding-left: 10px;
        }
        &__link {
            max-width: 127px;
        }
    }
}
