.shopsHeadline {
    padding: 10px 0;
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__shop {
        @extend %roboto-bold;
    }
    &__dropdown {
        display: flex;
        align-items: center;
        min-width: 150px;
        max-width: 150px;
        .dropdownComponent {
            width: 100%;
        }
    }
    .dropdownComponent.mobile {
        display: none;
    }
}

.shopsContent {
    margin-top: 40px;
    &__inner {
        display: flex;
        align-items: flex-start;
    }
}

.shopsBrand {
    min-width: 255px;
    max-width: 255px;
    border-right: 1px solid $nobel;
    margin-right: 30px;
    &__title {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 16px;
    }
    &__list {
        margin-top: 10px;
    }
    &__item {
        & + & {
            margin-top: 8px;
        }
    }
    &__link {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        &:hover {
            text-decoration: none;
        }
    }
    &__more {
        display: inline-block;
        margin-top: 15px;
    }
}

.shopsProductItem {
    border-bottom: 1px solid $nobel;
    &__label {
        width: 94px;
        height: 27px;
        display: inline-block;
        padding-left: 10px;
        position: relative;
        color: $nightrider;
        @extend %roboto;
        font-size: 12px;
        line-height: 27px;
        background: url("../img/label.svg") 0 0 no-repeat;
    }
    &__label + &__inner {
        padding-top: 12px;
    }
    &__inner {
        display: flex;
        align-items: flex-start;
        padding: 30px 0;
    }
    &__imgWrap {
        min-width: 255px;
        min-height: 200px;
        max-width: 255px;
        max-height: 200px;
        margin-right: 30px;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
    }
    .productInfo {
        &__title {
            font-size: 16px;
            line-height: 22px;
        }
        &__from {
            margin-top: 5px;
        }
        &__price {
            margin-top: 16px;
        }
    }
    .shortInfoPrice {
        align-items: flex-end;
        &__value {
            line-height: 28px;
        }
    }
}