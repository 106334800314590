.login {
    margin-right: auto;
    margin-left: auto;
    .b-success, .b-error {
        margin-top: 0;
        &.hide {
            display: none;
        }
    }
}
.loginform {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    background-color: $white;
    border: 1px solid $verylightgrey;
    border-radius: 6px;
    padding: 15px 25px 20px;
    &-form {
        margin-top: 10px;
        &--sendagain{
            display: flex;
            justify-content: center;
            .link {
                border: none;
                background-color: $white;
            }
        }
    }
    &-notify {
        color: $seagreen;
        font-size: 12px;
    }
    &-field {
        position: relative;
        min-height: 72px;
        max-height: 80px;
    }
    &-label {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 13px;
        margin-bottom: 0;
    }
    &-input {
        background-color: $white;
        border: 1px solid $nobel;
        border-radius: 2px;
        font-size: 14px;
        width: 100%;
        padding: 6px 10px;
        margin-top: 2px;
        &:focus {
            border-color: $blue;
            box-shadow: 0px 0px 2px $blue;
            outline: none;
        }
        &[type=password] {
            padding-right: 120px;
        }
    }
    &-text {
        color: $nightrider;
        @extend %roboto;
        font-size: 12px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    &-additionally {
        border-top: 1px solid $verylightgrey;
        padding-top: 15px;
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        .link {
            margin-left: 20px;
        }
        &--centered {
            text-align: center;
            .link {
                margin-left: 0;
            }
        }
    }
    &-wrap {
        margin-top: 15px;
        .btn-theme {
            margin-bottom: 5px;
        }
    }
    &-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
    &-signin-btn {
        width: 160px;
    }
    &-signup-btn {
        width: 100%;
    }
    &-mark {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        position: relative;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
        span {
            display: block;
            padding: 0 8px;
            background-color: #fff;
            overflow: hidden;
            width: 110px;
            z-index: 1;
            position: relative;
            margin: 0 auto;
        }
        &:before, &:after {
            content: '';
            display: block;
            background-color: $verylightgrey;
            width: 95px;
            height: 1px;
            position: absolute;
            top: 9px;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }
    .field {
        margin-top: 6px;
        margin-bottom: 15px;
    }
    &-regnew {
        .btn-theme {
            width: 100%;
        }
    }
    .err-msg {
        font-size: 11px;
        margin-top: 2px;
    }
    .password-status {
        font-size: 12px;
        position: absolute;
        top: 35px;
        right: 15px;
    }
    .radioGroup {
        padding: 15px 0 30px;
    }
    .radioItem {
        background-color: $white;
        &__radio {
            margin-right: 10px;
        }
        &__label {
            padding: 0;
        }
        &__title {
            @extend %roboto;
            font-size: 15px;
            max-width: none;
            overflow: visible;
        }
        & + .radioItem {
            margin-top: 15px;
        }
    }
}

@media screen and (max-width: 360px) {
    .login {
        &form {
            padding-right: 15px;
            padding-left: 15px;
            &-btns {
                flex-direction: column;
                .btn-theme {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
            &-mark {
                margin-top: 20px;
            }
            &-additionally {
                .link {
                    margin-left: 10px;
                    white-space: nowrap;
                }
            }
        }
    }
}
