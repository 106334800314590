.btn-theme {
    @extend %roboto-medium;
    font-size: 13px;
    text-align: center;
    border-radius: 4px;
    padding: 8px 12px;
    min-width: 110px;
    cursor: pointer;
    border: 1px solid transparent;
    display: inline-block;
    user-select: none;
    white-space: nowrap;
    &:hover {
        text-decoration: none;
    }
    &:active, &:focus {
        outline: none;
    }
    &:disabled, &.disabled {
        opacity: .3;
        pointer-events: none;
    }
    &--action {
        background-image: linear-gradient(180deg, #3193E3 0%, #0066C1 100%);
        color: $white;
        min-width: 150px;
        &:hover {
            background-image: linear-gradient(180deg, #66B1EE 0%, #1D8EEE 100%);
            color: $white;
        }
    }
    &--gray {
        background: linear-gradient(180deg, #E9E9E9 0%, #B4B4B4 100%);
        border: 1px solid #CCC;
        color: $nightrider;
        &:hover {
            background: linear-gradient(to bottom, #e7eaf0, #d9dce1);
            color: $nightrider;
        }
    }
    &--blue {
        background-color: $white;
        color: $blue !important;
        border-color: $blue;
        border-width: 2px;
        min-width: 150px;
        &:hover {
            color: $blue;
            background-color: $white;
        }
    }
    &--volume {
        background: linear-gradient(180deg, #F9F9F9 0%, #EDEDED 100%);
        color: $nightrider;
        &:hover {
            color: $nightrider;
        }
    }
    &--white {
        color: $nightrider;
        background-color: $white;
        border-color: $nightrider;
        &:hover {
            color: $white;
            background-color: #66b1ee;
            background-image: linear-gradient(180deg, #66B1EE 0%, #1D8EEE 100%);
            border-color: #66b1ee;
        }
    }
    &--more {
        color: $nightrider;
        background-color: $white;
        border-color: #ddd;
        border-width: 2px;
        border-radius: 2px;
        &:hover {
            color: $white;
            background-color: #66b1ee;
            background-image: linear-gradient(180deg, #66B1EE 0%, #1D8EEE 100%);
            border-color: #66b1ee;
        }
    }
    &--lightblue {
        color: $white;
        background-color: #3D9CFF;
        &:hover {
            color: $white;
            background-color: $darkblue;
        }
    }
    &--darkblue {
        color: $white;
        background-color: $blue;
        &:hover {
            color: $white;
            background-color: $darkblue;
        }
    }
    &--whitesmoke {
        color: $nightrider;
        background-color: $whitesmoke;
        border-color: $verylightgrey;
        &:hover {
            color: $nightrider;
            background-color: $whisper;
        }
    }
    &--gradient {
        background-image: linear-gradient(180deg, #3193E3 0%, #0066C1 100%);
        box-shadow: 0px 2px 4px rgba(54, 116, 180, 0.25);
        width: 100%;
        color: $white !important;
        font-size: 16px;
        min-width: 100%;
        padding: 8px 16px;
        &:hover {
            transition: all .5s ease;
            background-image: linear-gradient(180deg, #66B1EE 0%, #1D8EEE 100%);
            box-shadow: 0px 2px 4px rgba(54, 116, 180, 0.25);
        }
    }

    &--gradient-gray {
        background: linear-gradient(180deg, #CBCBCB 0%, #9D9D9D 100%), #DCDCDC;
        box-shadow: 0px 2px 4px rgba(54, 116, 180, 0.25);
        width: 100%;
        color: $white;
        font-size: 16px;
        min-width: 100%;
        padding: 8px 16px;
        &:hover {
            transition: all .5s ease;
            background: linear-gradient(180deg, #CBCBCB 0%, #727272 100%), #DCDCDC;
        }
    }
    &--blueWhite {
        color: #1A6ABA;
        border: 2px solid #1A6ABA;
        background-color: $white;
        width: 100%;
        &:hover {
            color: #04407C;
            border-color: #04407C;
        }
    }
    &--searchtext {
        color: $nightrider;
        border-color: #ccc;
        border-radius: 2px;
        background-color: #eee;
    }
}

.btn-close {
    cursor: pointer;
    background: 0 0;
    border: 0;
    padding: 0;
    width: 28px;
    height: 28px;
    outline: none;
    position: relative;
    &:before, &:after {
        content: '';
        width: 20px;
        height: 2px;
        background-color: $nightrider;
        position: absolute;
        top: 20px;
        left: 0;
    }
    &:hover:before, &:hover:after {
        background-color: $nobel;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(135deg);
    }
    &:active, &:focus {
        outline: none;
    }
    &--lightblue {
        border: 1px solid #3398FF;
        font-size: 16px;
        background-color: #3398FF;
        color: $white;
        &:hover {
            background-color: #2777C9;
            color: $white;
        }
    }
}

.link {
    @extend %roboto;
    font-size: 14px;
    line-height: 16px;
    transition: all .3s ease;
    &:hover {
        text-decoration: none;
    }
    &--blue {
        color: $blue;
        &:hover {
            color: $blue;
        }
    }
    &--black {
        color: $nightrider;
        &:hover {
            color: $blue;
        }
    }

    &--blue-border {
        color: $blue;
        border-bottom: 1px solid $blue;
        &:hover {
            color: $blue;
            border-color: $blue;
        }
    }
    &--gray {
        color: $nobel;
        &:hover {
            color: $blue;
        }
    }
    &--gray-border {
        color: $dimgray;
        border-bottom: 1px solid #D9D9D9;
        &:hover {
            color: $blue;
            border-color: $blue;
        }
    }
    &--dark {
        color: $nightrider;
        &:hover {
            color: $nightrider;
        }
        @extend %hovered-lighter;
    }
    &--black-border {
        color: $nightrider;
        border-bottom: 1px solid $nightrider;
        &:hover {
            color: $blue;
            border-color: $blue;
        }
    }
    &--bold {
        @extend %roboto-medium;
        white-space: nowrap;
    }
}
