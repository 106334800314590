.b-title {
    color: $nightrider;
    @extend %roboto-medium;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 15px;
}

.s-title {
    color: $nightrider;
    @extend %roboto-medium;
    font-size: 24px;
    line-height: 26px;
    display: flex;
    align-items: center;
    .s-title-bold {
        @extend %roboto-bold;
        max-width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .s-title-regular {
        @extend %roboto;
    }
    .s-title-bold + .s-title-regular {
        display: flex;
        align-items: center;
        padding-left: 12px;
        &:before {
            content: '|';
            display: flex;
            margin-right: 12px;
        }
    }
    .s-title-regular + .s-title-bold {
        margin-left: 12px;
    }
}

.b-paragraph {
    color: $nightrider;
    @extend %roboto;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
}

.b-title-large {
    color: $nightrider;
    @extend %roboto-light;
    font-size: 36px;
    line-height: 44px;
}

.text-shadow {
    position: relative;
    overflow: hidden;
    z-index: 0;
    &:after {
        content: '';
        display: inline-block;
        background-image: linear-gradient(to right,rgba(255,255,255,0),#fff 100%);
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 100%;
    }
}
