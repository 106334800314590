.show-on-991 {
    display: none;
}

@media screen and (max-width: 1199px) {
    .cartPackageWrapper {
        margin-top: 95px;
    }

    .cartPackage {
        &__params {
            max-width: 400px;
        }
        &__comment {
            width: 100%;
            max-width: 260px;
        }
    }

    .cartProductItem {
        &__title {
            max-width: 300px;
        }
        &__amount {
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 991px) {
    .show-on-991 {
        display: block !important;
    }

    .cartPackageWrapper {
        margin-top: 80px;
    }

    .orderProcessingHeadline {
        .cartTotal {
            bottom: -50px;
        }
    }

    .cartPackage {
        & .lvl2 {
            left: 83px;
        }
        &__inner {
            flex-direction: column;
            padding: 25px 20px;
        }
        // &__info - in cart, &__wrap - in checkout
        &__info, &__wrap, &__part {
            width: 100%;
            padding-right: 0;
        }
        &__wrap, &__part {
            flex-direction: column;
        }
        &__params {
            max-width: 480px;
        }
        &__options {
            margin-top: 20px;
        }
        &__options &__links {
            align-items: flex-start;
            position: static;
        }
        &__param &__name {
            margin-right: 50px;
        }
        &__param &__links {
            position: static;
        }
        &__calculation {
            width: 100%;
            padding-left: 0;
            border-left: none;
            margin-top: 30px;
            position: relative;
        }
        &__numbers {
            padding-bottom: 0;
            border-bottom: none;
        }
        &__amount {
            margin-bottom: 10px;
            text-align: left;
            position: relative;
            &:after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: #ededed;
                position: absolute;
                top: 50%;
                right: 0;
            }
        }
        &__amount &__name {
            display: inline-block;
            padding-right: 10px;
            position: relative;
            background-color: $white;
            z-index: 1;
        }
        &__price {
            justify-content: flex-start;
        }
        &__price &__name {
            width: 125px;
            min-width: 125px;
            margin-right: 45px;
            white-space: nowrap;
        }
        &__totalWrap {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
        &__total {
            &Value {
                font-size: 26px;
            }
        }
        &__payment {
            align-items: flex-start;
            width: 130px;
            max-width: 130px;
            margin-right: 40px;
        }
        &__payment &__name + &__name {
            margin-top: 8px;
        }
        &__toggle {
            display: flex;
            align-items: center;
            padding: 12px 20px;
            border: 1px solid $dimgray;
            margin-right: 0;
            transform: none;
            border-radius: 4px;
            background-color: $white;
            left: auto;
            bottom: 25px;
            right: 20px;
            &:before {
                content: 'Показать товары';
                color: $nightrider;
                font-family: Roboto;
                font-size: 16px;
                margin-right: 20px;
            }
            i {
                transform: scale(0.8);
            }
        }
        &.open &__toggle {
            &:before {
                content: 'Скрыть';
            }
            i {
                transform: rotate(180deg) scale(0.8);
            }
        }
    }

    .productItemsHeadline {
        &__param:nth-child(1) {
            width: 170px;
        }
        &__param:nth-child(3) {
            display: none;
        }
        &__param:nth-child(4) {
            width: auto;
        }
    }

    .cartProductItem {
        padding: 18px 10px;
        position: relative;
        &__title {
            height: 40px;
            max-height: 40px;
            overflow: hidden;
        }
        &__links {
            display: none;
            margin-top: 70px;
        }
        &__link {
            display: inline-block;
            font-size: 14px;
        }
        &__imgWrap {
            width: 90px;
            min-width: 90px;
            height: 90px;
            min-height: 90px;
        }
        &__amount {
            display: flex;
            align-items: flex-start;
            position: absolute;
            top: 85px;
            left: 190px;
            &Value {
                margin-top: 0;
            }
        }
        &__price {
            padding-right: 0;
            width: auto;
            min-width: auto;
            text-align: right;
            &Value {
                &New {
                    font-size: 16px;
                }
                &Old {
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .orderProcessingHeadline {
        .cartTotal {
            width: 100%;
            max-width: 400px;
            justify-content: space-between;
            left: 0;
            bottom: -110px;
        }
        & + .cartPackageWrapper {
            margin-top: 180px;
        }
    }
    .cartInfo {
        display: block;
    }
    .cartWarnings {
        margin-right: 0;
    }
    .cartPackage {
        .lvl2 {
            left: -58px;
            top: 116px;
        }
        &__inner {
            padding-bottom: 125px;
        }
        &__links {
            align-items: flex-start;
            position: absolute;
            left: 20px;
            bottom: 20px;
        }
        &__calculation {
            padding-bottom: 15px;
            border-bottom: 1px solid #ededed;
        }
        &__toggle {
            bottom: 50px;
            &:before {
                font-family: Roboto-Medium;
                font-size: 14px;
            }
        }
    }

    .productItemsHeadline {
        display: none;
    }

    .cartProductWarning {
        margin: 15px 7px 0;
    }

    .cartProductItem {
        &__index {
            margin-right: 20px;
        }
        &__title {
            height: 40px;
            margin: 45px 0;
        }
        &__comment {
            margin-top: 10px;
        }
        &__links {
            margin-top: 20px;
        }
        &__link{
            & + & {
                &:before {
                    display: none;
                }
            }
        }
        &__amount {
            top: 108px;
            left: 185px;
        }
        &__price {
            display: flex;
            align-items: flex-start;
            max-width: 100%;
            position: absolute;
            top: 18px;
            left: 185px;
            flex-direction: column;
            justify-content: flex-start;
            &Value {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                margin-right: 12px;
                width: 150px;
            }
            &Discount {
                white-space: nowrap;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .cartPackage {
        .lvl2 {
            left: -70px;
        }
    }
    .orderProcessingHeadline {
        .cartTotal {
            width: 100%;
            padding: 15px 10px;
        }
    }

    .postponeHeadline {
        flex-direction: column;
        .cartTotal {
            width: 100%;
            max-width: 100%;
            justify-content: space-between;
            position: static;
            margin-top: 15px;
        }
    }

    .cartProductItem {
        &__title {
            width: 100%;
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 479px) {
    .cartTotal {
        &__price {
            max-width: 120px;
        }
    }
    .cartSlider {
        width: 220px;
        max-width: 220px;
        height: 220px;
        max-height: 220px;
        &Container {
            margin-left: 0;
        }
        &__picbox {
            width: 120px;
            height: 120px;
        }
    }
    .cartPackage {
        .lvl2 {
            left: -18px;
            top: 75px;
            width: 220px;
            height: 220px;
        }
        &__inner {
            padding: 20px 15px 200px;
        }
        &__param {
            flex-direction: column;
            & + & {
                margin-top: 12px;
            }
        }
        &__param &__name {
            margin-bottom: 8px;
        }
        &__param &__value {
            font-size: 16px;
        }
        &__subtext {
            min-height: auto;
            font-size: 15px;
        }
        &__links {
            left: 15px;
            bottom: 100px;
        }
        &__calculation {
            margin-top: 20px;
        }
        &__amount {
            margin-bottom: 20px;
            &:after {
                display: block;
                margin-top: 4px;
                position: static;
            }
        }
        &__amount &__name {
            font-size: 16px;
        }
        &__price {
            &Value {
                font-size: 18px;
            }
        }
        &__price &__name {
            width: 142px;
            margin-right: 20px;
            font-size: 16px;
        }
        &__total {
            &Value {
                font-size: 18px;
            }
        }
        &__payment {
            margin-right: 33px;
        }
        &__toggle {
            justify-content: center;
            left: 15px;
            bottom: 20px;
        }
        .optionsOfPackagePopup {
            top: 100px;
            left: 0;
        }
    }

    .cartProductItem {
        padding-right: 0;
        padding-left: 0;
        &__info {
            width: 100%;
        }
        &__index {
            width: 26px;
            min-width: 26px;
            height: 26px;
            min-height: 26px;
            margin-top: 0;
            margin-right: 10px;
        }
        &__imgWrap {
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            margin-right: 15px;
        }
        &__amount {
            left: 92px;
            .numUpDown {
                width: 60px;
                margin: 0;
                &__control {
                    display: none;
                }
            }
        }
        &__price {
            left: 100px;
        }
    }
}

@media screen and (max-width: 376px) {
    .cartPackage {
        .lvl2 {
            left: -11px;
        }
    }
}
