.orderDetails {
    display: flex;
    align-items: flex-start;
    width: 100%;
    min-height: 500px;
    position: relative;
    background-color: $white;
    &__inner {
        max-width: 502px;
        margin-right: 110px;
        .orderDetailsProgressWrap {
            display: none;
        }
    }
    &__title {
        display: flex;
        align-items: flex-end;
        color: $nightrider;
        &Name {
            @extend %roboto-medium;
            font-size: 26px;
            line-height: 30px;
            padding-right: 8px;
        }
        &Date {
            @extend %roboto-medium;
            font-size: 22px;
            line-height: 26px;
            padding-right: 8px;
        }
    }
    .b2b {
        &:after {
            width: 50px;
            height: 30px;
            font-size: 14px;
            line-height: 30px;
        }
    }
    &__subtitle {
        color: $dimgray;
        @extend %roboto;
        font-size: 16px;
        line-height: 18px;
        margin-top: 5px;
    }
    &__return {
        display: flex;
        align-items: flex-start;
        margin: 40px 0;
        &Info {
            flex: 1;
        }
        &Text, &Number {
            color: $nightrider;
            @extend %roboto-medium;
        }
        &Text {
            max-width: 190px;
            padding-right: 20px;
            font-size: 14px;
            line-height: 18px;
        }
        &Number {
            font-size: 16px;
            line-height: 18px;
        }
        &Date {
            color: $nightrider;
            @extend %roboto;
            font-size: 13px;
            line-height: 15px;
            margin-top: 3px;
        }
        &Btn {
            margin-top: 11px;
            .btn-theme {
                width: 100%;
            }
        }
    }
    &__groups {
        padding-bottom: 30px;
        margin-top: 25px;
    }
    &__group {
        & + & {
            margin-top: 25px;
        }
    }
    &__param {
        display: flex;
        align-items: flex-start;
        & + & {
            margin-top: 8px;
        }
    }
    &__name {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 16px;
        min-width: 190px;
        padding-right: 20px;
    }
    &__value {
        min-width: 110px;
        color: $dimgray;
        @extend %roboto;
        font-size: 14px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        &Wrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    }
    &__paytype &__valueWrap {
        flex-wrap: nowrap;
    }
    &__link {
        display: flex;
        align-items: center;
        margin-left: 5px;
    }
    &__total {
        display: flex;
        align-items: center;
        padding-top: 15px;
        border-top: 1px solid $nobel;
        &Name, &Value {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 16px;
            line-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 10px;
        }
    }
    &__cancelOrder {
        color: $blue;
        @extend %roboto-medium;
        font-size: 13px;
        margin-left: auto;
        white-space: nowrap;
        &:hover {
            text-decoration: none;
            color: $blue;
        }
    }
    .red-circle-inner {
        .orderDetails {
            &__value:last-child {
                display: flex;
                align-items: center;
                &:after {
                    content: '';
                    display: block;
                    min-width: 12px;
                    min-height: 12px;
                    max-width: 12px;
                    max-height: 12px;
                    background-color: #F24C4C;
                    border-radius: 50%;
                    margin-left: 7px;
                }
            }
        }
    }
}

%beginProgressIndicator {
    @extend %progress-begin-active;
    @extend %pointProgressIndicator;
}

%inActiveProgressIndicator {
    @extend %progress-begin;
    @extend %pointProgressIndicator;
}

%pointProgressIndicator {
    width: 10px !important;
    min-width: 10px !important;
    height: 10px;
    min-height: 10px;
    border-radius: 20px;
    position: absolute;
}

// Progress Container
.orderDetailsProgress {
    width: 100%;
    max-width: 420px;
    padding: 22px 20px 25px;
    border: 1px solid #EDEDED;
    background-color: $white;
    border-radius: 8px;
    &__content {
        // last progress bar container
        &:last-child {
            .orderProgressBar {
                &:after {
                    content: '';
                    @extend %inActiveProgressIndicator;
                    top: -2px;
                    right: -2px;
                }
            }
            @for $i from 2 through 4 {
                .orderProgressBar.step-#{$i} {
                    @for $j from 1 through $i {
                        $step: $j - 1;
                        $prev: $step - 1;
                        .orderProgressBar__marker:nth-child(#{$prev}) + .orderProgressBar__marker:last-child:not(:only-child) {
                            background: #54a8ff;
                            &:after {
                                content: '';
                                @extend %beginProgressIndicator;
                                top: -2px;
                                right: -2px;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
        // first progress bar container
        &:first-child {
            .orderProgressBar {
                &:before {
                    content: '';
                    @extend %beginProgressIndicator;
                    top: -2px;
                    left: -2px;
                    z-index: 1;
                }
            }
        }
        & + & {
            padding-top: 20px;
            border-top: 1px solid #f1f1f1;
            margin-top: 20px;
            .orderProgressBar {
                &:before {
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: 2px;
                    width: 6px;
                    min-width: 6px;
                    height: 8px;
                    min-height: 8px;
                    @extend %arrow-xs;
                    z-index: 1;
                }
                &.step-1, &.step-2, &.step-3, &.step-4 {
                    &:before {
                        left: -3px;
                        width: 13px;
                        min-width: 13px;
                        height: 8px;
                        min-height: 8px;
                        @extend %arrow-border-before-xs;
                    }
                }
            }
        }
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        margin-bottom: 12px;
    }
    &Wrap {
        margin-top: 10px;
        &__btn {
            width: 100%;
            margin: 10px 0 20px;
        }
        &__text {
            color: #219653;
            @extend %roboto-medium;
            font-size: 16px;
            line-height: 18px;
            padding: 0 4px;
            margin-bottom: 20px;
        }
        &__btns {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 30px;
            .btn-theme {
                width: 100%;
            }
            .btn-theme + .btn-theme {
                margin-top: 20px;
            }
        }
    }
    .progressInfo {
        display: flex;
        align-items: flex-end;
        width: 360px;
        max-width: 360px;
        position: relative;
        &__step {
            min-width: 110px;
            max-width: 110px;
            display: flex;
            align-self: flex-start;
            flex-direction: column;
            position: relative;
            &:first-child {
                min-width: 100px;
                max-width: 100px;
            }
        }
        &__date {
            max-width: 60px;
            min-height: 40px;
            max-height: 40px;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            span {
                display: block;
                color: $nightrider;
                @extend %roboto;
                font-size: 12px;
                line-height: 18px;
            }
        }
        &__status {
            color: $nightrider;
            @extend %roboto;
            font-size: 12px;
            line-height: 16px;
            max-width: 80px;
            min-height: 32px;
            max-height: 32px;
            overflow: hidden;
            margin-top: 10px;
        }
    }
}

.orderProgressBar {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    max-width: 360px;
    position: relative;
    &__marker {
        width: 100%;
        height: 6px;
        position: relative;
        background-color: #E7F3FF;
        &:first-child {
            border-radius: 20px 0 0 20px;
        }
        &:last-child {
            border-radius: 0 20px 20px 0;
        }
    }
    &__filled {
        position: absolute;
        top: 0;
        right: 0;
        width: 6px;
        min-width: 6px;
        height: 6px;
        min-height: 6px;
        @extend %arrow-xs;
    }
    @for $i from 2 through 4 {
        &.step-#{$i} {
            @for $j from 1 through $i {
                $step: $j - 1;
                $prev: $step - 1;
                .orderProgressBar__marker:nth-child(#{$prev}) {
                    background: #54a8ff;
                }
                .orderProgressBar__marker:nth-child(#{$step}) {
                    background-image: linear-gradient(to right, #54a8ff 95%, #fff 100%);
                    .orderProgressBar__filled {
                        width: 8px;
                        min-width: 8px;
                        background: url("../img/sprite.svg") -50px -81px;
                    }
                }
            }
        }
    }
}
// /Progress Container

.orderProducts {
    margin-top: 25px;
    &__headline, &__controls {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 25px;
    }
    &__headline {
        padding-right: 204px;
    }
    &__controls {
        &--cancelled {
            justify-content: flex-start;
            .btn-theme {
                margin-left: 30px;
                background: linear-gradient(180deg, #F9F9F9 0%, #EDEDED 100%);
                color: $nightrider;
            }
        }
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 24px;
        margin-right: 40px;
    }
    &__btns {
        display: flex;
        align-items: center;
        .btn-theme {
            width: 300px;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__changes {
        display: flex;
        align-items: center;
        &-title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 16px;
            & + & {
                margin-left: 35px;
            }
        }
    }
    &Item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        border-top: 1px solid #e1e1e1;
        &__info {
            display: flex;
            align-items: center;
        }
        &__numbers {
            display: flex;
            align-items: center;
        }
        &__index {
            color: $nightrider;
            @extend %roboto;
            font-size: 20px;
            line-height: 22px;
            margin-right: 20px;
        }
        &__imgWrap {
            display: flex;
            align-items: center;
            align-self: flex-start;
            justify-content: center;
            min-width: 60px;
            width: 60px;
            height: 60px;
            max-width: 60px;
            max-height: 60px;
            margin-right: 15px;
            border: 1px solid #F4F4F4;
            border-radius: 4px;
            position: relative;
        }
        &__img {
            max-width: 100%;
            max-height: 100%;
        }
        &__title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
            max-width: 560px;
            padding-right: 20px;
            &:hover {
                color: $nightrider;
                text-decoration: none;
            }
        }
        &__amount, &__price, &__count {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
        }
        &__amount, &__price {
            padding-right: 50px;
        }
        &__amount {
            &:after {
                content: 'шт.';
                margin-left: 4px;
            }
        }
        &__price {
            min-width: 130px;
            text-align: right;
        }
        &__field {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
        }
        &__input {
            min-width: 53px;
            max-width: 53px;
            border: 1px solid #ddd;
            margin-right: 6px;
            border-radius: 4px;
            color: #D8D8D8;
            outline: none;
            text-align: center;
        }
        &__priceForItem {
            color: $dimgray;
            @extend %roboto;
            font-size: 14px;
            margin-top: 5px;
        }
        &:last-child {
            border-bottom: 1px solid #e1e1e1;
        }
    }
}

.deliveryInfoSection {
    margin-top: 15px;
    .orderDetailsProgressWrap {
        position: static;
    }
    .orderDetails {
        max-width: 100%;
        &__subtitle {
            margin-top: 15px;
        }
    }
    &__btn {
        margin-top: 15px;
        .btn-theme {
            width: 100%;
            max-width: 450px;
        }
    }
    &__address {
        padding: 20px 25px;
        border: 2px solid $nobel;
        margin-top: 30px;
        max-width: 450px;
        &Title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
            line-height: 16px;
        }
        &Value {
            color: $dimgray;
            @extend %roboto;
            font-size: 14px;
            line-height: 18px;
            margin-top: 10px;
        }
    }
    &__signInBtn {
        position: absolute;
        top: 0;
        right: 0;
        .btn-theme {
            width: 255px;
        }
    }
    &__signIn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        margin-top: 50px;
        border-top: 1px solid #F1F1F1;
        .btn-theme {
            width: 190px;
        }
    }
}

.orderCancelled {
    max-width: 1110px;
    padding: 25px 30px 20px;
    margin-top: 15px;
    background-color: #F5F4F4;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    &__title {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 20px;
    }
}

.returnProduct {
    min-height: 700px;
    position: relative;
    margin-top: 30px;
    &__headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        line-height: 22px;
        max-width: 260px;
    }
    &__items {
        margin-top: 50px;
        position: relative;
        &:before {
            content: 'Вернуть';
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 16px;
            line-height: 18px;
            position: absolute;
            top: -25px;
            right: 25px;
        }
    }
    &Warning {
        max-width: 310px;
        padding: 25px 20px;
        position: absolute;
        top: -100px;
        right: 0;
        background-color: $white;
        &__title, &__date {
            color: $nightrider;
            @extend %roboto-medium;
        }
        &__title {
            font-size: 18px;
            line-height: 22px;
            max-width: 210px;
        }
        &__date {
            max-width: 180px;
            margin-top: 35px;
            font-size: 14px;
            line-height: 20px;
        }
        &__links {
            margin-top: 30px;
            a {
                width: 100%;
            }
            a + a {
                margin-top: 10px;
            }
        }
        & + .returnProduct__items {
            max-width: 780px;
        }
    }
}

.recommendForYou {
    margin-top: 50px;
    &__title {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 22px;
        line-height: 28px;
    }
    &__items {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }
}

@media screen and (max-width: 1199px) {
    .returnProductWarning {
        max-width: 100%;
        padding: 0;
        margin-bottom: 20px;
        position: static;
        &__links {
            max-width: 300px;
        }
    }
    .orderProducts {
        &__headline {
            padding-right: 154px;
        }
        &__controls {
            flex-direction: column;
            align-items: flex-start;
        }
        &__btns {
            margin-top: 15px;
            .btn-theme {
                width: 280px;
            }
        }
        &Item {
            &__price {
                padding-right: 0;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .orderDetails {
        &__inner {
            .orderDetailsProgressWrap {
                display: block;
            }
        }
        & > &Progress {
            &Wrap {
                display: none;
            }
        }
    }

    .orderProducts {
        &__btns {
            .btn-theme {
                width: 230px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .orderProducts {
        &__btns {
            flex-direction: column;
            .btn-theme {
                margin-top: 10px;
                margin-right: 0;
            }
        }
        &Item {
            &__title {
                max-width: 220px;
            }
        }
    }

    .deliveryInfoSection {
        &__signInBtn {
            display: none;
        }
    }
}

@media screen and (max-width: 719px) {
    .orderProducts {
        &__headline {
            padding-right: 24px;
        }
        &Item {
            &__numbers {
                align-items: flex-start;
                flex-direction: column;
                position: relative;
            }
            &__amount {
                padding-right: 0;
                &--ordered {
                    position: absolute;
                    top: 0;
                    left: -106px;
                }
            }
            &__price {
                margin-top: 2px;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .orderProducts {
        &__changes {
            &-title {
                & + & {
                    margin-left: 20px;
                }
            }
        }
        &Item {
            &__title {
                font-size: 13px;
            }
            &__amount {
                &--ordered {
                    left: -90px;
                }
            }
        }
    }
}

@media screen and (max-width: 539px) {
    .orderProducts {
        &Item {
            padding-right: 0;
            padding-left: 0;
            &__imgWrap {
                min-width: 70px;
                width: 70px;
                height: 70px;
                max-height: 70px;
                margin-right: 20px;
            }
            &__title {
                font-family: Roboto;
            }
        }
        &--ordered {
            .orderProducts {
                &Item {
                    position: relative;
                    &__info {
                        align-items: flex-start;
                    }
                    &__index {
                        align-self: center;
                    }
                    &__title {
                        max-width: 100%;
                        max-height: 40px;
                        overflow: hidden;
                    }
                    &__numbers {
                        align-items: flex-end;
                        flex-direction: row;
                        position: absolute;
                        bottom: 20px;
                        left: 122px;
                    }
                    &__amount {
                        margin-right: 15px;
                    }
                }
            }
        }
        &--changes {
            .orderProducts {
                &__title {
                    font-size: 18px;
                }
                &Item {
                    &__imgWrap {
                        display: none;
                    }
                }
            }
        }
    }
    .returnProduct {
        &__headline {
            flex-direction: column;
            align-items: flex-start;
        }
        &__btn {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 480px) {
    .orderDetails {
        &__title {
            align-items: flex-start;
            min-height: 60px;
            max-height: 60px;
            position: relative;
            &Name {
                font-size: 20px;
                white-space: nowrap;
                max-width: 240px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &Date {
                font-size: 20px;
                position: absolute;
                top: 32px;
                left: 0;
            }
        }
        &__name {
            min-width: 150px;
        }
        &__valueWrap {
            align-items: flex-start;
            flex-direction: column;
        }
        &__value + &__value {
            margin-top: 6px;
        }
        &__paytype &__valueWrap &__link {
            margin-top: 5px;
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 440px) {
    .orderDetails {
        &Progress {
            max-width: 290px;
            padding: 15px 10px;
            .progressInfo {
                width: 100%;
                max-width: 100%;
                &:before {
                    top: 44px;
                }
                &__step {
                    min-width: 70px;
                    max-width: 70px;
                    margin-right: 10px;
                    &:first-child {
                        min-width: 65px;
                        max-width: 65px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
                &__date {
                    min-height: 30px;
                    max-height: 30px;
                    span {
                        font-size: 10px;
                        line-height: 12px;
                    }
                }
                &__status {
                    font-size: 10px;
                    line-height: 12px;
                    span {
                        display: block;
                    }
                }
                &:before {
                    min-width: 270px;
                    width: 270px;
                }
            }
        }
    }
    .orderProducts {
        &Item {
            &__index {
                margin-right: 10px;
            }
        }
        &--changes {
            .orderProducts {
                &__title {
                    max-width: 90px;
                    line-height: 20px;
                }
                &Item {
                    &__title {
                        max-width: 180px;
                    }
                    &__numbers {
                        align-self: flex-start;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .orderProducts {
        &__btns, &__btns .btn-theme {
            width: 100%;
        }
        &--changes {
            .orderProducts {
                &Item {
                    &__title {
                        max-width: 120px;
                    }
                }
            }
        }
        &Item {
            &__price {
                min-width: 120px;
            }
        }
    }
}
