.productComparison {
    padding: 30px 0;
    &__headline {}
    &__product {
        display: flex;
    }
    &__filters {
        padding: 10px 0 50px;
    }
    &__table {}
}

.comparisonTable {
    &__headline {
        border-bottom: 1px solid #dedede;
    }
    &__row {
        display: flex;
        padding: 30px 0;
    }
    &__headline &__row {
        padding: 10px 0;
    }
    &__item {
        border-bottom: 1px solid #dedede;
    }
    &__column {
        width: 440px;
        max-width: 440px;
        & + & {
            margin-left: 20px;
        }
    }
    &__title {
        color: $nobel;
        @extend %roboto-medium;
        font-size: 16px;
    }
    &__price {
        &New {
            display: flex;
            align-items: center;
            padding: 3px 0;
            &Value {
                color: $nightrider;
                @extend %roboto-medium;
                font-size: 18px;
                margin-right: 5px;
            }
        }
        &Old {
            &Value {
                color: $nobel;
                @extend %roboto-medium;
                font-size: 16px;
                display: inline-block;
                position: relative;
                padding: 0 5px;
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: #f00;
                    position: absolute;
                    margin-left: -50%;
                    top: 50%;
                    left: 50%;
                    z-index: 1;
                }
            }
        }
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        & + & {
            margin-top: 7px;
        }
    }
    &__address {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        margin: 4px 0 2px;
        .storeMarker {
            margin-left: 5px;
        }
    }
    &__rating {
        display: flex;
        align-items: center;
    }
    &__reviews {
        color: $nobel;
        @extend %roboto;
        font-size: 14px;
        margin-left: 7px;
    }
    &__btn {
        width: 160px;
        height: 38px;
        margin-left: auto;
    }
    &__contentBtn {
        width: 600px;
    }
    .b-selectPopup {
        margin: 30px 0 0;
    }
}

.b-selectPopup {
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    padding: 9px 15px;
    & > div {
        margin-left: 20px;
    }
}

.productFilters {
    display: flex;
    &__param {
        display: flex;
        align-items: center;
        & + & {
            margin-left: 25px;
        }
    }
    &__text {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        white-space: nowrap;
        margin-right: 15px;
    }
    .deliveryTo {
        &__value {
            margin: 0 0 0 5px;
        }
    }
}