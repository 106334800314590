.b2b, .b2c {
    position: relative;
    &:after {
        content: '';
        display: block;
        width: 34px;
        height: 20px;
        border-radius: 2px;
        color: $white;
        @extend %roboto-medium;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
    }
    &--big:after {
        width: 50px;
        height: 30px;
        font-size: 18px;
        line-height: 30px;
    }
}
.b2b {
    &:after {
        content: 'B2B'; background-color: #8886FF;
    }
    &--inactive {
        &:after {
            background-color: $darkgray;
        }
    }
}
.b2c:after { content: 'B2C'; background-color: #FF3D00; }

.statusMarker {
    padding: 3px 14px;
    min-width: 160px;
    @extend %roboto-medium;
    font-size: 12px;
    text-align: center;
    border-radius: 4px;
    display: inline-block;
    &:before {
        content: attr(data-status);
    }
    &--primary {
        background-color: $solitude;
        color: $nightrider;
    }
    &--success {
        background-color: #E2F9E0;
        color: $nightrider;
    }
    &--reject {
        background-color: #FFDDDA;
        color: $nightrider;
    }
}

.marker {
    width: 34px;
    height: 20px;
    border-radius: 4px;
    font-size: 12px;
    padding: 2px 5px;
    margin-left: 5px;
    border: 1px solid $lightgrey;
}


// постфик "шт."
.amount-postfix {
    &:after {
        content: 'шт.';
        margin-left: 4px;
    }
}

// постфикс "₽"
.rouble {
    &:after {
        content: '₽';
        margin-left: 4px;
    }
}
