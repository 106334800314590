.contracts {
    color: $nightrider;
    @extend %roboto;
    font-size: 14px;
    margin-top: 15px;
    &__block {
        & + & {
            margin-top: 77px;
        }
    }
    &__headline {
        display: flex;
        justify-content: space-between;
    }
    &__corporateData {
        border: 1px solid $lightgrey;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        padding: 20px;
    }
    &__partners {
        & + & {
            margin-top: 40px;
        }
        &List {
            margin-top: 10px;
        }
    }

    &__partner {
        border: 1px solid $lightgrey;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        padding: 20px;
        margin-top: 15px;
        display: flex;
        align-items: center;
        &--header {
            border: none;
            box-shadow: none;
            background: $whitesmoke;
            border-radius: 4px;
            padding: 13px 20px;
            @extend %roboto-medium;
        }

        .partner {
            &__info {
                width: 350px;
            }
            &__title {
                @extend %roboto-medium;
                max-width: 305px;
            }
            &__address {
                font-size: 12px;
                margin-top: 3px;
            }
            &__links {
                display: flex;
                margin-top: 10px;
            }
            &__link {
                @extend %roboto;
                font-size: 13px;
                line-height: 14px;
                color: $blue;
                border-bottom: 1px solid $blue;
                &:not(:first-child) {
                    margin-left: 25px;
                }
                &:hover, &:active {
                    text-decoration: none;
                    color: $darkblue;
                }
            }

            &__contractNumber, &__pricesLoaded, &__paymentWay, &__paymentDelay {
                width: 178px;
            }

            &__line {
                &--small {
                    font-size: 13px;
                }
            }

            &__request {
                padding: 9px 24px;
                &--sent {
                    background: $cornflower;
                    border-radius: 4px;
                    color: $blue;
                }
                &--approved {
                    background: $hintofgreen;
                    border-radius: 4px;
                    color: $seagreen;
                }
            }
        }

    }

    &Info {
        & + & {
            margin-top: 15px;
        }
        &__line {
            display: flex;
            &--name {
                font-size: 20px;
            }
        }
        &__item {
            & + & {
                margin-left: 16px;
            }
        }
    }
}