.profileForm {
    width: 100%;
    max-width: 540px;
    padding: 20px 0;
    background-color: $white;
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 22px;
        margin-bottom: 12px;
        &--small {
            font-size: 16px;
        }
    }
    &__field {
        min-height: 72px;
        &-title {
            color: $nightrider;
            @extend %roboto-bold;
            font-size: 14px;
        }
        &-input {
            height: 32px;
            max-height: 32px;
        }
        &-textarea {
            height: 45px;
            max-height: 45px;
            margin-bottom: 10px;
            resize: none;
        }
        &-input, &-textarea {
            width: 100%;
            padding: 8px 10px;
            border: 1px solid $nobel;
            background-color: $white;
            border-radius: 4px;
            outline: none;
            color: $nightrider;
            @extend %roboto;
            font-size: 13px;
            line-height: 14px;
            &:focus, &:active {
                border-width: 2px;
                border-color: $blue;
            }
        }
        &-error {
            color: #FF494F;
            @extend %roboto;
            font-size: 12px;
            max-width: 380px;
            display: none;
        }
        &-wrap {
            display: flex;
            align-items: center;
            & + & {
                margin-top: 15px;
            }
        }
        &-additional {
            display: flex;
            align-items: center;
            margin-left: 15px;
            &:before {
                content: 'Доб.';
                color: #444;
                @extend %roboto;
                font-size: 13px;
                margin-right: 4px;
            }
            input {
                min-width: 100px;
                max-width: 100px;
            }
        }
        &--group {
            min-height: 136px;
        }
        & + & {
            margin-top: 2px;
        }
        &.has-error {
            .profileForm__field-error {
                display: block;
            }
        }
    }
    &__btns {
        display: flex;
        align-items: center;
        margin-top: 25px;
        font-size: 0;
        .btn-theme + .btn-theme {
            margin-left: 10px;
        }
    }
    &__submit {
        margin-top: 15px;
    }
    .mfp-close {
        font-size: 0;
        opacity: 1;
        cursor: pointer;
        background: 0 0;
        border: 0;
        padding: 0;
        width: 20px;
        height: 20px;
        outline: none;
        top: 10px;
        right: 10px;
        &:before, &:after {
            content: '';
            width: 20px;
            height: 2px;
            background-color: #4d4d4d;
            position: absolute;
            top: 10px;
            left: 0;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(135deg);
        }
    }
}

@media screen and (max-width: 375px) {
    .profileForm {
        &__btns {
            .btn-theme {
                min-width: 135px;
                max-width: 135px;
                padding-right: 10px;
                padding-left: 10px;
            }
        }
    }
}
