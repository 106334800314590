.completedOrder {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 15px;
    &__content {
        display: flex;
        justify-content: space-between;
        padding: 3px 0;
        .completedOrder__inner {
            align-items: flex-start;
        }
        .completedOrder__btns {
            .btn-theme {
                min-width: 135px;
            }
        }
    }
    &__inner {
        display: flex;
        align-items: center;
        .statusMarker {
            margin-right: 25px;
        }
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 15px;
        line-height: 22px;
        margin-right: 30px;
        width: 170px;
        overflow: hidden;
        white-space: nowrap;
    }
    &__subtext {
        color: $nobel;
        @extend %roboto;
        font-size: 14px;
    }
    &__infoWrap {
        width: 140px;
        margin-right: 25px;
        position: relative;
        white-space: nowrap;
        line-height: 22px;
        &:last-child {
            margin-right: 0;
        }
        .tooltipLink { margin-left: 10px; }
    }
    &__company {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 25px;
        line-height: 22px;
        &Info {
            display: flex;
            align-items: center;
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
        }
        &Name {
            position: relative;
            margin-right: 4px;
            max-width: 190px;
            overflow: hidden;
        }
        &Address {
            color: $nightrider;
            @extend %roboto;
            font-size: 13px;
            position: relative;
        }
    }
    &__countInfo {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__shortTitle {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
    }
    &__costValue {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 13px;
    }
    &__dateValue {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
    }
    &__paramWrap {
        display: flex;
        align-items: flex-start;
        margin-top: 7px;
        &--line {
            margin: 0;
        }
        &--separate {
            margin-top: 20px;
        }
    }
    &__paramWrap--summary {
        align-items: center;
    }
    &__paramText, &__paramValue {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        &--bold {
            @extend %roboto-medium;
        }
        &--orange {
            color: #ff5c00;
        }
    }
    &__paramText {
        min-width: 160px;
        text-align: left;
        &--summary {
            @extend %roboto-medium;
            font-size: 16px;
        }
    }
    &__paramValue {
        margin-left: 4px;
        text-align: left;
        &--summary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 285px;
            span {
                @extend %roboto-medium;
                font-size: 16px;
            }
        }
    }
    .b2b + div {
        margin-left: 60px;
    }
    & + & {
        margin-top: 25px;
    }
    &__links {
        display: flex;
        .link + .link {
            margin-left: 10px;
        }
    }
    .btn-theme {
        &--more {
            margin-top: 30px;
        }
    }
    &__additional {
        margin-top: 15px;
        border-top: 1px solid #e0e0e0;
        padding-top: 10px;
    }
    &__group {
        display: inline-block;
        vertical-align: top;
        &:first-child {
            margin-left: 200px;
            margin-right: 27px;
        }
    }
    &__group:first-child &__infoWrap {
        width: 224px;
    }
    &__group &__infoWrap {
        width: auto;
    }
    &__group &__paramWrap &__paramText {
        margin-right: 16px;
    }
    &__group &__paramText {
        min-width: auto;
    }
}
