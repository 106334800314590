@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto/roboto-light.woff') format('woff'),
         url('../fonts/Roboto/roboto-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/roboto-regular.woff') format('woff'),
         url('../fonts/Roboto/roboto-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../fonts/Roboto/roboto-medium.woff') format('woff'),
         url('../fonts/Roboto/roboto-medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../fonts/Roboto/roboto-bold.woff') format('woff'),
         url('../fonts/Roboto/roboto-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url('../fonts/Montserrat/montserrat-extrabold.woff') format('woff'),
         url('../fonts/Montserrat/montserrat-extrabold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
