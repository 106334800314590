.report {
    padding: 20px 0;
    &Title {
        color: $nightrider;
        @extend %roboto;
        font-size: 30px;
        line-height: 34px;
        & + .reportContent {
            margin-top: 20px;
        }
    }
    &Subtitle {
        color: $nightrider;
        @extend %roboto;
        font-size: 20px;
        line-height: 24px;
    }
    &Content {
        & + & {
            margin-top: 15px;
        }
    }
    &List {
        margin: 20px 0 30px;
        padding: 0;
    }
    &Item {
        list-style-type: none;
        &Name {
            color: $nightrider;
            @extend %roboto-light;
            font-size: 28px;
            line-height: 28px;
        }
        &Content {
            color: $nobel;
            @extend %roboto-light;
            font-size: 20px;
            line-height: 20px;
            margin: 20px 0 0;
            padding: 0;
        }
        &Elem {
            list-style-type: none;
            display: flex;
            justify-content: space-between;
            & + & {
                margin-top: 12px;
            }
        }
        &Value {
            & + & {
                margin-left: 10px;
            }
        }
        & + & {
            margin-top: 16px;
        }
    }
}

.listSwitcher {
    cursor: pointer;
}