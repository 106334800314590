.lk {
    max-width: 820px;
    &AddAddr {
        position: relative;
        height: 290px;
        padding: 20px;
        margin-bottom: 25px;
        &:hover {
            .lkAddAddr__lbl { color: $blue; }
            i { @extend %plus-big-circle-blue-icon; }
        }
        i {
            margin: 60px auto 0 auto;
        }
        &__lbl {
            color: $dimgray;
            @extend %roboto-medium;
            font-size: 18px;
            text-align: center;
            margin-top: 30px;
        }
        &__link {
            border: 2px dashed $lightgrey;
            border-radius: 2px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            &:hover {
                border-color: $blue;
            }
        }
    }
    &MyAddr {
        border: 2px solid $lightgrey;
        border-radius: 2px;
        min-height: 290px;
        padding: 75px 25px 30px 25px;
        position: relative;
        margin-bottom: 25px;
        &__name, &__phone {
            color: $nightrider;
            @extend %roboto;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__phone {
            margin-top: 5px;
        }
        &__address {
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            margin: 15px 0 0;
            span {
                overflow: hidden;
                display: block;
                max-height: 300px;
            }
        }
        &__links {
            font-size: 0;
            margin-top: 10px;
            a + a {
                margin-left: 25px;
            }
        }
        .statusMarker {
            position: absolute;
            top: 30px;
            left: 25px;
            display: none;
        }
        &.main-address {
            .statusMarker {
                display: block;
            }
            .js-main-address {
                display: none;
            }
        }
    }
    .field { margin-bottom: 5px; }
    .field-group {
        .field {
            margin-right: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .passChange {
        display: flex;
        align-items: center;
        .btn-theme { margin-left: 40px; }
        &Form {
            display: none;
            .field-group { margin-bottom: 20px; }
            .btn-theme { margin-top: 8px; }
        }
    }
}
