@media screen and (max-width: 1080px) {
    .myContracts {
        &Param {
            &:nth-child(1) {
                min-width: 200px;
            }
            &:nth-child(2) {
                min-width: 120px;
            }
            &:nth-child(3) {
                min-width: 200px;
            }
            &:nth-child(4) {
                min-width: 120px;
            }
        }
    }
    .myContractItem {
        &__seller {
            min-width: 200px;
            max-width: 200px;
        }
        &__param {
            &:nth-child(1) {
                min-width: 120px;
            }
            &:nth-child(2) {
                min-width: 200px;
            }
            &:nth-child(3) {
                min-width: 120px;
            }
            &:nth-child(4) {
                min-width: 140px;
            }
        }
    }

    .myContractsAddressHeadline {
        .title-combined {
            max-width: 600px;
            &__last {
                margin-top: 8px;
            }
        }
        .btn-theme {
            top: 30px;
        }
    }

    .myContractAddressItem {
        &__address {
            min-width: 220px;
            max-width: 220px;
        }
        .main-address-marker {
            margin-left: 10px;
        }
    }
}

@media screen and (max-width: 992px) {
    .myContractAddressItem {
        &__address {
            max-width: 500px;
        }
        &__links {
            display: none;
        }
        &__toggle {
            display: block;
        }
    }
}

@media screen and (max-width: 920px) {
    .myContracts {
        &Params {
            display: none;
        }
    }

    .myContractsHeadline {
        padding-bottom: 0;
        .btn-theme {
            position: static;
            margin-top: 15px;
        }
    }

    .myContractItem {
        padding: 15px 0 30px;
        flex-direction: column;
        align-items: flex-start;
        border-top: 1px solid #ccc;
        &:last-child {
            border-bottom: 0;
        }
        &__params {
            flex-direction: column;
            align-items: flex-start;
        }
        &__seller {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            max-width: 100%;
            min-height: 50px;
            position: relative;
        }
        &__link {
            position: absolute;
            bottom: 0;
            left: 0;
        }
        &__params {
            width: 100%;
        }
        &__param {
            display: flex;
            align-items: center;
            width: 100%;
            line-height: 16px;
            &:nth-child(2) {
                align-items: flex-start;
                max-width: 100%;
                .myContractItem {
                    &__paymentType {
                        white-space: normal;
                    }
                }
            }
        }
        &__company, &__status, &__paymentType, &__postponement {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__request {
            padding-left: 170px;
            line-height: 22px;
        }
        &__time {
            margin-right: 12px;
        }
        &__pre {
            display: block;
            min-width: 170px;
            width: 170px;
            padding-right: 30px;
        }
        &__param, &__request {
            margin-top: 12px;
        }
    }
}

@media screen and (max-width: 768px) {
    .myContractsHeadline {
        .s-title {
            align-items: flex-start;
            flex-direction: column;
            .s-title-regular {
                padding-left: 0;
                margin-top: 10px;
            }
        }
    }

    .myContractsAddressHeadline {
        .btn-theme {
            position: static;
            margin-top: 20px;
        }
        .title-combined {
            &__first {
                width: 100%;
            }
            &__subject {
                margin-top: 8px;
            }
        }
    }

    .myContractAddressItem {
        &__address {
            max-width: 440px;
        }
    }
}

@media screen and (max-width: 700px) {
    .myContractAddressItem {
        position: relative;
        min-height: 110px;
        max-height: 100%;
        &__title {
            align-items: flex-start;
            flex-direction: column;
        }
        &__address {
            min-width: 290px;
            padding-right: 50px;
        }
        &__setMain {
            position: absolute;
            bottom: 20px;
            left: 0;
        }
        .main-address-marker {
            margin-top: 12px;
            margin-left: 0;
            position: static;
        }
    }
}

@media screen and (max-width: 575px) {
    .myContractsHeadline {
        .s-title {
            .s-title-bold {
                width: 100%;
            }
        }
    }

    .myContractItem {
        &__pre {
            min-width: 140px;
            width: 140px;
        }
        &__request {
            padding-left: 140px;
        }
    }
}

@media screen and (max-width: 539px) {
    .myContractAddressItem {
        &__address {
            max-width: 300px;
            white-space: normal;
        }
    }
}

@media screen and (max-width: 375px) {
    .myContractAddressItem {
        &__address {
            min-width: 240px;
            max-width: 240px;
        }
    }

    .reqsPopup {
        &__headline {
            padding: 15px;
        }
        &__title {
            font-size: 18px;
        }
        &__inner {
            padding-right: 15px;
            padding-left: 15px;
        }
        &__btns {
            .btn-theme {
                min-width: 135px;
                max-width: 135px;
                padding-right: 10px;
                padding-left: 10px;
            }
        }
    }
}