iframe {
    overflow: hidden;
    border: none;
    border-radius: 0;
    background: none;
    user-select: none;
    &.visit-app {
        position: relative;
        &.panel {
            width: 100%;
            height: 750px;
            margin: 20px 0 0;
        }
        &.visits {
            width: 280px;
            height: 213px;
            margin-right: 15px;
            &.my {
                width: 520px !important;
                height: 410px !important;
            }
        }
        &.exit {
            width: 280px;
            height: 205px;
            margin-right: 15px;
        }
    }
    &#visitsModalWindow {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 2003;
    }
}

.visit-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .spinner {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        div {
            position: relative;
            transform-origin: 20px 20px;
            animation: spinner 1.2s linear infinite;
            &:after {
                content: " ";
                display: block;
                position: absolute;
                top: 1.5px;
                left: 18.5px;
                width: 3px;
                height: 9px;
                border-radius: 20%;
                background: #999999;
            }
            &:nth-child(1) {
                transform: rotate(0deg);
                animation-delay: -1.1s;
            }
            &:nth-child(2) {
                transform: rotate(30deg);
                animation-delay: -1s;
            }
            &:nth-child(3) {
                transform: rotate(60deg);
                animation-delay: -0.9s;
            }
            &:nth-child(4) {
                transform: rotate(90deg);
                animation-delay: -0.8s;
            }
            &:nth-child(5) {
                transform: rotate(120deg);
                animation-delay: -0.7s;
            }
            &:nth-child(6) {
                transform: rotate(150deg);
                animation-delay: -0.6s;
            }
            &:nth-child(7) {
                transform: rotate(180deg);
                animation-delay: -0.5s;
            }
            &:nth-child(8) {
                transform: rotate(210deg);
                animation-delay: -0.4s;
            }
            &:nth-child(9) {
                transform: rotate(240deg);
                animation-delay: -0.3s;
            }
            &:nth-child(10) {
                transform: rotate(270deg);
                animation-delay: -0.2s;
            }
            &:nth-child(11) {
                transform: rotate(300deg);
                animation-delay: -0.1s;
            }
            &:nth-child(12) {
                transform: rotate(330deg);
                animation-delay: 0s;
            }
        }
    }
    @keyframes spinner {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}

@media screen and (max-width: 660px) {
    iframe {
        &.visit-app {
            &.visits {
                &.my {
                    max-width: 320px !important;
                    width: 100%;
                }
            }
        }
    }
}
