%visa-icon { background: url('../img/sprite.svg') 0 -1810px; }
%mastercard-icon { background: url('../img/sprite.svg') 0 -801px; }
%vk-icon { background: url('../img/vk.svg') 0 0; }
%telegram-icon { background: url('../img/telegram.svg') 0 0; }
%question-icon { background: url('../img/sprite.svg') 0 -2070px; }
%attention-white-icon { background: url('../img/sprite.svg') 0 -2090px; }
%attention-red-icon { background: url('../img/sprite.svg') 50px -960px; }
%attention-orange-icon {background: url('../img/sprite.svg') 0 -2560px; }
%plus-big-circle-icon { background: url('../img/sprite.svg') 0 -2160px; }
%plus-big-circle-blue-icon { background: url('../img/sprite.svg') 0 -2240px; }
%checked-success-icon { background: url('../img/sprite.svg') 44px -994px; }
%attention-white { background: url('../img/sprite.svg') 0 -2090px; }

%box-icon { background: url('../img/lk-menu-icons.svg') 0 0; }
%lock-icon { background: url('../img/lk-menu-icons.svg') 0 -56px; }
%placeholder-icon { background: url('../img/lk-menu-icons.svg') 0 -126px; }
%creditcard-icon { background: url('../img/lk-menu-icons.svg') 0 -181px; }
%gift-icon { background: url('../img/lk-menu-icons.svg') 0 -236px; }
%help-icon { background: url('../img/lk-menu-icons.svg') 0 -289px; }
%b2b-icon { background: url('../img/lk-menu-icons.svg') 0 -350px; }
%soc-icon { background: url("../img/lk-menu-icons.svg") 0 -390px; }

%cart-green-icon { background: url('../img/sprite.svg') 0 -1489px; }
%cart-black-icon { background: url('../img/sprite.svg') -30px -1470px }
%heart-blue-small-icon { background: url('../img/sprite.svg') 0 -653px; }
%heart-blue-medium-icon { background: url('../img/sprite.svg') 56px -680px; }
%star-blue-small-icon { background: url('../img/sprite.svg') 0 -1617px; }
%nav-small-icon { background: url('../img/sprite.svg') 0 -837px; }

%heart-icon { background: url('../img/sprite.svg') 0 -683px; }
%compass-icon { background: url('../img/sprite.svg') 0 -426px; }
%info-icon { background: url('../img/sprite.svg') 0 -742px; }
%review-icon { background: url('../img/sprite.svg') -3px -1358px; }
%heart-white-icon { background: url('../img/sprite.svg') 0 -665px; }
%compass-white-icon { background: url('../img/sprite.svg') 0 -386px; }
%info-white-icon { background: url('../img/sprite.svg') 0 -702px; }
%review-white-icon { background: url('../img/sprite.svg') 0 -255px; }
%percent-icon { background: url('../img/sprite.svg') 0 -934px; }
%darkstar-icon { background: url('../img/sprite.svg') 0 -1587px; }
%darkstar-hover-icon { background: url('../img/sprite.svg') 0 -2140px; }
%darkbin-icon { background: url('../img/sprite.svg') 0 -238px; }
%darkbin-hover-icon { background: url('../img/sprite.svg') 0 -2120px; }
%cart-white-icon { background: url('../img/sprite.svg') 0 -1470px; }

%attention-mc-icon { background: url('../img/sprite.svg') 0 -2320px; }

%description-icon { background: url('../img/sprite.svg') 0 -876px; }
%star-bold-blue-icon { background: url('../img/sprite.svg') 0 -1602px; }

%check-sm-icon { background: url('../img/sprite.svg') 50px -2120px; }
%check-md-icon { background: url('../img/sprite.svg') -20px -300px; }

%unboxed-sm-icon { background: url('../img/unboxed-small.svg') 0 0; }
%unboxed-md-icon { background: url('../img/unboxed-medium.svg') 0 0; }
%unboxed-lg-icon { background: url('../img/unboxed-large.svg') 0 0; }

%clock-gray-icon { background: url("../img/sprite.svg") 0 -2385px };
%clock-red-icon { background: url("../img/sprite.svg") -20px -2385px };

%search-blue-icon { background: url("../img/sprite.svg") -50px -1800px; }

%array-to-top-icon { background: url("../img/sprite.svg") 50px 0; }
%array-to-bottom-icon { background: url("../img/sprite.svg") 50px -30px; }

%locate-black-icon { background: url("../img/sprite.svg") 1px -2400px; }

%arrow-black-small-icon { background: url("../img/sprite.svg") -20px -1437px; }

%triangle-in-circle-gray-icon { background: url("../img/sprite.svg") 0 -2420px; }
%arrow-gray-small-icon { background: url("../img/sprite.svg") -20px 0; }
%arrow-lightgray-right-small-icon { background: url('../img/sprite.svg') 0 -1424px; }

%arrow-down-icon { background: url("../img/sprite.svg") -20px -1420px; }
%arrow-left-icon { background: url("../img/sprite.svg") -50px -1430px; }
%arrow-right-gray-icon { background: url("../img/sprite.svg") -40px -1410px; }
%arrow-right-black-icon { background: url("../img/sprite.svg") -40px -1430px; }
%location-gray-icon { background: url("../img/sprite.svg") -20px -830px; }
%location-lightblue-icon { background: url("../img/sprite.svg") -35px -830px; }
%search-gray-icon { background: url("../img/sprite.svg") -20px -1380px; }
%account-icon { background: url("../img/sprite.svg") -30px -500px; }
%visit-icon { background: url("../img/visit_menu.svg") no-repeat center; }
%communicate-icon { background: url("../img/sprite.svg") -30px -530px; }
%orders-icon { background: url("../img/sprite.svg") -30px -470px; }
%cart-icon { background: url("../img/sprite.svg") -30px -440px; }
%arrow-back-white-icon { background: url("../img/sprite.svg") -40px -2030px; }
%triangle-black-small-icon { background: url("../img/sprite.svg") -40px -60px; }
%cross-small-icon { background: url("../img/sprite.svg") 0 -353px; }
%chevron-left-gray-icon { background: url("../img/sprite.svg") -50px -1390px; }
%arrow-xs { background: url("../img/sprite.svg") -40px -71px; }
%progress-begin { background: url("../img/sprite.svg") -50px -280px; }
%progress-begin-active { background: url("../img/sprite.svg") -50px -300px; }
%arrow-border-before-xs { background: url("../img/sprite.svg") -60px -70px; }
%arrow-border-after-xs { background: url("../img/sprite.svg") -50px -80px; }


%add-circle-icon { background: url("../img/add-icon.svg") -5px -5px; }
%dots-gray-icon { background: url("../img/sprite.svg") -30px -1730px; }
%cart-in-way-icon { background: url("../img/sprite.svg") -30px -220px; }
%arrow-big-blue-icon { background: url("../img/sprite.svg") 0 -2500px; }
%two-arrows-white-icon { background: url("../img/sprite.svg") -40px -240px; }
%sticky-icon { background: url("../img/sprite.svg") -40px -800px; }
%little-arrow-down-icon { background: url("../img/sprite.svg") 0 -1440px; }
%success-circle-icon { background: url("../img/success-circle.svg") 0 0; }

%pagination-back-icon { background: url("../img/pagination-back.svg") 0 0; }
%pagination-next-icon { background: url("../img/pagination-next.svg") 0 0; }
%pagination-back-disabled-icon { background: url("../img/pagination-back-disabled.svg") 0 0; }
%pagination-next-disabled-icon { background: url("../img/pagination-next-disabled.svg") 0 0; }
