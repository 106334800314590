// карточка корзины
.cartPackage {
    &Variants{
        &__selected {
            position: relative;
            padding-right: 18px;
            &:before {
                content: '';
                position: absolute;
                top: 4px;
                right: 0;
                width: 12px;
                height: 7px;
                @extend  %little-arrow-down-icon;
            }
        }
        &__container {
            position: absolute;
            background-color: white;
            padding: 5px;
            z-index: 100;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
            display: none;
            border-radius: 2px;
            &.show {
                display: block;
            }
        }
        &__list {
            list-style: none;
            @extend %roboto;
        }
        &__item {
            padding: 7px 10px;
            &:hover {
                background-color: $whitesmoke;
                cursor: pointer;
            }
        }
    }
    &__package {
        border: 1px solid #DCDCDC;
        border-radius: 2px;
        background-color: $white;
        position: relative;
        margin-top: 5px;
    }
    &__markers {
        display: flex;
    }
    &__marker {
        min-width: 100px;
        padding: 5px 20px;
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        background-color: #f9f9f9;
        text-align: center;
        border-radius: 4px;
        & + & {
            margin-left: 12px;
        }
    }
    &__inner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 25px 30px;
        position: relative;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }
    &__info, &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-grow: 1;
        padding-right: 25px;
    }
    &__wrap {
        flex-direction: column;
    }
    &__part {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        & + & {
            margin-top: 55px;
        }
    }
    &__name {
        color: $nobel;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 16px;
    }
    &__value {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 16px;
        padding-right: 15px;
        &--link {
            color: $blue;
            @extend %hovered-lighter;
        }
    }
    &__subtext {
        color: $nobel;
        @extend %roboto;
        font-size: 13px;
        line-height: 16px;
        min-height: 20px;
        margin-top: 4px;
        &--warning {
            color: $radicalred;
        }
    }
    &__param {
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        & + & {
            margin-top: 20px;
        }
        .b2b {
            margin-left: 10px;
        }
    }
    &__param &__name {
        display: flex;
        align-items: center;
        width: 120px;
        min-width: 120px;
        margin-right: 15px;
    }
    &__links {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }
    &__link {
        font-size: 14px;
        margin-bottom: 20px;
        white-space: nowrap;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__comment {
        width: 500px;
        min-height: 63px;
        max-height: 63px;
        padding: 5px 10px;
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        line-height: 17px;
        background-color: $white;
        border-radius: 2px;
        border: 1px solid #CDCDCD;
        margin: 20px 0;
        overflow: hidden;
    }
    &__radiogroup {
        margin-top: 20px;
        padding: 0 10px 0;
    }
    &__calculation {
        min-width: 270px;
        padding-left: 50px;
        border-left: 1px solid #d9d9d9;
    }
    &__numbers {
        padding-bottom: 10px;
        border-bottom: 2px solid #d9d9d9;
    }
    &__amount {
        text-align: right;
        margin-bottom: 14px;
    }
    &__price {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        &Text {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-left: 6px;
            min-width: 80px;
            color: $nobel;
            @extend %roboto-medium;
            font-size: 14px;
            line-height: 18px;
        }
        &Value {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 16px;
            line-height: 18px;
        }
        & + & {
            margin-top: 4px;
        }
    }
    &__price &__name {
        width: 130px;
        min-width: 130px;
        margin-right: 6px;
        text-align: right;
    }
    &__totalWrap {
        margin-top: 10px;
    }
    &__total {
        display: flex;
        justify-content: flex-end;
        &Text {
            display: flex;
            align-items: flex-end;
            color: $nobel;
            @extend %roboto;
            font-size: 16px;
        }
        &Value {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 22px;
            line-height: 26px;
        }
    }
    &__payment {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        margin-top: 6px;
    }
    &__payment &__name + &__name {
        margin-top: 2px;
    }
    &__toggle {
        position: absolute;
        bottom: 5px;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
    &__content {
        display: none;
        &Inner {
            padding: 10px;
        }
        &Hide {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 18px 20px;
            border-top: 1px solid #ededed;
            cursor: pointer;
        }
    }
    &__items {
        margin-top: 5px;
    }
    & + & {
        margin-top: 40px;
    }
    &.open &__content {
        display: block;
    }
    &.open &__toggle {
        i { transform: rotate(180deg); }
    }
    .optionsOfPackagePopup {
        top: 20px;
        left: 20px;
    }
}

.cartSlider {
    display: flex;
    justify-content: space-between;
    max-width:368px;
    width:368px;
    height: 290px;
    max-height: 290px;
    &Container {
        border-radius: 4px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
        background-color: $white;
        margin-left: 30px;
        padding: 20px;
    }
    &__picbox {
        width: 290px;
        height: 290px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        a {
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    &__ui {
        display: flex;
        flex-direction: column;
        &.showed {
            justify-content: start;
            .prev-slide, .next-slide {
                display: block;
            }
        }
        .prev-slide, .next-slide {
            display: none;
            width: 46px;
            height: 24px;
            margin: 0 auto;
            cursor: pointer;
        }
        .next-slide {
            background: url('../img/sprite.svg') 0 -852px;
            margin-top: 17px;
            transform: scale(0.7);
        }
        .prev-slide {
            background: url('../img/sprite.svg') 0 -1045px;
            margin-bottom: 17px;
            transform: scale(0.7);
        }
    }
    &__list {
        height: 9999px;
        transition: margin-top 250ms;
    }
    &__item {
        display: block;
        width: 58px;
        height: 58px;
        max-width: 58px;
        max-height: 58px;
        text-align: center;
        padding: 3px;
        border: 1px solid #C6C6C6;
        border-radius: 4px;
        cursor: pointer;
        &.active {
            border-width: 3px;
            border-color: $blue;
        }
        & + div, & + a {
            margin-top: 12px;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__wrap {
        max-height: 210px;
        overflow: hidden;
    }
    &-mobile {
        display: none;
    }
}

// контейнер карточек корзины
.cartPackageWrapper {
    margin-top: 0;
}

// контейнер карточек оставлено на потом
.postponePackageWrapper {
    margin-top: 65px;
}

// тайтл для айтемов корзины
.productItemsHeadline {
    display: flex;
    padding: 12px 20px;
    background-color: #f9f9f9;
    border-radius: 4px;
    &__param {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        &:nth-child(1) { width: 135px; }
        &:nth-child(2) { flex-grow: 1; }
        &:nth-child(3) { width: 230px; }
        &:nth-child(4) { width: 130px; }
    }
}


.cartAddedPopup {
    max-width: 660px;
    margin-right: auto;
    margin-left: auto;
    background-color: $white;
    border-radius: 4px;
    &__headline {
        padding: 15px 18px;
        position: relative;
        display: flex;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 20px;
        &--warning {
            color: $radicalred;
        }
        &--notify {
            color: #ffcf87;
        }
    }
    &__content {
        padding: 25px;
        border-top: 2px solid #eee;
        border-bottom: 2px solid #eee;
    }
    &__inner {

    }
    &__warning {
        @extend %roboto-medium;
        font-size: 16px;
    }
    &__text {
        @extend %roboto;
        font-size: 14px;
        margin-top: 10px;
    }
    &__wrap {
        display: flex;
        align-items: flex-start;
        & + & {
            margin-top: 20px;
        }
    }
    &__imgWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        width: 100px;
        height: 100px;
        max-width: 100px;
        max-height: 100px;
        border: 1px solid #F4F4F4;
        margin-right: 20px;
        border-radius: 4px;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
    }
    &__name {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        max-width: 190px;
    }
    &__amount {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
    }
    &__price {
        padding: 0 40px 0 20px;
    }
    &__cost {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &__new {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 14px;
        padding-left: 10px;
    }
    &__old {
        color: $nobel;
        @extend %roboto-medium;
        font-size: 14px;
        text-decoration: line-through;
        margin-left: 4px;
        display: none;
        &.show {
            display: block;
        }
    }
    &__economy {
        color: #ff6a16;
        @extend %roboto;
        font-size: 14px;
        text-align: right;
        margin-top: 4px;
        display: none;
        &.show {
            display: block;
        }
    }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 70px 15px 0;
    }
    &__link {
        margin-right: 25px;
    }
    .mfp-close {
        cursor: pointer;
        font-size: 0;
        &:before, &:after {
            content: '';
            width: 20px;
            height: 2px;
            background-color: $nightrider;
            position: absolute;
            top: 20px;
            left: 10px;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(135deg);
        }
    }
}


.cartInfo {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    .b-error {
        width: 100%;
        margin-right: 30px;
        margin-top: 3px;
    }
}

.cartWarnings {
    margin-right: 30px;
    margin-bottom: 15px;
    flex-grow: 1;
    border: 3px solid #ffcf87;
    border-left: 10px solid #ffcf87;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px 20px 20px 20px;
    &__header {
        display: flex;
        align-items: center;
        &Text {
            @extend %roboto-medium;
            font-size: 18px;
            margin-left: 11px;
            line-height: 20px;
        }
        &Icon {
            transform: scale(0.8);
            width: 26px;
            height: 24px;
        }
    }

    &__warning {
        line-height: 22px;
        & + & {
            margin-top: 20px;
        }
        &List {
            margin-top: 20px;
        }

    }
}

// инфо о общем количестве товаров в корзине и их стоимости
.cartTotal {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 15px 20px;
    background-color: $white;
    border: 1px solid #DCDCDC;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    &__wrap {
        display: flex;
        align-items: flex-end;
    }
    &__info {
        display: flex;
        align-items: flex-end;
        color: $nobel;
        @extend %roboto-medium;
        font-size: 14px;
    }
    &__amount {
        margin-left: 6px;
    }
    &__price {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 22px;
        line-height: 24px;
        margin-left: 12px;
        max-width: 190px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &__btn {
        width: 100%;
        margin-top: 16px;
    }
}

.cartProductWarning {
    border: 3px solid #ffcf87;
    border-left: 10px solid #ffcf87;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    margin: 15px 40px 0;
    display: flex;
    justify-content: flex-start;
    &__icon {
        transform: scale(0.8);
        width: 26px;
        height: 24px;
    }
    &__text {
        @extend %roboto;
        font-size: 13px;
        margin-left: 10px;
        line-height: 20px;
    }
}

// айтем продукта в корзине
.cartProductItem {
    display: flex;
    padding: 18px 5px;
    &__info {
        display: flex;
        align-items: flex-start;
    }
    &__index {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
        margin: 10px 25px 0 0;
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 18px;
        border-radius: 20px;
        background-color: #f9f9f9;
    }
    &__imgWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        min-width: 60px;
        height: 60px;
        min-height: 60px;
        margin-right: 25px;
        border: 1px solid #F7F7F7;
        border-radius: 4px;
        position: relative;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
    }
    &__detail {
        display: flex;
        flex-direction: column;
    }
    &__title {
        width: 400px;
        max-width: 400px;
        color: $nightrider;
        @extend %roboto;
        @extend %hovered-lighter;
        &:hover {
            color: $nightrider;
        }
        font-size: 14px;
        line-height: 20px;
    }
    &__comment {
        @extend %roboto;
        font-size: 13px;
        line-height: 16px;
        margin-top: 4px;
        &--warning {
            color: $radicalred;
        }
        &--available {
            color: $seagreen;
        }
    }
    &__links {
        display: flex;
        align-items: center;
        margin-top: 4px;
    }
    &__link {
        display: flex;
        margin-right: 15px;
        font-size: 13px;
        line-height: 16px;
        color: $nightrider;
        @extend %roboto;
        @extend %hovered-lighter;
        &:hover {
            color: $nightrider;
        }
        & + & {
            &:before {
                content: '|';
                margin-right: 16px;
            }
        }
    }
    &__amount {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 200px;
        margin-left: 78px;
        &Value {
            color: $dimgray;
            @extend %roboto;
            font-size: 14px;
            text-align: center;
            margin-top: 2px;
            &:before {
                content: '1 шт = ';
            }
        }
    }
    &__price {
        min-width: 200px;
        width: 200px;
        max-width: 200px;
        padding-right: 15px;
        margin-left: auto;
        text-align: center;
        overflow: hidden;
        &Value {
            &New {
                color: $nightrider;
                @extend %roboto-medium;
                font-size: 14px;
            }
            &Old {
                display: inline-flex;
                padding: 0 8px;
                color: $dimgray;
                @extend %roboto;
                font-size: 14px;
                position: relative;
                text-align: center;
                &:before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: $nobel;
                    position: absolute;
                    top: 50%;
                    left: 0;
                }
            }
        }
        &Discount {
            color: $dimgray;
            @extend %roboto;
            font-size: 14px;
            &:before {
                content: 'Скидка ';
            }
        }
    }
    & + & {
        border-top: 1px solid #ededed;
    }
    .numUpDown {
        border: none;
        margin-left: auto;
        margin-right: auto;
        &__control {
            &--dec {
                margin-right: 5px;
                &:before {
                    width: 11px;
                    height: 3px;
                    background: url('../img/sprite.svg') 0 -1309px;
                }
            }
            &--inc {
                margin-left: 5px;
                &:before {
                    width: 11px;
                    height: 11px;
                    background: url('../img/sprite.svg') 0 0;
                }
            }
        }
        &__field {
            border: 1px solid #ababab;
            border-radius: 2px;
            max-width: 46px;
            font-size: 14px;
            height: 32px;
        }
    }
}

// тайтл для страниц выполнения заказа
.orderProcessingHeadline {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 0;
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 24px;
        margin-right: 8px;
    }
    .cartTotal {
        position: absolute;
        bottom: -70px;
        right: 0;
    }
}

// тайтл при пустой корзине
.cartEmpty {
    margin-top: 100px;
    margin-bottom: 80px;
    display: none;
    &.show {
        display: block;
    }
    &__text {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 40px;
        text-align: center;
    }
}

// тайтл для оставлено на потом
.postponeHeadline {
    display: none;
    margin-bottom: 18px;
    position: relative;
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 24px;
        margin-right: 8px;
    }
    .cartTotal {
        max-width: 315px;
        position: absolute;
        bottom: -10px;
        right: 0;
        flex-direction: row;
    }
    &.show {
        display: flex;
    }
}

// Оставлено на потом, скрыть кнопку "Упаковать"
.js-postpone-wrap {
    .cartPackage__link--packItOnly { display: none; }
}
