.rating {
    width: 118px;
    min-width: 118px;
    max-width: 118px;
    overflow: hidden;
    &:before {
        content: '';
        display: block;
        width: 118px;
        height: 22px;
    }
    &.zero:before {
        background: url("../img/rating.svg") 0 1px no-repeat;
    }
    &.one:before {
        background: url("../img/rating.svg") 0 -29px no-repeat;
    }
    &.two:before {
        background: url("../img/rating.svg") 0 -57px no-repeat;
    }
    &.three:before {
        background: url("../img/rating.svg") 0 -85px no-repeat;
    }
    &.four:before {
        background: url("../img/rating.svg") 0 -113px no-repeat;
    }
    &.five:before {
        background: url("../img/rating.svg") 0 -141px no-repeat;
    }
    &--small {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        &:before {
            width: 70px;
            height: 14px;
        }
        &.zero:before {
            background: url("../img/rating.svg") 0 -175px no-repeat;
        }
        &.one:before {
            background: url("../img/rating.svg") 0 -190px no-repeat;
        }
        &.two:before {
            background: url("../img/rating.svg") 0 -204px no-repeat;
        }
        &.three:before {
            background: url("../img/rating.svg") 0 -218px no-repeat;
        }
        &.four:before {
            background: url("../img/rating.svg") 0 -232px no-repeat;
        }
        &.five:before {
            background: url("../img/rating.svg") 0 -246px no-repeat;
        }
    }
}

.stars {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    overflow: hidden;
    &:before {
        content: '';
        display: block;
        width: 120px;
        height: 22px;
    }
    &.zero:before {
        background: url("../img/stars.svg") 0 -150px no-repeat;
    }
    &.one:before {
        background: url("../img/stars.svg") 0 -120px no-repeat;
    }
    &.two:before {
        background: url("../img/stars.svg") 0 -90px no-repeat;
    }
    &.three:before {
        background: url("../img/stars.svg") 0 -60px no-repeat;
    }
    &.four:before {
        background: url("../img/stars.svg") 0 -30px no-repeat;
    }
    &.five:before {
        background: url("../img/stars.svg") 0 0 no-repeat;
    }
    &--small {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        &:before {
            width: 70px;
            height: 12px;
        }
        &.zero:before {
            background: url("../img/stars.svg") 0 -255px no-repeat;
        }
        &.one:before {
            background: url("../img/stars.svg") 0 -240px no-repeat;
        }
        &.two:before {
            background: url("../img/stars.svg") 0 -225px no-repeat;
        }
        &.three:before {
            background: url("../img/stars.svg") 0 -210px no-repeat;
        }
        &.four:before {
            background: url("../img/stars.svg") 0 -195px no-repeat;
        }
        &.five:before {
            background: url("../img/stars.svg") 0 -180px no-repeat;
        }
    }
}
