.numberselect {
    display: inline-block;
    min-width: 80px;
    min-height: 30px;
    height: 30px;
    max-height: 30px;
    position: relative;
    border: 1px solid $nobel;
    border-radius: 2px;
    color: $nightrider;
    @extend %roboto-medium;
    font-size: 13px;
    cursor: pointer;
    user-select: none;
    &__inner {
        width: 100%;
        height: 100%;
        &:after {
            height: 0;
            width: 0;
            position: absolute;
            top: 0;
            right: 15px;
            bottom: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #ccc;
            content: '';
            display: block;
            margin: auto 0;
            right: 10px;
            width: 12px;
            height: 7px;
            border: 0;
            background: url('../img/sprite.svg') 0 -1440px;
        }
    }
    &__selected {
        width: 100%;
        height: 100%;
        padding: 5px 32px 5px 10px;
        position: relative;
        background-color: $white;
        &:before {
            content: attr(data-before-val);
            margin-right: 4px; }
        &:after {
            content: attr(data-after-val);
            margin-left: 4px; }
    }
    &__list {
        display: none;
        min-height: 84px;
        padding: 5px;
        list-style-type: none;
        position: absolute;
        top: 28px;
        left: -1px;
        border-radius: 2px;
        background-color: #fff;
        z-index: 10;
        overflow: hidden;
        border: 1px solid #999;
        width: 102%;
        &.open {
            display: block;
        }
    }
    &__item {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 13px;
        padding: 5px 20px;
        &--overval {
            border-top: 1px solid $nobel;
        }
        &:hover {
            background-color: #edf6ff;
        }
        &.selected {
            background-color: #cce5ff;
            border-radius: 2px;
        }
    }
    &__inputWrapper {
        width: 100%;
        max-width: 100px;
        height: 100%;
        position: relative;
    }
    &__input {
        width: 100%;
        height: 100%;
        padding: 2px 8px;
        border: none;
        outline: none;
    }
    &__update {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 70px;
        max-width: 70px;
        min-height: 26px;
        max-height: 26px;
        padding: 2px;
        color: $white !important;
        @extend %roboto-medium;
        font-size: 11px;
        text-align: center;
        border-radius: 4px;
        position: absolute;
        top: 1px;
        right: -75px;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
        background-image: linear-gradient(180deg, #3193E3 0%, #0066C1 100%);
        &:hover {
            background-image: linear-gradient(180deg, #66B1EE 0%, #1D8EEE 100%);
            color: $white;
        }
    }
}
