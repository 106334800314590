.order {
    margin-top: 20px;
    .radioGroup {
        margin: 20px 0;
    }
    .js-radioGroupWrapper {
        max-height: 85px;
    }
}

.ordersWrap {
    margin-top: 30px;
}

.titleSuccess {
    color: $nightrider;
    @extend %roboto-medium;
    font-size: 24px;
    line-height: 26px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    &:after {
        content: '';
        width: 31px;
        min-width: 31px;
        height: 31px;
        min-height: 31px;
        display: block;
        margin-left: 10px;
        background: url('../img/sprite.svg') 0 -1940px;
    }
}