.myContractsAddressHeadline {
    padding: 10px 0;
    position: relative;
    .btn-theme {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.myContractsAddress {
    margin-top: 20px;
}

.myContractAddressItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
    max-height: 70px;
    border-top: 1px solid #CCCCCC;
    &__title {
        display: flex;
        align-items: center;
    }
    &__address {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        min-width: 250px;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 20px;
    }
    &__links {
        display: flex;
        align-items: center;
        .link + .link {
            margin-left: 50px;
        }
    }
    &__toggle {
        display: none;
        min-width: 44px;
        width: 44px;
        min-height: 10px;
        height: 10px;
        margin-right: 5px;
        @extend %dots-gray-icon;
    }
    &:last-child {
        border-bottom: 1px solid #CCCCCC;
    }
    &.main {
        .main-address-marker {
            display: flex;
        }
        .myContractAddressItem {
            &__setMain {
                display: none;
            }
        }
    }


    &:first-child {
        .myContractAddressItem {
            &__links {
                opacity: 1;
            }
        }
    }
    &__links {
        transition: opacity .3s linear;
        opacity: 0;
    }
    &:hover {
        .myContractAddressItem {
            &__links {
                transition: opacity .3s linear;
                opacity: 1;
            }
        }
    }
}

.main-address-marker {
    display: none;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 30px;
    color: $nightrider;
    @extend %roboto;
    font-size: 14px;
    background-color: #F7F7F7;
    border-radius: 4px;
}

.title-combined {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__first {
        color: $nightrider;
        @extend %roboto;
        font-size: 24px;
        line-height: 26px;
        padding-right: 12px;
    }
    &__subject {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 24px;
        line-height: 26px;
        max-width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 12px;
    }
    &__last {
        display: flex;
        align-items: flex-start;
        color: $nightrider;
        @extend %roboto;
        font-size: 24px;
        line-height: 26px;
        &:before {
            content: '|';
            display: flex;
            margin-right: 12px;
        }
    }
}

.reqsPopup {
    max-width: 540px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: $white;
    border-radius: 6px;
    &__headline {
        padding: 15px 25px;
        position: relative;
        background-color: #EAEAEA;
        border-radius: 6px 6px 0 0;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 22px;
    }
    &__formTitle {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 10px;
    }
    &__link {
        display: block;
        padding: 15px 25px;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        & + & {
            border-top: 1px solid $nobel;
        }
    }
    &__inner {
        padding: 10px 25px 45px;
    }
    &__regular {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        padding-bottom: 10px;
    }
    &__medium {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        padding-bottom: 10px;
    }
    &__address {
        display: inline-block;
        max-width: 100%;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        background-color: #E4F2FF;
        border-radius: 4px;
        padding: 8px 15px;
    }
    &__btns {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-top: 35px;
        .btn-theme + .btn-theme {
            margin-left: 10px;
        }
    }
    .mfp-close {
        top: 8px;
        font-size: 0;
        cursor: pointer;
        &:before, &:after {
            content: '';
            width: 20px;
            height: 2px;
            background-color: $nightrider;
            position: absolute;
            top: 20px;
            left: 10px;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(135deg);
        }
    }
}