.headerMagic {
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    &__inner {
        display: flex;
        align-items: center;
        padding: 15px 25px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
    }
    &__logo {
        display: flex;
        align-items: center;
        &Text {
            color: $nightrider;
            font-size: 22px;
            &--bold {
                @extend %roboto-bold;
            }
            &--regular {
                @extend %roboto;
            }
            & + & {
                padding: 0 8px;
            }
        }
    }
    &__nav {
        margin-left: 180px;
        &List {
            display: flex;
            align-items: center;
        }
        &Item {
            margin-right: 40px;
        }
    }
    &__links {
        display: flex;
        align-items: center;
        margin-left: auto;
        a + a {
            &:before {
                content: '|';
                display: block;
                padding: 0 10px;
            }
        }
    }
    &__link {
        display: flex;
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        line-height: 18px;
        &:hover {
            color: $nightrider;
            @extend %roboto-medium;
            text-decoration: none;
        }
    }
}

.contentMagic {
    &__headline {
        padding: 45px 0;
        text-align: center;
    }
    &__title, &__subTitle, &__subText {
        color: $nightrider;
    }
    &__title {
        @extend %roboto-bold;
        font-size: 40px;
        line-height: 42px;
    }
    &__subTitle {
        @extend %roboto-medium;
        font-size: 22px;
        line-height: 28px;
        margin-top: 35px;
    }
    &__subText {
        @extend %roboto;
        font-size: 16px;
        line-height: 18px;
        margin-top: 16px;
    }
    &__btn {
        margin-top: 25px;
    }
    .btn-theme {
        min-width: 200px;
    }
    &__banner {
        max-width: 1110px;
        height: 270px;
        max-height: 270px;
        margin: 55px auto;
        background-color: #4282F0;
    }
    &__points {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 60px 0 90px;
        .contentMagicPoint {
            max-width: 330px;
        }
    }
    &Point {
        &__title {
            color: $nightrider;
            @extend %roboto-bold;
            font-size: 24px;
            line-height: 32px;
        }
        &__text {
            color: $nightrider;
            @extend %roboto;
            font-size: 16px;
            line-height: 26px;
            margin-top: 16px;
        }
    }
    &BannerWeb {
        padding-top: 28px;
        background-color: #0A5A95;
        &__inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
        }
        &__image {
            min-width: 570px;
            min-height: 172px;
            max-width: 570px;
            max-height: 172px;
            background: url("../img/sellercenter.svg") 0 0 no-repeat;
        }
        .magicBannerText {
            padding-bottom: 30px;
        }
    }
    &BannerAdv {
        padding-top: 38px;
        background-color: #D3E0E9;
        &__inner {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            position: relative;
        }
        &__table {
            display: flex;
            align-items: flex-start;
            min-width: 315px;
            min-height: 95px;
            padding: 15px;
            background-color: $white;
            border-radius: 10px;
            position: absolute;
            top: 10px;
            left: 540px;
            &Col {
                min-width: 100px;
                max-width: 100px;
                &Title {
                    color: $nightrider;
                    @extend %roboto-medium;
                    font-size: 13px;
                    line-height: 15px;
                    min-height: 30px;
                    max-height: 30px;
                    overflow: hidden;
                }
                &Value {
                    color: $nobel;
                    @extend %roboto-medium;
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
        }
        &__image {
            min-width: 328px;
            min-height: 131px;
            max-width: 328px;
            max-height: 131px;
            background: url("../img/sellercenter.svg") 0 -180px no-repeat;
        }
        .magicBannerText {
            padding-bottom: 44px;
        }
    }
    .magicBannerText {
        max-width: 480px;
        &__title {
            @extend %roboto-bold;
            font-size: 24px;
            line-height: 32px;
        }
        &__text {
            @extend %roboto;
            font-size: 14px;
            line-height: 22px;
            margin-top: 12px;
        }
        &--black {
            color: $nightrider;
        }
        &--white {
            color: $white;
        }
    }
    &__carousel {
        padding: 45px 0;
        text-align: center;
        .contentMagicPoint {
            &__text {
                margin-top: 6px;
            }
        }
    }
    &Carousel {
        max-width: 820px;
        padding: 0 40px;
        margin: 25px auto 0 auto;
        position: relative;
        &Item {
            min-height: 195px;
            height: 195px;
            background-color: #4282F0;
        }
        .owl-nav {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
        }
        .owl-prev, .owl-next {
            width: 20px;
            min-width: 20px;
            height: 33px;
            min-height: 33px;
            &:focus {
                outline: none;
            }
            span { font-size: 0; }
        }
        .owl-prev { background: url("../img/sellercenter.svg") -575px 0 !important; }
        .owl-next { background: url("../img/sellercenter.svg") -575px -40px !important; }
    }
    &__quick {
        padding: 20px 0;
        text-align: center;
        &Title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 24px;
        }
        &Btn {
            margin-top: 20px;
        }
    }
}

@media screen and (min-width: 800px) and (max-width: 1500px) {
    .headerMagic {
        &__inner {
            padding-bottom: 40px;
        }
        &__nav {
            position: absolute;
            bottom: 15px;
            left: 25px;
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 1199px) {
    .contentMagic {
        &__banner {
            max-width: 100%;
        }
        &__points {
            .contentMagicPoint {
                max-width: 290px;
            }
        }
        &BannerWeb {
            &__image {
                position: absolute;
                right: -100px;
                bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .contentMagic {
        &__points {
            flex-direction: column;
            padding: 25px 0 30px;
            .contentMagicPoint {
                max-width: 100%;
            }
            .contentMagicPoint + .contentMagicPoint {
                margin-top: 35px;
            }
        }
        &BannerWeb, &BannerAdv {
            .magicBannerText {
                max-width: 100%;
                align-self: flex-start;
            }
            &__inner {
                flex-direction: column;
            }
            &__image {
                align-self: flex-end;
                position: static;
                margin-right: -40px;
            }
        }
        &BannerAdv {
            &__table {
                top: unset;
                left: unset;
                right: 60px;
                bottom: 60px;
            }
        }
    }
}

@media screen and (max-width: 820px) {
    .contentMagic {
        &Carousel {
            max-width: 540px;
        }
    }
}

@media screen and (max-width: 799px) {
    .headerMagic {
        &__nav {
            display: none;
        }
        &__link {
            font-size: 14px;
        }
    }
    .contentMagic {
        &__headline {
            padding: 30px 0;
        }
        &__banner {
            margin: 0;
        }
    }
}

@media screen and (max-width: 680px) {
    .contentMagic {
        &Banner {
            &Web {
                &__inner {
                    padding-bottom: 180px;
                }
                &__image {
                    position: absolute;
                    bottom: 0;
                    right: -80px;
                }
            }
        }
        &__quick {
            max-width: 300px;
            margin-right: auto;
            margin-left: auto;
            &Title {
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .headerMagic {
        &__links {
            display: none;
        }
    }
    .contentMagic {
        &__banner {
            height: 180px;
        }
        &Carousel {
            max-width: 320px;
        }
        &__points {
            padding-right: 15px;
            padding-left: 15px;
        }
        &Banner {
            &Web {
                &__image {
                    right: -170px;
                }
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .contentMagic {
        &__points {
            padding-right: 0;
            padding-left: 0;
        }
        &Banner {
            &Web {
                &__image {
                    right: -280px;
                    margin-right: 0;
                }
            }
            &Adv {
                &__table {
                    left: 0;
                }
            }
        }
        &__carousel {
            padding-right: 15px;
            padding-left: 15px;
            .contentMagicPoint {
                &__title {
                    font-size: 20px;
                }
            }
        }
    }
}