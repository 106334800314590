.myCompanyItem {
    width: 350px;
    min-height: 230px;
    position: relative;
    padding: 60px 20px 20px;
    border: 1px solid #eee;
    margin-bottom: 30px;
    border-radius: 8px;
    background-color: $white;
    &__title {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 20px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 18px;
        padding-top: 2px;
    }
    &__phone {
        display: flex;
        max-width: 140px;
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 16px;
        margin-top: 6px;
        &:hover {
            color: $nightrider;
            text-decoration: none;
        }
    }
    &__links {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-top: 30px;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: #bbb;
            position: absolute;
            top: 15px;
            left: 0;
        }
        .link + .link {
            margin-top: 8px;
        }
    }
    &__setMain {
        display: block;
        width: 140px;
        color: $dimgray;
        @extend %roboto;
        font-size: 12px;
        background-color: $white;
        border: 1px solid #dedede;
        border-radius: 4px;
        text-align: center;
        padding: 5px 10px;
        position: absolute;
        top: 20px;
        left: 20px;
        &:hover {
            color: $white;
            background-color: #66b1ee;
            background-image: linear-gradient(180deg, #66B1EE 0%, #1D8EEE 100%);
            border-color: #66b1ee;
            text-decoration: none;
        }
    }
    &__labelMain {
        display: none;
        width: 140px;
        color: $dimgray;
        @extend %roboto;
        font-size: 12px;
        background-color: #f7f7f7;
        border: 1px solid #f7f7f7;
        border-radius: 4px;
        text-align: center;
        padding: 5px 10px;
        position: absolute;
        top: 20px;
        left: 20px;
    }
    &.main &__setMain { display: none; }
    &.main &__labelMain { display: block; }
}

.addMyCompanyItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 350px;
    height: 230px;
    position: relative;
    padding: 40px 60px;
    border: 1px solid #eee;
    margin-bottom: 30px;
    border-radius: 8px;
    background-color: $white;
    cursor: pointer;
    &__text {
        width: 100%;
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        text-align: center;
        margin-top: 10px;
    }
    &__link {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    &:hover {
        border-color: $blue;
        .addMyCompanyItem {
            &__text {
                color: $blue;
            }
        }
        i {
            background-position: -5px 100px;
        }
    }
}

.myCompaniesHeadline {
    padding: 15px 0 35px;
}

@media screen and (max-width: 1199px) {
    .myCompanyItem, .addMyCompanyItem {
        width: 370px;
    }
}

@media screen and (max-width: 959px) {
    .myCompanyItem, .addMyCompanyItem {
        width: 330px;
    }
}

@media screen and (max-width: 767px) {
    .myCompanyItem, .addMyCompanyItem {
        width: 240px;
    }
}

@media screen and (max-width: 575px) {
    .myCompanyItem, .addMyCompanyItem {
        width: 100%;
    }
}
