.profile {
    max-width: 820px;
    &Headline {
        position: relative;
        margin-top: 20px;
        .b-title {
            margin-bottom: 20px;
        }
    }
   &Info {
       &__line {
           color: $nightrider;
           @extend %roboto-medium;
           font-size: 14px;
       }

   }
    &ContractsInfo {
        &Line {
            display: flex;
            & + & {
                margin-top: 8px;
            }
        }
        &__param {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
            margin-right: 30px;
        }
        &__value {
            color: $nightrider;
            @extend %roboto;
            font-size: 15px;
        }
    }
    &AddAddr {
        position: relative;
        height: 290px;
        padding: 20px;
        &:hover {
            .profileAddAddr__lbl { color: $blue; }
            i { @extend %plus-big-circle-blue-icon; }
        }
        i {
            margin: 60px auto 0 auto;
        }
        &__lbl {
            color: $dimgray;
            @extend %roboto-medium;
            font-size: 18px;
            text-align: center;
            margin-top: 30px;
        }
        &__link {
            border: 2px dashed $lightgrey;
            border-radius: 2px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            &:hover {
                border-color: $blue;
            }
        }
    }
    &MyAddr {
        border: 2px solid $lightgrey;
        border-radius: 2px;
        min-height: 290px;
        padding: 75px 25px 30px 25px;
        position: relative;
        &__name, &__phone {
            color: $nightrider;
            @extend %roboto;
            font-size: 16px;
        }
        &__phone {
            margin-top: 5px;
        }
        &__address {
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            margin: 15px 0 0;
            span {
                display: block;
            }
        }
        &__links {
            font-size: 0;
            margin-top: 10px;
            a + a {
                margin-left: 25px;
            }
        }
        .statusMarker {
            position: absolute;
            top: 30px;
            left: 25px;
            display: none;
        }
        &.main-address {
            .statusMarker {
                display: block;
            }
            .js-main-address {
                display: none;
            }
        }
    }
    &__area {
        padding-bottom: 40px;
        & + & {
            padding-top: 40px;
            border-top: 1px solid #dedede;
        }
        button[type=submit] {
            margin-top: 30px;
        }
        .err-msg {
            font-size: 12px;
            margin-top: 5px;
        }
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        margin-right: 30px;
    }
}

@media screen and (max-width: 480px) {
    .profile {
        &__area {
            .field {
                width: 100% !important;
            }
        }
    }
}