.myOrders-container {
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
}

.myOrders {
    margin-top: 25px;
    &Headline {
        display: flex;
        align-items: center;
        margin-bottom: 45px;
        position: relative;
        .b-title {
            font-size: 26px;
            margin: 0 35px 0 0;
        }
    }
    &Search {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: 600px;
        i {
            position: absolute;
            top: 8px;
            left: 8px;
            transform: scale(0.8);
        }
        &__input {
            width: 100%;
            max-width: 445px;
            padding: 7px 30px 7px 37px;
            border: 1px solid #ccc;
            margin-right: 10px;
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            border-radius: 2px;
            outline: none;
        }
    }
    &LatePayment {
        display: flex;
        flex-direction: column;
        padding: 8px 10px;
        border: 1px solid #ddd;
        background-color: $white;
        border-radius: 2px;
        position: absolute;
        right: 0;
        bottom: -15px;
        &__text, &__value {
            color: $nightrider;
        }
        &__text {
            @extend %roboto;
            font-size: 13px;
            line-height: 15px;
        }
        &__value {
            display: flex;
            align-items: center;
            align-self: flex-end;
            @extend %roboto-medium;
            font-size: 14px;
            line-height: 18px;
            margin-top: 4px;
            &:before {
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                background-color: $radicalred;
                border-radius: 50%;
                margin-right: 4px;
            }
            &:after {
                color: #888;
                @extend %roboto;
                font-size: 12px;
                line-height: 16px;
                align-self: flex-end;
            }
        }
    }
    &Params {
        background-color: $white;
        border-radius: 4px;
        font-size: 0;
        padding: 15px 0;
        margin-bottom: 10px;
        white-space: nowrap;
        &__title {
            width: 140px;
            color: #464646;
            @extend %roboto-medium;
            font-size: 14px;
            line-height: 16px;
            display: inline-block;
            & + & {
                margin-left: 15px;
            }
            &:nth-child(2) {
                width: 455px;
                margin-left: 100px;
            }
            &:nth-child(4) {
                margin-left: 70px;
            }
            &:nth-child(5) {
                margin-left: 98px;
            }
        }
    }
    &Filters {
        position: relative;
        margin-bottom: 50px;
        &__dropdowns {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            bottom: 12px;
            .b-dropdown {
                margin-left: 10px;
            }
        }
        .mobileFilters {
            display: none;
            &__field {
                color: $nightrider;
                @extend %roboto-medium;
                font-size: 14px;
            }
            &__variants {
                display: flex;
                align-items: center;
                color: $nightrider;
                @extend %roboto;
                font-size: 14px;
                i {
                    margin-left: 10px;
                }
            }
        }
    }
    &Pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
}

.b-navigation {
    &__list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        border-bottom: 1px solid #ddd;
    }
    &__item {
        padding-bottom: 10px;
        line-height: 16px;
        &.active {
            border-bottom: 3px solid $blue;
            a {
                color: $blue;
            }
        }
        & + & {
            margin-left: 30px;
        }
    }
    &__link {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        &:hover {
            color: #1B4D80;
            text-decoration: none;
        }
    }
}

.myOrderItem {
    padding: 20px 0;
    border-top: 1px solid #eee;
    background-color: $white;
    border-radius: 2px;
    position: relative;
    &__top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__bottom {
        margin-top: 25px;
    }
    &__wrap {
        display: flex;
        justify-content: space-between;
    }
    &__inner {
        display: flex;
        align-items: flex-start;
        position: relative;
        & > div {
            display: flex;
        }
    }
    &__numbers {
        justify-content: space-between;
        flex-direction: column;
        width: 220px;
        max-width: 220px;
        margin-right: 20px;
        overflow: hidden;
    }
    &__seller {
        justify-content: space-between;
        flex-direction: column;
        width: 350px;
        max-width: 350px;
        padding-right: 50px;
        margin-right: 120px;
        position: relative;
        overflow: hidden;
        .b2b {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
        }
    }
    &__delivery {
        justify-content: space-between;
        flex-direction: column;
        width: 160px;
        max-width: 160px;
        margin-right: 50px;
        overflow: hidden;
    }
    &__payment {
        justify-content: space-between;
        flex-direction: column;
        width: 160px;
        max-width: 160px;
        margin-right: 80px;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        line-height: 18px;
        overflow: hidden;
        white-space: nowrap;
        & + div {
            margin-top: 5px;
        }
        &--medium {
            @extend %roboto-medium;
            font-size: 14px;
        }
        &--gray {
            color: $dimgray;
        }
        &--lightgray {
            color: $nobel;
        }
    }
    &__price {
        max-width: 80px;
        overflow: hidden;
        justify-content: space-between;
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        line-height: 18px;
        white-space: nowrap;
        position: relative;
        margin-right: 6px;
    }
    &__value {
        display: flex;
        align-items: center;
    }
    &__postponement {
        justify-content: space-between;
        flex-direction: column;
        width: 75px;
        max-width: 75px;
        overflow: hidden;
        color: $nobel;
        @extend %roboto-medium;
        font-size: 13px;
        line-height: 18px;
        white-space: normal;
        .postponementIndicator {
            &__expired {
                display: block;
                color: $nightrider;
                @extend %roboto;
                font-size: 13px;
                margin-bottom: 5px;
            }
            &__time {
                display: flex;
                align-items: center;
                color: $nightrider;
                @extend %roboto;
                font-size: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &:before {
                    content: '';
                    display: block;
                    width: 14px;
                    min-width: 14px;
                    height: 14px;
                    background-color: #ddd;
                    border-radius: 20px;
                    margin-right: 6px;
                }
            }
            &.soon {
                .postponementIndicator__time {
                    &:before {
                        background-color: #FFBC3A;
                    }
                }
            }
            &.expired {
                .postponementIndicator__time {
                    &:before {
                        background-color: #FF494F;
                    }
                }
            }
        }
    }
    &__details {
        justify-content: center;
        flex-direction: column;
    }
    &__return {
        &Text {
            color: $nightrider;
            @extend %roboto;
            font-size: 13px;
            max-width: 170px;
        }
        &Item {
            display: flex;
            align-items: center;
            margin-top: 15px;
            & + & {
                margin-top: 20px;
            }
        }
        &Info {
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            min-width: 220px;
            max-width: 220px;
            margin-right: 20px;
            span {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &Link {
            width: 280px;
            .btn-theme {
                width: 100%;
            }
        }
    }
    &:last-child {
        border-bottom: 1px solid #eee;
    }
}

.b-dropdown {
    display: flex;
    align-items: center;
    height: 32px;
    min-height: 32px;
    max-width: 160px;
    max-height: 30px;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    background-color: $white;
    position: relative;
    cursor: pointer;
    &__selected {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 40px 0 15px;
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        user-select: none;
        &:after {
            content: '';
            display: block;
            width: 12px;
            height: 7px;
            background: url('../img/sprite.svg') 0 -1440px;
            position: absolute;
            top: 12px;
            right: 15px;
        }
    }
    &__content {
        display: none;
        width: 100%;
        margin-top: 5px;
        background-color: $white;
        border-radius: 2px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        .b-checkbox {
            padding: 0 15px;
        }
        .b-checkbox + .b-checkbox {
            margin-top: 12px;
        }
    }
    &__items {
        padding: 14px 0;
    }
    &__item {
        padding: 5px 15px;
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 15px;
        &:hover {
            background-color: #e9e9e9;
        }
        & + & {
            margin-top: 5px;
        }
    }
    &__btn {
        padding: 15px;
        border-top: 1px solid #ECECEC;
        .btn-theme { width: 100%; }
    }
    &__item {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
    }
    &.show &__content {
        display: block;
    }
    &.show &__selected:after {
        transform: rotate(180deg);
    }
}
