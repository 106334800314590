.numUpDown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    height: 35px;
    border: 1px solid #dadada;
    border-radius: 2px;
    &__control {
        width: 30px;
        height: 100%;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &--dec {
            &:before {
                content: '';
                display: block;
                width: 14px;
                height: 2px;
                background: url('../img/sprite.svg') 0 -1312px;
            }
        }
        &--inc {
            &:before {
                content: '';
                display: block;
                width: 13px;
                height: 13px;
                background: url('../img/sprite.svg') 0 -33px;
            }
        }
    }
    &__field {
        padding: 0;
        max-width: 40px;
        text-align: center;
        border: 0;
        outline: none;
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        line-height: 16px;
    }
}