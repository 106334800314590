.companyCard {
    background-color: $white;
    border: 1px solid #E5E5E5;
    border-radius: 2px;
    &__inner {
        padding: 18px 20px 25px;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
    }
    &__headline {
        display: flex;
        align-items: center;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 20px;
        line-height: 24px;
        margin-right: 10px;
    }
    &__info {
        margin-top: 10px;
    }
    &__detail {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            margin-right: 15px;
        }
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 18px;
    }
    &__phone, &__email {
        margin-top: 4px;
    }
    &__links {
        display: flex;
        margin-top: 12px;
    }
    &__link {
        margin-right: 35px;
    }
    &__toggleLink {
        display: flex;
        align-items: center;
        cursor: pointer;
        i { margin-left: 4px; }
    }
    &__items {
        margin-top: 5px;
    }
    &__content {
        display: none;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
        &Wrap {
            padding: 25px 20px 0;
        }
        &Title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 20px;
            line-height: 20px;
        }
        &Headline {
            display: flex;
            padding: 15px 20px;
            margin-top: 20px;
            background-color: #F7F7F7;
            border-radius: 4px;
        }
        &Col {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
            line-height: 14px;
            &:nth-child(1) { width: 140px; margin-right: 190px; }
            &:nth-child(2) { width: 140px; margin-right: 50px; }
            &:nth-child(3) { width: 140px; margin-right: 50px; }
            &:nth-child(4) { width: 140px; margin-right: 50px; }
        }
        &Hide {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 9px 20px;
            border-top: 1px solid #e4e4e4;
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            cursor: pointer;
            i {
                margin-left: 4px;
                transform: rotate(180deg); }
        }
    }
    .statusMarker { display: none; }
    &.open &__content { display: block; }
    &.main &__companyLink { display: none; }
    &.main .statusMarker { display: inline-block; background-color: $solitude; }
    & + & {
        margin-top: 25px;
    }
}

.companyCardItem {
    display: flex;
    padding: 20px 0;
    &__info {
        width: 350px;
        max-width: 350px;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        max-width: 320px;
    }
    &__address {
        color: $nightrider;
        @extend %roboto;
        font-size: 12px;
        max-width: 320px;
    }
    &__links {
        display: flex;
        align-items: center;
        margin-top: 8px;
    }
    &__link {
        margin-right: 20px;
    }
    &__params {
        display: flex;
        margin-top: 15px;
    }
    &__request {
        margin-top: 15px;
        color: $dimgray;
        @extend %roboto;
        font-size: 14px;
        &--success { color: $seagreen; }
    }
    &__param {
        width: 140px;
        max-width: 140px;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        & + & {
            margin-left: 50px;
        }
    }
    & + & {
        border-top: 1px solid #e4e4e4;
    }
}

.companyItem {
    display: flex;
    padding: 20px 0;
    &__info {
        width: 350px;
        max-width: 350px;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
    }
    &__detail {
        display: flex;
        align-items: center;
        margin-top: 5px;
        div {
            margin-right: 16px;
        }
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
    }
    &__address {
        max-width: 320px;
        margin-top: 5px;
    }
    &__params {
        display: flex;
        padding-top: 10px;
    }
    &__status {
        width: 120px;
        max-width: 120px;
        display: flex;
        justify-content: center;
        padding-top: 10px;
        .b2b {
            margin-left: 0;
            &:after {
                width: 40px;
                height: 27px;
                font-size: 16px;
                line-height: 27px;
            }
        }
    }
    &__contracts {
        margin-left: 60px;
    }
    &__contract {
        & + & {
            margin-top: 2px;
        }
    }
    & + & {
        border-top: 1px solid #e4e4e4;
    }
}

.tab-buttons {
    display: inline-flex;
    border-bottom: 1px solid $nobel;
    .tab-button {
        color: $nightrider;
        @extend %roboto;
        font-size: 22px;
        line-height: 36px;
        cursor: pointer;
        &.active {
            border-bottom: 4px solid $nobel;
            @extend %roboto-medium;
        }
        &:first-child { padding-right: 30px; }
        &:last-child { padding-left: 30px; }
    }
}

.tab-container {
    margin-top: 30px;
    .tab-content {
        display: none;
        position: relative;
        &.active {
            display: block;
        }
        .btn-invite {
            position: absolute;
            top:-60px;
            right: 0;
        }
    }
}