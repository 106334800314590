.heart-icon {
    width: 20px;
    height: 18px;
    @extend %heart-icon; }

.compass-icon {
    width: 20px;
    height: 19px;
    @extend %compass-icon; }

.info-icon {
    width: 20px;
    height: 19px;
    @extend %info-icon; }

.review-icon {
    width: 20px;
    height: 20px;
    @extend %review-icon; }

.heart-white-icon {
    width: 20px;
    height: 18px;
    @extend %heart-white-icon; }

.compass-white-icon {
    width: 20px;
    height: 19px;
    @extend %compass-white-icon; }

.info-white-icon {
    width: 20px;
    height: 19px;
    @extend %info-white-icon; }

.review-white-icon {
    width: 20px;
    height: 20px;
    @extend %review-white-icon; }

.visa {
    width: 43px;
    height: 13px;
    @extend %visa-icon; }

.mastercard {
    width: 36px;
    height: 21px;
    @extend %mastercard-icon; }

.percent-icon {
    display: block;
    width: 11px;
    height: 12px;
    @extend %percent-icon; }

.darkstar-icon {
    width: 16px;
    height: 15px;
    @extend %darkstar-icon;
    &:hover {
        width: 16px;
        height: 15px;
        @extend %darkstar-hover-icon; } }

.darkbin-icon {
    width: 13px;
    height: 17px;
    @extend %darkbin-icon;
    &:hover {
        width: 13px;
        height: 17px;
        @extend %darkbin-hover-icon; } }

.description-icon {
    display: block;
    width: 10px;
    height: 13px;
    @extend %description-icon;
}

.star-bold-blue-icon {
    display: block;
    width: 15px;
    height: 15px;
    @extend %star-bold-blue-icon;
}

.unboxed {
    display: block;
    width: 45px;
    height: 43px;
    background: url('../img/unboxed.svg') 0 0;
}

.boxed {
    display: block;
    width: 39px;
    height: 39px;
    background: url('../img/boxed.svg') 0 0;
}

.vk-icon {
    display: block;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    @extend %vk-icon; }

.telegram-icon {
    display: block;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    @extend %telegram-icon; }

.attention-white-icon {
    display: block;
    width: 24px;
    height: 22px;
    @extend %attention-white-icon; }

.attention-red-icon {
    display: block;
    min-width: 32px;
    min-height: 30px;
    @extend %attention-red-icon;
    }

.attention-orange-icon {
    display: block;
    min-width: 32px;
    min-height: 30px;
    @extend %attention-orange-icon;
}

.plus-big-circle-icon {
    display: block;
    width: 70px;
    height: 70px;
    @extend %plus-big-circle-icon; }

.checked-success-icon {
    display: block;
    width: 35px;
    height: 26px;
    @extend %checked-success-icon; }

.cart-green-icon {
    display: block;
    width: 25px;
    height: 23px;
    @extend %cart-green-icon; }

.cart-black-icon {
    display: block;
    width: 22px;
    height: 19px;
    min-width: 22px;
    min-height: 19px;
    @extend %cart-black-icon; }

.box-icon {
    display: block;
    min-width: 55px;
    min-height: 55px;
    @extend %box-icon; }

.lock-icon {
    display: block;
    min-width: 57px;
    min-height: 70px;
    @extend %lock-icon; }

.placeholder-icon {
    display: block;
    min-width: 54px;
    min-height: 54px;
    @extend %placeholder-icon; }

.creditcard-icon {
    display: block;
    min-width: 55px;
    min-height: 55px;
    @extend %creditcard-icon; }

.gift-icon {
    display: block;
    min-width: 52px;
    min-height: 52px;
    @extend %gift-icon; }

.help-icon {
    display: block;
    min-width: 49px;
    min-height: 49px;
    @extend %help-icon; }

.b2b-icon {
    display: block;
    min-width: 50px;
    min-height: 30px;
    @extend %b2b-icon; }

.soc-icon {
    display: block;
    min-width: 55px;
    min-height: 33px;
    @extend %soc-icon; }

.heart-blue-small-icon {
    display: block;
    width: 14px;
    height: 13px;
    @extend %heart-blue-small-icon; }

.heart-blue-medium-icon {
    display: block;
    width: 20px;
    height: 15px;
    @extend %heart-blue-medium-icon; }

.star-blue-small-icon {
    display: block;
    width: 15px;
    height: 15px;
    @extend %star-blue-small-icon; }

.nav-small-icon {
    display: block;
    width: 15px;
    height: 15px;
    @extend %nav-small-icon; }

.cart-white-icon {
    display: block;
    width: 21px;
    height: 19px;
    @extend %cart-white-icon; }

.attention-mc-icon {
    display: block;
    width: 77px;
    min-width: 77px;
    height: 53px;
    min-height: 53px;
    @extend %attention-mc-icon; }

.check-sm-icon {
    display: block;
    width: 20px;
    height: 19px;
    @extend %check-sm-icon; }

.check-md-icon {
    display: block;
    width: 14px;
    height: 11px;
    @extend %check-md-icon; }

.unboxed-sm-icon {
    display: block;
    width: 117px;
    height: 117px;
    @extend %unboxed-sm-icon; }

.unboxed-md-icon {
    display: block;
    width: 189px;
    height: 191px;
    @extend %unboxed-md-icon; }

.unboxed-lg-icon {
    display: block;
    width: 364px;
    height: 364px;
    @extend %unboxed-lg-icon; }

.search-blue-icon {
    display: block;
    width: 17px;
    min-width: 17px;
    height: 18px;
    min-height: 18px;
    @extend %search-blue-icon; }

.clock-gray-icon {
    display: block;
    width: 13px;
    height: 13px;
    @extend %clock-gray-icon; }

.clock-red-icon {
    display: block;
    width: 13px;
    height: 13px;
    @extend %clock-red-icon; }

.arrow-down-icon {
    display: block;
    width: 15px;
    height: 9px;
    min-width: 15px;
    min-height: 9px;
    @extend %arrow-down-icon;
}

.little-arrow-down-icon {
    display: block;
    width: 12px;
    height: 7px;
    @extend  %little-arrow-down-icon;
}
.arrow-left-icon {
    display: block;
    width: 9px;
    height: 15px;
    min-width: 9px;
    min-height: 15px;
    @extend %arrow-left-icon; }

.arrow-right-gray-icon {
    display: block;
    width: 9px;
    height: 14px;
    min-width: 9px;
    min-height: 14px;
    @extend %arrow-right-gray-icon; }

.arrow-right-black-icon {
    display: block;
    width: 9px;
    height: 14px;
    min-width: 9px;
    min-height: 14px;
    @extend %arrow-right-black-icon; }

.location-gray-icon {
    display: block;
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    @extend %location-gray-icon; }

.location-lightblue-icon {
    display: block;
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    @extend %location-lightblue-icon; }

.search-gray-icon {
    display: block;
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    @extend %search-gray-icon; }

.account-icon {
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    @extend %account-icon; }

.visit-icon {
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    @extend %visit-icon;
    background-size: cover !important; }

.orders-icon {
    display: block;
    width: 19px;
    height: 26px;
    min-width: 19px;
    min-height: 26px;
    @extend %orders-icon; }

.cart-icon {
    display: block;
    width: 25px;
    height: 24px;
    min-width: 25px;
    min-height: 24px;
    @extend %cart-icon; }

.arrow-back-white-icon {
    display: block;
    width: 20px;
    height: 12px;
    min-width: 20px;
    min-height: 12px;
    @extend %arrow-back-white-icon; }

.locate-black-icon {
    display: block;
    min-width: 15px;
    min-height: 18px;
    max-width: 15px;
    max-height: 18px;
    @extend %locate-black-icon; }

.arrow-black-small-icon {
    display: block;
    min-width: 12px;
    min-height: 10px;
    max-width: 12px;
    max-height: 10px;
    @extend %arrow-black-small-icon; }

.triangle-in-circle-gray-icon {
    display: block;
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    @extend %triangle-in-circle-gray-icon; }

.arrow-gray-small-icon {
    display: block;
    min-width: 6px;
    min-height: 9px;
    max-width: 6px;
    max-height: 9px;
    @extend %arrow-gray-small-icon; }

.arrow-lightgray-right-small-icon {
    display: block;
    min-width: 7px;
    min-height: 10px;
    max-width: 7px;
    max-height: 10px;
    @extend %arrow-lightgray-right-small-icon; }

.array-to-top-icon {
    display: block;
    width: 30px;
    min-width: 30px;
    height: 18px;
    @extend %array-to-top-icon; }

.array-to-bottom-icon {
    display: block;
    width: 30px;
    min-width: 30px;
    height: 18px;
    @extend %array-to-bottom-icon; }

.triangle-black-small-icon {
    display: block;
    width: 9px;
    height: 7px;
    @extend %triangle-black-small-icon; }

.cross-small-icon {
    display: block;
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    @extend %cross-small-icon; }

.add-circle-icon {
    display: block;
    width: 90px;
    height: 90px;
    min-width: 90px;
    min-height: 90px;
    @extend %add-circle-icon; }

.chevron-left-gray-icon {
    display: block;
    width: 11px;
    height: 18px;
    min-width: 11px;
    min-height: 18px;
    @extend %chevron-left-gray-icon; }

.cart-in-way-icon {
    display: block;
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;
    @extend %cart-in-way-icon; }

.arrow-big-blue-icon {
    display: block;
    width: 78px;
    height: 51px;
    min-width: 78px;
    min-height: 51px;
    @extend %arrow-big-blue-icon; }

.two-arrows-white-icon {
    display: block;
    width: 17px;
    height: 18px;
    min-width: 17px;
    min-height: 18px;
    @extend %two-arrows-white-icon; }

.sticky-icon {
    display: block;
    width: 11px;
    height: 16px;
    min-width: 11px;
    min-height: 16px;
    @extend %sticky-icon; }

.communicate-icon {
    display: block;
    width: 23px;
    height: 16px;
    min-width: 23px;
    min-height: 16px;
    @extend %communicate-icon; }

.pagination-back-icon {
    display: block;
    width: 9px;
    height: 17px;
    min-width: 9px;
    min-height: 17px;
    @extend %pagination-back-icon; }

.pagination-next-icon {
    display: block;
    width: 9px;
    height: 17px;
    min-width: 9px;
    min-height: 17px;
    @extend %pagination-next-icon; }

.pagination-back-disabled-icon {
    display: block;
    width: 9px;
    height: 17px;
    min-width: 9px;
    min-height: 17px;
    @extend %pagination-back-disabled-icon; }

.pagination-next-disabled-icon {
    display: block;
    width: 9px;
    height: 17px;
    min-width: 9px;
    min-height: 17px;
    @extend %pagination-next-disabled-icon; }

.success-circle-icon {
    display: block;
    width: 43px;
    height: 43px;
    min-width: 43px;
    min-height: 43px;
    @extend %success-circle-icon; }
