.radio {
    & + span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: 2px solid $nobel;
        background-color: $white;
        border-radius: 50%;
    }
    &:checked + span {
        border-color: $blue;
    }
    &:checked + span:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background-color: $blue;
        border-radius: 50%;
    }
}

.radioItem {
    background-color: #eee;
    border-radius: 6px;
    position: relative;
    &.active {
        background-color: $solitude;
    }
    &__label {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        margin: 0;
        cursor: pointer;
    }
    &__radio {
        margin-right: 20px;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        width: 240px;
        max-width: 240px;
        overflow: hidden;
        padding-right: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        user-select: none;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        user-select: none;
    }
    &__description {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        user-select: none;
    }
    &__link {
        color: #205D9B;
        @extend %roboto;
        font-size: 13px;
        border-bottom: 1px solid #205D9B;
        line-height: 16px;
        position: absolute;
        top: 18px;
        right: 20px;
        &:hover {
            color: $dodgerblue;
            border-color: $dodgerblue;
            text-decoration: none;
        }
    }
    & + & {
        margin-top: 10px;
    }
}

.radiobutton {
    &__label {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 0;
    }
    &__input {
        display: none;
        & + span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            min-width: 16px;
            height: 16px;
            border: 1px solid $blue;
            background-color: $white;
            border-radius: 50%;
        }
        &:checked + span {
            border-color: $blue;
        }
        &:checked + span:after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background-color: $blue;
            border-radius: 50%;
        }
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        margin-left: 10px;
    }
    &__text {
        max-width: 725px;
        color: $seagreen;
        @extend %roboto;
        font-size: 14px;
        line-height: 18px;
        padding-left: 26px;
        margin-top: 4px;
    }
    & + & {
        margin-top: 15px;
    }
    &.disabled {
        user-select: none;
        .radiobutton {
            &__input {
                & + span {
                    border-color: $nobel;
                }
                &:checked + span {
                    border-color: $nobel;
                }
                &:checked + span:after {
                    background-color: $nobel;
                }
            }
        }
    }
}

.radio-group {
    &__btn {
        display: none;
        & + span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border: 1px solid $dimgray;
            background-color: $white;
            border-radius: 50%;
        }
        &:checked + span {
            border-color: $dimgray;
        }
        &:checked + span:after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background-color: $dimgray;
            border-radius: 50%;
        }
    }
    &__label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        margin-left: 12px;
    }
}
