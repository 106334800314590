.password-status {
    &.simple { color: $radicalred; }
    &.medium { color: #FFBC3A; }
    &.high { color: #219653; } }

.has-error {
    .err-msg {
        display: block;
    }

    input {
        border: 1px solid $radicalred;
    }
}

.err-msg {
    color: $radicalred;
    @extend %roboto;
    display: none;
}
    