@import "slider.scss";
.mainPage {
    padding: 45px 0 35px;
    &Content {
        &Title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 28px;
        }
    }
}

$slider-dot-color: #e6e6e6;
$slider-dot-active-color: #c4c4c4;
$slider-control-color: #fff;

$slider-height: 360px;
$slider-border-radius: 4px;
$slider-dot-width: 12px;

.mainSliderWrapper {
    width: 100%;
    max-width: 100%;
    height: $slider-height;
    border-radius: $slider-border-radius;
    position: relative;
    overflow: hidden;
}

// for old slider with static images (deprecated)
.mainGallery {
    width: 100%;
    max-width: 100%;
    height: $slider-height;
    border-radius: $slider-border-radius;
    position: relative;
    overflow: hidden;
    &__slides {
        display: grid;
        height: 100%;
        grid-area: 1 / 1 / -1 / -1;
        position: relative;
        z-index: 10;
    }
    &__slide {
        display: none;
        place-self: center;
        align-self: end;
        &Link {
            position: relative;
        }
        &.active {
            display: flex;
        }
        img {
            display: block;
            margin: 0 auto;
        }
    }
    &__controls {
        display: grid;
        height: 100%;
        grid-area: 1 / 1 / -1 / -1;
        grid-template: 10% auto 10% / 20% auto 20%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    &__prev {
        display: grid;
        grid-area: 1 / 1 / span 3 / span 1;
        width: 100px;
        height: 100%;
        color: $slider-control-color;
        border-top-left-radius: $slider-border-radius;
        border-bottom-left-radius: $slider-border-radius;
        place-self: self-start;
        place-content: center;

        &:hover {
            background: linear-gradient(90deg, rgba(196, 196, 196, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
            cursor: pointer;
        }
    }
    &__next {
        display: grid;
        grid-area: 1 / 3 / span 3 / span 1;
        width: 100px;
        height: 100%;
        color: $slider-control-color;
        border-top-right-radius: $slider-border-radius;
        border-bottom-right-radius: $slider-border-radius;
        place-self: self-end;
        place-content: center;

        &:hover {
            background: linear-gradient(-90deg, rgba(196, 196, 196, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
            cursor: pointer;
        }
    }
    &__prev,
    &__next {
        z-index: 10;
        & span {
            font-weight: 400;
            font-size: 48px;
        }
    }
    &__dots {
        display: grid;
        grid-area: 3 / 2 / span 1 / span 1;
        grid-template-columns: repeat(6, auto);
        grid-column-gap: $slider-dot-width;
        justify-content: center;
        align-content: flex-start;
        z-index: 10;
    }
    &__dot {
        background-color: $slider-dot-color;
        cursor: pointer;
        transition: 0.5s ease;
        width: $slider-dot-width;
        aspect-ratio: 1;
        border-radius: 50%;

        &.active,
        &:hover {
            background-color: $slider-dot-active-color;
        }
    }
}

.mainCategory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 35px;
    gap: 20px;
    &Item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
        width: 100%;
        max-width: 690px;
        height: 360px;
        padding: 40px;
        position: relative;
        border-radius: 4px;
        &__title {
            color: $white;
            @extend %roboto-bold;
            font-size: 28px;
            white-space: nowrap;
            position: relative;
            z-index: 10;
        }
        &__text {
            color: $white;
            @extend %roboto;
            font-size: 16px;
            line-height: 20px;
            margin-top: 6px;
            max-width: 400px;
            position: relative;
            z-index: 10;
        }
        &__img {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 4px;
            z-index: -1;
        }
        &__link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 20;
        }
    }
}

.mainCategories {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 35px 0;
    gap: 30px;
    &Item {
        display: flex;
        flex-direction: column;
        max-width: 180px;
        position: relative;
        &__box {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 180px;
            max-width: 180px;
            min-height: 180px;
            max-height: 180px;
            background-color: #f8f8f8;
            border-radius: 50%;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &__title {
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            line-height: 20px;
            margin-top: 24px;
            text-align: center;
            min-height: 40px;
        }
        &__link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }
}

.mainProducts {
    display: flex;
    flex-direction: column;
    padding: 35px 0;
    &__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;
        gap: 30px;
    }
}

.mainProduct {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    position: relative;
    &__link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    &__bestseller {
        color: $white;
        font-size: 13px;
        @extend %roboto;
        text-align: center;
        background-color: #00a3ff;
        border-radius: 20px;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 2px 6px;
    }
    &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 200px;
        max-width: 200px;
        min-height: 200px;
        max-height: 200px;
        position: relative;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__title {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 18px;
        margin-top: 12px;
        max-height: 36px;
        overflow: hidden;
    }
    &__price {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        &-new {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 18px;
            line-height: 20px;
            margin-right: 10px;
        }
        &-old {
            color: $nobel;
            @extend %roboto-medium;
            font-size: 14px;
            position: relative;
            padding: 0 5px;
            &:before {
                content: '';
                width: 100%;
                height: 1px;
                background-color: $nobel;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
            }
        }
    }
}

.brandBanner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 52px 60px;
    margin: 35px 0;
    border-radius: 4px;
    background: linear-gradient(90deg,#000 40%,rgba(51,51,51,.6) 98%);
    &__img {
        margin-bottom: 20px;
    }
    &__title {
        color: $white;
        @extend %roboto;
        font-size: 28px;
        line-height: 36px;
        max-width: 350px;
        text-align: center;
    }
    &__link {
        color: $white;
        @extend %roboto-medium;
        font-size: 12px;
        white-space: nowrap;
        padding: 8px 32px;
        border: 2px solid $white;
        border-radius: 4px;
        transition: all .2s;
        &:hover {
            color: $nobel;
            border-color: $nobel;
        }
    }
}

.mainStock {
    padding: 35px 0;
    &__items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 30px;
        gap: 30px;
    }
    &Item {
        max-width: 330px;
        position: relative;
        &__link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
        &__box {
            min-width: 330px;
            max-width: 330px;
            min-height: 330px;
            max-height: 330px;
            border-radius: 4px;
        }
        &__img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 4px;
        }
        &__title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 18px;
            margin-top: 14px;
        }
        &__text {
            color: $nightrider;
            @extend %roboto;
            font-size: 13px;
            margin-top: 8px;
            min-height: 60px;
            max-height: 60px;
            overflow: hidden;
        }
    }
}

.mainBrands {
    padding: 35px 0;
    &__items {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 30px;
        gap: 30px;
    }
}

.mainBrandItem {
    min-width: 210px;
    max-width: 210px;
    min-height: 130px;
    max-height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

@media screen and (max-width: 1440px) {
    .mainGallery {
        height: 280px;
        &__slide {
            img {
                height: 280px;
            }
        }
    }

    .mainSlider {
        &Slide {
            &__image {
                right: 90px;
            }
            &__content {
                left: 90px;
            }
        }
    }

    .mainCategory {
        padding: 30px 0;
        &Item {
            max-width: 540px;
            height: 300px;
        }
    }

    .mainCategories {
        padding: 30px 0;
        &Item {
            max-width: 160px;
            &__box {
                min-width: 160px;
                max-width: 160px;
                min-height: 160px;
                max-height: 160px;
            }
            &__title {
                min-height: 60px;
            }
        }
    }

    .mainProducts {
        padding: 30px 0;
    }

    .mainProduct {
        max-width: 160px;
        &__box {
            min-width: 160px;
            max-width: 160px;
            min-height: 160px;
            max-height: 160px;
        }
    }

    .brandBanner {
        padding: 52px 40px;
        margin: 30px 0;
        &__title {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .mainStock {
        padding: 30px 0;
        &Item {
            max-width: 255px;
            &__box {
                min-width: 255px;
                max-width: 255px;
                min-height: 255px;
                max-height: 255px;
            }
            &__title {
                font-size: 16px;
                white-space: nowrap;
                min-width: 260px;
                max-width: 260px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .mainBrands {
        padding: 30px 0;
    }

    .mainBrandItem {
        min-width: 160px;
        max-width: 160px;
        min-height: 100px;
        max-height: 100px;
    }
}

@media screen and (max-width: 1200px) {
    .mainCategory {
        &Item {
            padding: 40px 20px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .mainGallery {
        height: 230px;
        &__slide {
            img {
                height: 230px;
            }
        }
    }

    .mainSlider {
        &Slide {
            &__image {
                right: 50px;
            }
            &__content {
                left: 50px;
            }
            &__title {
                font-size: 34px;
                line-height: 44px;
                max-width: 440px;
            }
            &__text {
                max-width: 380px;
            }
        }
        .nav-arrow {
            display: none;
        }
    }

    .mainCategory {
        &Item {
            max-width: 450px;
            height: 250px;
            padding: 20px;
            &__title {
                font-size: 24px;
            }
        }
    }

    .mainCategories {
        &Item {
            max-width: 130px;
            &__box {
                min-width: 130px;
                max-width: 130px;
                min-height: 130px;
                max-height: 130px;
                img {
                    max-width: 50%;
                    max-height: 50%;
                }
            }
        }
    }

    .mainProduct {
        max-width: 130px;
        &__box {
            min-width: 130px;
            max-width: 130px;
            min-height: 130px;
            max-height: 130px;
        }
    }

    .brandBanner {
        &__title {
            font-size: 20px;
            line-height: 26px;
        }
    }

    .mainStock {
        &Item {
            max-width: 210px;
            &__box {
                min-width: 210px;
                max-width: 210px;
                min-height: 210px;
                max-height: 210px;
            }
            &__title {
                white-space: normal;
                min-width: 0;
                max-width: 100%;
                min-height: 48px;
                max-height: 48px;
            }
        }
    }

    .mainBrands {
        &__items {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .mainBrandItem {
            min-width: 0;
            max-width: 100%;
            min-height: 90px;
            max-height: 90px;
        }
    }
}

@media screen and (max-width: 991px) {
    .mainCategory {
        &Item {
            max-width: 330px;
            height: 220px;
            padding: 20px;
            &__title {
                font-size: 16px;
                line-height: 20px;
                white-space: normal;
            }
            &__text {
                font-size: 13px;
                line-height: 17px;
            }
        }
    }

    .mainSlider {
        &Slide {
            &__title {
                font-size: 22px;
                line-height: 28px;
                max-width: 260px;
            }
            &__text {
                font-size: 13px;
                line-height: 17px;
                max-width: 190px;
            }
            &__image {
                right: 20px;
            }
        }
    }

    .mainCategories {
        flex-wrap: nowrap;
        overflow-y: auto;
        gap: 20px;
    }

    .mainProducts {
        &__items {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        .mainProduct {
            width: 100%;
            &__box {
                min-width: 0;
                max-width: 100%;
                min-height: 0;
                max-height: 100%;
            }
        }
    }

    .brandBanner {
        padding: 25px 0;
        position: relative;
        &__img {
            max-width: 40%;
            max-height: 40%;
        }
        &__title {
            max-width: 260px;
            font-size: 16px;
            line-height: 20px;
            position: absolute;
            top: 20px;
            right: 60px;
        }
        &__link {
            position: absolute;
            bottom: 20px;
            right: 60px;
        }
    }

    .mainStock {
        &__items {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }
        &Item {
            min-width: 0;
            max-width: 100%;
            &__box {
                min-width: 0;
                max-width: 100%;
                min-height: 0;
                max-height: 330px;
            }
            &__title {
                font-size: 18px;
                line-height: 24px;
                min-height: 24px;
                max-height: 24px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .mainSlider {
        &Wrapper {
            height: 170px;
        }
        &Slide {
            border-radius: 4px;
            &__content {
                left: 20px;
            }
            &__title {
                font-size: 16px;
                line-height: 24px;
                max-width: 260px;
                margin-bottom: 15px;
            }
            &__image {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 45%;
                height: 100%;
            }
        }
        .nav-point {
            li {
                width: 8px;
                height: 8px;
            }
        }
    }
    
    .mainCategory {
        &Item {
            height: 180px;
            &__title {
                font-size: 14px;
                line-height: 20px;
            }
            &__text {
                font-size: 12px;
                line-height: 17px;
            }
        }
    }
}

@media screen and (max-width: 620px) {
    .mainPage {
        padding: 20px 0;
        &Content {
            &Title {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }

    .mainSlider {
        &Wrapper {
            margin-bottom: 30px;
            overflow: visible;
        }
        .nav-point {
            position: static;
            margin-top: 5px;
            margin-left: 0 !important;
            display: flex;
            justify-content: center;
            li {
                width: 8px;
                height: 8px;
            }
        }
    }

    .mainGallery {
        height: 200px;
        &__slide {
            img {
                height: 200px;
            }
        }
    }

    .mainCategory {
        flex-direction: column;
        &Item {
            width: 100%;
            max-width: 100%;
            &__title {
                font-size: 16px;
            }
            &__img {
                width: 100%;
            }
        }
    }

    .mainCategories {
        &Item {
            max-width: 110px;
            &__box {
                min-width: 110px;
                max-width: 110px;
                min-height: 110px;
                max-height: 110px;
                img {
                    max-width: 50%;
                    max-height: 50%;
                }
            }
        }
    }

    .mainStock {
        &Item {
            &__title {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .mainBrands {
        &__items {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media screen and (max-width: 480px) {
    .mainSlider {
        &Wrapper {
            height: 200px;
        }
        &Slide {
            &__content {
                width: 100%;
                top: 15px;
                left: 0;
                text-align: center;
                transform: none;
            }
            &__title {
                font-size: 16px;
                line-height: 20px;
                margin: 0 auto 5px;
                min-height: 48px;
                max-height: 48px;
                overflow: hidden;
            }
            &__text {
                max-width: 100%;
                font-size: 10px;
                line-height: 12px;
                margin: 0 auto;
                white-space: nowrap;
            }
            &__image {
                top: unset;
                right: 0;
                left: 0;
                bottom: 0;
                justify-content: center;
                align-items: center;
                width: 25%;
                height: 90px;
                transform: none;
                margin: 0 auto;
            }
        }
    }
    .mainCategory {
        padding: 30px 0 15px;
    }

    .mainCategories {
        padding: 15px 0;
        flex-wrap: nowrap;
        overflow-y: auto;
        &Item {
            &__title {
                margin-top: 12px;
            }
        }
    }

    .mainProducts {
        padding: 15px 0;
        &__items {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .brandBanner {
        padding: 15px 25px 45px;
        align-items: flex-start;
        flex-direction: column;
        margin: 15px 0;
        background: linear-gradient(90deg, #000000 45%, #333333 98%);
        &__img {
            max-width: 60%;
            max-height: 60%;
        }
        &__title {
            position: static;
            text-align: left;
            line-height: 24px;
            padding-left: 20px;
        }
        &__link {
            position: static;
            font-size: 14px;
            margin-top: 25px;
            margin-left: 20px;
        }
    }

    .mainStock {
        padding: 15px 0;
        &Item {
            &__title {
                font-size: 14px;
                line-height: 18px;
                min-height: 36px;
                max-height: 36px;
            }
        }
    }

    .mainBrands {
        padding: 15px 0;
        &__items {
            gap: 25px;
        }
    }
}

@media screen and (max-width: 400px) {
    .mainSlider {
        &Slide {
            &__image {
                width: 50%;
                height: 110px;
            }
        }
    }

    .mainProducts {
        &__items {
            grid-template-columns: 1fr 1fr;
        }
    }

    .brandBanner {
        padding: 15px 5px 45px;
    }

    .mainStock {
        &Item {
            &__title {
                font-size: 14px;
                line-height: 18px;
                min-height: 36px;
                max-height: 36px;
            }
        }
    }

    .mainBrandItem {
        min-height: 80px;
        max-height: 80px;
    }
}
