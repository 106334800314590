.imgPopup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    .lvl1 {
        display: none;
        &:hover {
            .lvl2 { opacity: 1; }
        }
    }
    .lvl2 {
        width: 438px;
        height: 290px;
        opacity: 0;
        transition: opacity .3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50px;
        img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 10px;
        }
    }
    &:hover {
        .lvl1 {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
