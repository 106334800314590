// product
.product-container {
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.properties-container,
.comments-container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.properties-container {
    .btn-theme { display: none; }
}

.productAddedNotification {
    width: 100%;
    padding: 15px 12px;
    position: fixed;
    top: -120px;
    left: 0;
    right: 0;
    background-color: $white;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: top .2s linear, opacity 1s linear;
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 18px;
        line-height: 18px;
        &--warning {
            color: $radicalred;
        }
    }
    &__btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }
    .btn-theme {
        min-width: 140px;
        max-height: 36px;
        line-height: 18px;
        width: 48%;
        &--full {
            width: 100%;
        }
    }
    &.onDisplay {
        transition: top .2s linear, opacity .1s linear;
        z-index: 100;
        opacity: 1;
        top: 0;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        &--warning {
            justify-content: flex-start;
        }
        &Icon {
            transform: scale(0.6);
            &--notify {
                color: #ffcf87;
            }
        }

    }
    &__comment {
        font-size: 16px;
        line-height: 18px;
        margin-top: 8px;
        @extend %roboto-medium;
    }
    &__text {
        margin-top: 8px;
        font-size: 14px;
        line-height: 17px;
    }
}

.productAddedPopup {
    max-width: 660px;
    margin-right: auto;
    margin-left: auto;
    background-color: $white;
    .numberselect {
        min-width: 53px;
        &__inputWrapper {
            display: flex;
        }
        &__input {
            max-width: 50px;
        }
        &__inner {
            min-width: 80px;
        }
    }
    &__numberSelectWrap {
        min-width: 130px;
        margin-left: 40px;
    }
    &__headline {
        padding: 15px 18px;
        position: relative;
        display: flex;
        align-items: center;
        &Marker {
            margin-left: 95px;
            padding: 5px 20px;
            color: #333;
            font-size: 14px;
            background-color: #f9f9f9;
            text-align: center;
            border-radius: 4px;
        }
    }
    &__preOrder {
        color: #333;
        background-color: #f9f9f9;
        text-align: center;
        border-radius: 4px;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 24px;
    }
    &__content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 25px;
        border-top: 2px solid #eee;
        border-bottom: 2px solid #eee;
    }
    &__contentWrap {
        border-top: 2px solid #eee;
        border-bottom: 2px solid #eee;
    }
    &__recommendedContent {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 25px;
    }
    &__wrap {
        display: flex;
        align-items: flex-start;
        &--title {
            min-width: 317px;
        }
        &--amounts {
            flex-grow: 1;
            justify-content: flex-end;
        }
    }
    &__imgWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        width: 100px;
        height: 100px;
        max-width: 100px;
        max-height: 100px;
        border: 1px solid #F4F4F4;
        margin-right: 20px;
        border-radius: 4px;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
    }
    &__name {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        max-width: 190px;
    }
    &__amount {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
    }
    &__price {
        padding: 0 40px 0 20px;
    }
    &__cost {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &__new {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 14px;
        padding-left: 10px;
    }
    &__old {
        color: $nobel;
        @extend %roboto-medium;
        font-size: 14px;
        text-decoration: line-through;
        margin-left: 4px;
        display: none;
        &.show {
            display: block;
        }
    }
    &__economy {
        color: #ff6a16;
        @extend %roboto;
        font-size: 14px;
        text-align: right;
        margin-top: 4px;
        display: none;
        &.show {
            display: block;
        }
    }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 70px 15px 0;
    }
    &__link {
        margin-right: 25px;
    }
    .mfp-close {
        cursor: pointer;
        font-size: 0;
        &:before, &:after {
            content: '';
            width: 20px;
            height: 2px;
            background-color: $nightrider;
            position: absolute;
            top: 20px;
            left: 10px;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(135deg);
        }
    }
    .b2b {
        display: none;
        &.show {
            display: block;
        }
    }
}

.productCard {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    &__wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        position: relative;
    }
}
// productSlider
.productSlider { padding-right: 20px; }
// productCharacteristic
.productCharacteristic {
    padding: 0 15px;
    flex-grow: 1;
    position: relative;
}
// productPayment
.productPayment { padding-left: 20px; }

// productProperties
.productProperties {
    padding: 30px 0;
    .b-title {
        margin-bottom: 20px;
    }
}

// productComments
.productComments {
    background-color: #fafafa;
    padding: 40px;
    margin-top: 20px;
    &__headline {
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 30px;
    }
    &__rating {
        display: flex;
        align-items: center;
        margin-top: 15px;
        &Value {
            color: #F2C94C;
            @extend %roboto-bold;
            font-size: 24px;
            margin-right: 10px;
        }
    }
    &__reviews {
        color: $nobel;
        @extend %roboto;
        font-size: 20px;
        margin-left: 10px;
    }
    &__content {
        padding: 40px 0 0;
    }
    &__btn {
        max-width: 660px;
        margin: 60px 0 0 148px;
    }
}

// slider
.slider {
    display: flex;
    justify-content: space-between;
    width: 700px;
    max-width: 700px;
    &__picbox {
        width: 575px;
        height: 575px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        a {
            width: 100%;
            height: 95%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                max-width: 100%;
                max-height: 100%;
                display: inline-block;
            }
        }
    }
    &__showText {
        display: inline-block;
        max-width: 100%;
        max-height: 5%;
        margin: 0;
        color: gray;
    }
    &__ui {
        display: flex;
        flex-direction: column;
        &.showed {
            justify-content: start;
            .prev-slide, .next-slide {
                display: block;
            }
        }
        .prev-slide, .next-slide {
            display: none;
            width: 46px;
            height: 24px;
            margin: 0 auto;
            cursor: pointer;
        }
        .prev-slide {
            background: url('../img/sprite.svg') 0 -1045px;
            margin-bottom: 25px;
        }
        .next-slide {
            background: url('../img/sprite.svg') 0 -852px;
            margin-top: 25px;
        }
    }
    &__list {
        height: 9999px;
        transition: margin-top 250ms;
    }
    &__item {
        display: block;
        width: 95px;
        height: 95px;
        max-width: 95px;
        max-height: 95px;
        text-align: center;
        padding: 3px;
        border: 1px solid #C6C6C6;
        border-radius: 4px;
        cursor: pointer;
        &.active {
            border-width: 3px;
            border-color: $blue;
        }
        & + div, & + a {
            margin-top: 15px;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__wrap {
        max-height: 330px;
        overflow: hidden;
    }
    &-mobile {
        display: none;
    }
}

.zoomwindow {
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
}

.magnifier {
    background-color: $white;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
}
// /slider

// shortInfo
.shortInfo {
    width: 350px;
    padding: 20px;
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    &Price {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &__value {
            color: $nightrider;
            @extend %roboto-bold;
            font-size: 26px;
            line-height: 32px;
            margin-right: 10px;
            white-space: nowrap;
            &.rouble:after {
                @extend %roboto-medium;
                font-size: 22px;
            }
        }
        &__wrap {
            display: flex;
            align-items: center;
            &.hide {
                display: none;
            }
        }
        &__oldValue {
            color: $dimgray;
            @extend %roboto;
            font-size: 18px;
            padding: 0 10px;
            margin-right: 10px;
            position: relative;
            white-space: nowrap;
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: #c2c2c2;
                position: absolute;
                margin-left: -50%;
                top: 50%;
                left: 50%;
            }
            &.rouble:after {
                @extend %roboto-medium;
                font-size: 14px;
            }
        }
        &__discount {
            color: #FF6A16;
            @extend %roboto;
            font-size: 14px;
        }
        &__pricePerUnit {
            color: #FF6A16;
            @extend %roboto;
            font-size: 14px;
            margin-left: 10px;
        }
        div + &__value {
            margin-left: 12px;
        }
    }
    &__price {
        padding-bottom: 10px;
    }
    &__notavailable {
        text-align: center;
        @extend %roboto-medium;
        &Comment {
            @extend %roboto;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            margin-top: 11px;
            margin-bottom: 10px;
        }
    }
    &__delivery {
        padding: 10px 10px 10px 0;
        & + & {
            padding-top: 0;
        }
    }
    &__deliveryTo {
        padding: 10px 0;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        &:after {
            content: "";
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 40%;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), white 100%);
            pointer-events: none;
        }
        &--withoutIcon {
            margin-left: 25px;
        }
        & + & {
            padding-top: 0;
        }
    }
    &__btnWrap {
        padding: 10px 0 15px;
    }
    &__amount {
        display: flex;
        align-items: center;
        padding: 10px 0;
        &Select {
            width: 140px;
            height: 32px;
        }
        &Value {
            color: $nightrider;
            @extend %roboto;
            font-size: 13px;
            line-height: 15px;
            margin-left: 10px;
        }
    }
    &__store {
        padding: 10px 0 5px;
        &Links {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 15px;
            & > a {
                margin-right: 8px;
            }
        }
        .rating {
            margin-right: 8px;
        }
    }
    &__reviews {
        white-space: nowrap;
        line-height: 14px;
    }
    &__storeSelect {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 5px 0 20px;
        .selectWindow {
            left: -510px;
            top: -200px;
        }
        .selectPopup {
            padding: 5px 0;
        }
        .storeMarker {
            margin: 3px 0;
        }
    }
    &__anotherSellers {
        border-top: 1px solid #d8d8d8;
        padding: 20px 0 5px;
    }
    &__warning {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        &Icon {
            transform: scale(0.5);
            width: 20px;
            height: 20px;
            margin-left: -5px;
        }
        &Text {
            font-size: 14px;
            margin-left: 5px;
        }
    }
    &__popup{
        position: relative;
        background: #FFF;
        padding: 20px;
        width:auto;
        max-width: 500px;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
    }
}

.deliveryTo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 10px;
    &__name {
        display:flex;
        white-space: nowrap;
        overflow: hidden;
    }
    &__text {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        display:flex;
        margin-left: 20px;
    }
    &__value {
        width: 100%;
        color: $blue;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 18px;
        margin: 2px 0 0 20px;
    }
    .link {
        white-space: normal;
    }
    i + div {
        margin-left: 6px;
    }
}

.shipAhead {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    margin-top: 10px;
    &__text {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 20px;
    }
    &__value {
        width: 100%;
        color: $blue;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 18px;
        margin: 2px 0 0 20px;
    }
    .link {
        white-space: normal;
    }
    i + div {
        margin-left: 6px;
        padding-left: 0;
    }
}

.deliveryTo + .shipAhead {
    margin-top: 8px;
}

.infoDelivery {
    &Price {
        line-height: 16px;
        &Value {
            display: inline;
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
            line-height: 16px;
        }
        &Text {
            display: inline;
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            line-height: 16px;
            margin-right: 4px;
        }
        & + & {
            margin-top: 5px;
        }
    }
}

.infoCapabilitiesPopup{
    display: none;
    position: relative;
    @extend %roboto;
    margin-top: 25px;
    padding: 20px 40px 20px 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
    font-size: 13px;
    border-radius: 4px;
    &.open {
        display: block;
    }
    &__close {
        position: absolute;
        right: 11px;
        top: 11px;
        width: 11px;
        height: 11px;
        background: url('../img/cross.svg');
        cursor: pointer;
    }
}

.anotherSellers {
    &Headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
        }
    }
    &Content {
        margin-top: 15px;
    }
    .anotherSeller {
        display: flex;
        justify-content: space-between;
        &__content {
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
        }
        &__name {
            display: flex;
            @extend %roboto-bold;
            line-height: 20px;
            max-width: 160px;
            overflow: hidden;
        }
        &__btn {
            max-width: 95px;
            margin-top: 5px;
            .btn-theme {
                padding-top: 5px;
                padding-bottom: 5px;
                font-size: 13px;
            }
        }
        &:not(:first-child) {
            margin-top: 15px;
        }
    }
}

.storeMarker {
    display: inline-block;
    padding: 2px 5px;
    background-color: $white;
    border: 1px solid $nobel;
    border-radius: 4px;
    color: $nightrider;
    @extend %roboto;
    font-size: 13px;
}

.productAvailability {
    display: flex;
    &__status {
        color: #219653;
        @extend %roboto-medium;
        font-size: 14px;
        white-space: nowrap;
        margin-right: 4px;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
    }
    &.not-available &__status {
        color: #FF6A16;
    }
    &.not-available &__text {
        display: none;
    }
}
// /shortInfo

// productInfo
.productInfo {
    width: 100%;
    max-width: 100%;
    padding-bottom: 20px;
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 24px;
        line-height: 30px;
    }
    &__from {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        margin-top: 2px;
    }
    &__links {
        display: flex;
        align-items: center;
        margin-top: 8px;
    }
    &__contract {
        display: inline-block;
        margin-top: 10px;
    }
    &__reviews {
        display: flex;
        align-items: center;
        margin: 0 25px 0 15px;
        .link {
            font-size: 16px;
        }
    }
    &__favourites {
        display: flex;
        align-items: center;
        // &:hover {
        //     i { background-position: 24px -680px; }
        // }
        i {
            margin-right: 8px;
            display: inline-block;
            vertical-align: bottom;
        }
        .link {
            font-size: 16px;
        }
    }
    &__parameter {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        line-height: 18px;
        padding: 6px 0;
    }
    &__size {
        &Title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 16px;
            line-height: 18px;
        }
        &Select {
            display: flex;
            align-items: center;
            padding: 15px 0;
        }
        .b-dropdown {
            width: 100px;
            margin-right: 20px;
        }
    }
    &__groupParams {
        display: flex;
        align-items: center;
        padding: 6px 0;
        &--decorate {
            background-color: #f8f8f8;
            padding: 6px 8px;
            margin: 12px 0 8px;
        }
    }
    &__palette {
        display: flex;
        justify-content: space-between;
        padding: 6px 0;
        &Title {
            margin-bottom: 8px;
            padding-right: 20px;
        }
        &Toggle {
            height: 60px;
            margin-top: 37px;
            cursor: pointer;
            transition: transform .5s ease-in;
            transform: rotate(180deg);
            &.active {
                transform: rotate(360deg);
                transition: transform .5s ease-out;
            }
        }
    }
    &__packed {
        padding: 6px 0 15px;
        &Title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 16px;
            line-height: 18px;
        }
        &Items {
            display: flex;
            margin: 12px 0 0;
            padding: 0;
            list-style-type: none;
        }
        &Item {
            min-width: 120px;
            width: 120px;
            height: 40px;
            text-align: center;
            margin-right: 15px;
            &Link {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border: 1px solid #a6a6a6;
                border-radius: 2px;
                color: $nightrider;
                @extend %roboto-medium;
                font-size: 14px;
                line-height: 1.3;
                &:hover {
                    color: $nightrider;
                    text-decoration: none;
                }
                &__description {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 6px;
                }
            }
            &Info {
                color: $nightrider;
                @extend %roboto-medium;
                font-size: 13px;
                white-space: nowrap;
                margin-top: 3px;
            }
        }
        &Item.active &ItemLink {
            border: 2px solid #4F89C5 !important;
        }
        &Item.available &ItemLink {
            border: 1px solid #4F89C5;
        }
    }
    &__types {
        padding: 6px 0 20px;
        &Items {
            display: flex;
            margin: 0;
            padding: 0;
            list-style-type: none;
        }
        &Item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 110px;
            min-width: 110px;
            height: 50px;
            text-align: center;
            margin-right: 15px;
            border: 1px solid #a6a6a6;
        }
        &Item.active {
            border: 2px solid #4F89C5;
        }
    }
    & + & {
        border-top: 1px solid #e1e1e1;
        padding-top: 20px;
    }
    .infoDelivery {
        &Price {
            max-width: 100%;
            line-height: 22px;
        }
    }
}

.mobileOnly {
    display: none;
}

.btnDetail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 60px 18px 18px;
    border: 2px solid #B0B0B0;
    position: relative;
    border-radius: 4px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F4F4F4;
    cursor: pointer;
    &__title, &__value {
        color: $nightrider;
        font-size: 20px;
    }
    &__title { @extend %roboto-medium; }
    &__value { @extend %roboto-bold; }
    &__imgWrap {
        width: 55px;
        height: 55px;
        max-width: 55px;
        max-height: 55px;
        border-radius: 4px;
        text-align: center;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
    }
    i {
        position: absolute;
        right: 25px;
        transform: rotate(-90deg);
    }
    & + & {
        margin-top: 12px;
    }
}

.popupDetail {
    width: 100%;
    padding: 15px 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $white;
    button {
        width: 100px;
        position: absolute;
        top: -50px;
        right: 10px;
        opacity: 1;
        &:before {
            content: 'Закрыть';
            color: $white;
            @extend %roboto-medium;
            font-size: 22px;
        }
    }
    .palette {
        flex-wrap: nowrap;
        overflow: auto;
    }
}

.titleWithLine {
    color: $nightrider;
    @extend %roboto-medium;
    font-size: 14px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: 22px;
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #e1e1e1;
        margin-left: 15px;
    }
}

.discountLabel {
    display: inline-block;
    color: #FF6A16;
    @extend %roboto;
    font-size: 16px;
    line-height: 18px;
    &:after {
        content: 'Акция';
    }
}

.selectPopup {
    cursor: pointer;
    position: relative;
    margin-right: 12px;
    &__item {
        display: flex;
        align-items: center;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 18px;
        margin-right: 5px;
    }
}

.selectWindow {
    display: none;
    overflow: hidden;
    position: absolute;
    top: 150%;
    //top: 0;
    left: 0;
    background-color: $white;
    padding: 18px 15px;
    border-radius: 2px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    z-index: 1026;
    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0 10px 0;
        height: 230px;
        max-height: 230px;
        border-right: 1px solid #dfdfdf;
        &--col {
            width: 130px;
            min-width: 130px;
        }
        &--col1 {
            width: 140px;
            min-width: 140px;
        }
        &--col2 {
            width: 260px;
            min-width: 260px;
        }
        & + & {
            margin-left: 15px;
        }
    }
    &__item {
        display: flex;
        justify-content: space-between;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        padding: 2px 8px;
        margin-right: 30px;
        border-radius: 2px;
        overflow: hidden;
        cursor: pointer;
        user-select: none;
        &.disabled {
            color: $darkgray;
            &:hover {
                background-color: $white;
            }
        }
        &:hover {
            background-color: #EDF6FF;
        }
        &.active {
            background-color: #CCE5FF;
        }
        & + & {
            margin-top: 5px;
        }
        span {
            min-width: 120px;
            max-width: 120px;
            text-align: left;
            line-height: 18px;
            margin-right: 10px;
            word-break: break-word;
        }
        &.js-select-warehouse {
            &.disabled {
                display: none;
            }
        }
    }
    &.open {
        display: flex;
    }
    &__close {
        position: absolute;
        top: 5px;
        right: 5px;
    }
    &--group {
        width: 620px;
        //width: 480px;
        height: 270px;
        max-width: 620px;
        //max-width: 480px;
        max-height: 270px;
    }
    &--single {
        width: 470px;
        height: 105px;
        max-width: 470px;
        max-height: 105px;
        padding: 12px 15px;
    }
    &--single &__list {
        border: 0;
        padding: 0;
        width: 100%;
    }
    &--single &__item {
        padding: 3px 5px;
        margin: 0;
        span {
            min-width: auto;
            margin: 0;
            &:first-child {
                max-width: 350px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    &--single &__item + &__item {
        margin-top: 6px;
    }
}

.paletteWrap {
    max-height: 320px;
}
.palette {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    &Item {
        width: 80px;
        max-width: 80px;
        text-align: center;
        margin-right: 15px;
        padding-bottom: 15px;
        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
            border-radius: 2px;
            border: 1px solid #e1e1e1;
            position: relative;
        }
        &__description {
            color: $white;
            @extend %roboto-medium;
            font-size: 14px;
            position: absolute;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 45px;
            text-shadow:
                1px 0 1px $nightrider,
                0 1px 1px $nightrider,
                -1px 0 1px $nightrider,
                0 -1px 1px $nightrider;
        }
        &__image {
            max-width: 60px;
            max-height: 60px;
        }
        &__info {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 13px;
            margin-top: 5px;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    &Item.available &Item__link {
        border: 1px solid #437FBD;
    }
    &Item.active &Item__link {
        border: 3px solid $blue !important;
        box-shadow: 0 0 4px $blue !important;
    }

}
// /productInfo

.productProperty {
    display: flex;
    align-items: flex-start;
    &__title, &__value {
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        line-height: 20px;
        padding: 3px 0;
    }
    &__title {
        min-width: 350px;
        max-width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 30px;
        &:after {
            content: '';
            width: 100%;
            display: block;
            border: 1px dotted #e1e1e1;
            margin-left: 5px;
        }
        span {
            background-color: #fff;
            padding: 2px 8px 4px 0;
        }
    }
    &__value {
        span {
            display: block;
        }
        span + span {
            margin-top: 5px;
        }
    }
}

.productComment {
    display: flex;
    align-items: flex-start;
    position: relative;
    &__content {
        margin-left: 150px;
    }
    &__date {
        color: $nobel;
        @extend %roboto;
        font-size: 14px;
        line-height: 16px;
        margin-top: 12px;
    }
    &__rating {
        margin-right: 30px;
    }
    &__author {
        display: flex;
        align-items: center;
        &Name {
            color: $nightrider;
            @extend %roboto;
            font-size: 24px;
            line-height: 24px;
            padding: 5px 0;
            margin-right: 30px;
        }
        &Rating {
            padding: 5px 0;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__aboutPurchase {
        color: $nobel;
        @extend %roboto;
        font-size: 14px;
        margin-top: 8px;
    }
    &__params {
        color: $nightrider;
    }
    &__review {
        margin-top: 25px;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 20px;
        margin-top: 5px;
    }
    & + & {
        margin-top: 60px;
    }
}

.productImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 380px;
    height: 380px;
    max-width: 380px;
    max-height: 380px;
    margin-right: 20px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

// scrollbar
.scroll-element.scroll-y.scroll-scrolly_visible {
    display: block;
    width: 8px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: 0 0;
    border: none;
    margin: 0;
    padding: 0;
    z-index: 1;
}
.scroll-element div {
    background: 0 0;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
}
.scroll-element_size {
    background-color: #f0f0f0 !important;
    opacity: 1;
    -webkit-transition: all .2s ease 0s;
    -o-transition: all .2s ease 0s;
    transition: all .2s ease 0s;
    border-radius: 12px;
}
.scroll-element_track {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}
.scroll-bar {
    width: 8px !important;
    height: 70px !important;
    border-radius: 4px !important;
    pointer-events: all !important;
    position: relative !important;
    z-index: 100 !important;
    cursor: pointer !important;
    background-color: #b7b7b7 !important;
}

// custom scrollbar
.paletteWrap.scroll-wrapper>.scroll-content {
    overflow: hidden !important;
}
.paletteWrap.open.scroll-wrapper>.scroll-content {
    overflow: scroll !important;
}
.paletteWrap .scroll-element.scroll-y.scroll-scrolly_visible {
    display: none;
}
.paletteWrap.open .scroll-element.scroll-y.scroll-scrolly_visible {
    display: block;
    height: 316px;
    right: 20px;
}
.selectWindow .scroll-element.scroll-y.scroll-scrolly_visible {
    right: 10px;
}

.recommendedProduct-container {
    display: flex;
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #dcdcdc;
}

.recommendedProduct {
    padding-top: 20px;
}

.recommendedProduct__items {
    display: flex;
    padding-bottom: 20px;
}

.currentProduct {
    border-right: 1px solid #dcdcdc;
}

.recommendedProductItem {
    width: 200px;
    max-width: 200px;
    height: 200px;
    max-height: 200px;
    margin-bottom: 40px;
    position: relative;
    padding: 0px 40px 60px;

    &__imgWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;

        &.unchecked {
            opacity: 0.6;
        }
    }

    &__info {
        margin-top: 15px;
    }

    &__title {
        display: flex;
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        line-height: 22px;
        min-height: 44px;
        max-height: 44px;
        overflow: hidden;
        text-align: center;

        &:hover {
            color: $nobel;
            text-decoration: none;
        }
    }

    &__price {
        align-items: center;
        text-align: center;
        font-weight: bold;
    }
}

.plus {
    font-size: 50px;
    padding-top: 25px;
}

.recommendedProductCheckbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    cursor: pointer;
    height: 20px;
    &__input {
        width: 20px;
        height: 20px;
    }
}

.totalWrap {
    margin: auto;
}

.totalCount {
    display: flex;
    border-bottom: 1px solid #dcdcdc;
    font-size: 18px;

    &__name {
        margin-right: 10px;
    }

    &__priceText {
        font-weight: bold;
    }
}

.productCharacteristics {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
