.wrapper {
    overflow: hidden;
}

a {
    color: $blue;
    &:hover {
        color: $blue;
        text-decoration: none;
    }
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

.pageContent {
    min-height: 500px;
    padding-bottom: 40px;
    &__inner {
        padding: 40px 0;
    }
    &__item {
        @extend %roboto;
        & + & {
            margin-top: 5px;
        }
    }
}
.pageHeadline {
    position: relative;
    padding-bottom: 30px;
}

.overflow-hidden {
    overflow: hidden;
}

.hidden { display: none !important; }

.visible { display: block !important; }

.section {
    &-login {
        padding: 30px 0 100px;
    }
}

.pageError {
    display: flex;
    justify-content: space-between;
    min-height: 500px;
    &Content {
        .btn-theme {
            margin-top: 30px;
        }
    }
    &Icon {
        position: relative;
        i { position: absolute; }
        .unboxed-sm-icon {
            top: 82px;
            right: 382px;
        }
        .unboxed-md-icon {
            top: 210px;
            right: 395px;
        }
        .unboxed-lg-icon {
            top: 10px;
            right: 0;
        }
    }
}

.mfp-close {
    &:focus {
        outline: none;
    }
}

.pr-25 { padding-right: 25px; }
.pl-25 { padding-left: 25px; }

.container-fluid-custom {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto;
}

.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (min-width: 1200px) {
    .container-xl {
        max-width: 1140px;
    }
}

@media screen and (min-width: 1600px) {
    .container-xl {
        max-width: 1440px;
    }
}

@media screen and (max-width: 767px) {
    .pageError {
        &Icon {
            display: none;
        }
    }
}
