.checkbox {
    display: inline-flex;
    margin-bottom: 0;
    input[type=checkbox] + span {
        display: block;
        width: 20px;
        height: 20px;
        border: 2px solid $nobel;
        border-radius: 2px;
        position: relative;
        cursor: pointer;
        background-color: $white;
    }
    input[type=checkbox]:checked + span:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 1px;
        width: 14px;
        height: 11px;
        background: url('../img/sprite.svg') 0 -306px;
    }
    &__text {
        margin-left: 10px;
    }
    &__title {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        user-select: none;
        margin-top: 1px;
    }
    &__subtext {
        color: $dimgray;
        @extend %roboto;
        font-size: 11px;
        margin-top: 3px;
    }
}

.b-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
    &__field {
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid $nightrider;
        border-radius: 2px;
        position: relative;
        background-color: $white;
    }
    input[type=checkbox]:checked + &__field:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 2px;
        width: 10px;
        height: 8px;
        background: url('../img/sprite.svg') 0 -298px;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        line-height: 16px;
        margin-left: 10px;
        user-select: none;
    }
}

.checkboxField {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
    input[type=checkbox] + span {
        display: block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        border: 1px solid $dimgray;
        border-radius: 2px;
        position: relative;
        background-color: $white;
    }
    input[type=checkbox]:checked + span:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 2px;
        width: 14px;
        height: 11px;
        background: url('../img/sprite.svg') 0 -306px;
    }
    &__text {
        margin-left: 10px;
    }
    &__title {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 20px;
        user-select: none;
        &--bold {
            font-family: Roboto-Medium;
        }
    }
    &__subtext {
        color: $dimgray;
        @extend %roboto;
        font-size: 11px;
        margin-top: 3px;
    }
}

.checkView {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
    input[type=checkbox] + span {
        display: block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        border: 1px solid $nobel;
        border-radius: 4px;
        position: relative;
        background-color: $white;
    }
    input[type=checkbox]:checked + span {
        border-color: $dimgray;
    }
    input[type=checkbox]:checked + span:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 2px;
        width: 14px;
        height: 11px;
        background: url('../img/sprite.svg') -20px -300px;
    }
    &:hover {
        input[type=checkbox] + span {
            background-color: #f2f2f2;
        }
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        line-height: 20px;
        margin-left: 15px;
    }
}