.dropdownComponent, .dropdownReason {
    min-width: 80px;
    position: relative;
    border: 1px solid $nobel;
    border-radius: 2px;
    color: $nightrider;
    @extend %roboto-medium;
    font-size: 13px;
    cursor: pointer;
    user-select: none;
    &:after {
        height: 0;
        width: 0;
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #ccc;
        content: '';
        display: block;
        margin: auto 0;
        right: 10px;
        width: 12px;
        height: 7px;
        border: 0;
        background: url('../img/sprite.svg') 0 -1440px;
    }
    &__selected {
        width: 100%;
        padding: 5px 32px 5px 10px;
        position: relative;
        z-index: 1;
        white-space: nowrap;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        &:before {
            content: attr(data-before);
            margin-right: 4px; }
        &:after {
            content: attr(data-after);
            margin-left: 4px; }
    }
    &__items {
        display: none;
        max-height: 300px;
        padding: 5px;
        margin: 5px 0 0;
        list-style-type: none;
        position: absolute;
        top: 26px;
        left: 0;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
        border-radius: 2px;
        background-color: $white;
        overflow: auto;
        z-index: 1;
        &.open {
            display: block;
            z-index: 1000;
        }
    }
    &__item {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 13px;
        padding: 5px 20px;
        &:hover {
            background-color: #edf6ff;
        }
        &.selected {
            background-color: #cce5ff;
            border-radius: 2px;
        }
    }
}

.dropdownComponent {
    &__item {
        text-align: center;
    }
    &--start {
        .dropdownComponent {
            &__item {
                text-align: start;
            }
        }
    }
    & + .link {
        margin-left: 15px;
    }
    & + & {
        margin-left: 15px;
    }
}

.variationDropdownItem {
    opacity: 50%;
    &.available {
        opacity: 100%;
    }
}

.dropdown {
    padding: 0 0 5px 0;
    &Content{
        min-width: 80px;
        position: relative;
        border: 1px solid $nobel;
        border-radius: 2px;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            content: '';
            display: block;
            margin: auto 0;
            right: 10px;
            width: 12px;
            height: 7px;
            border: 0;
            background: url('../img/sprite.svg') 0 -1440px;
        }
        &__selected {
            @extend %roboto-medium;
            width: 100%;
            padding: 5px 32px 5px 10px;
            position: relative;
            z-index: 1;
            white-space: nowrap;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            &:before {
                content: attr(data-before);
                margin-right: 4px; }
            &:after {
                content: attr(data-after);
                margin-left: 4px; }
        }
        &__items {
            display: none;
            max-height: 300px;
            margin: 5px 0 0;
            list-style-type: none;
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
            border-radius: 2px;
            background-color: $white;
            overflow: auto;
            z-index: 1;
            &.open {
                display: block;
            }
        }
        &__item {
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            padding: 5px 20px;
            text-align: center;
            &:hover {
                background-color: $whitesmoke;
            }
            &.selected {
                background-color: #cce5ff;
                border-radius: 2px;
            }
        }
        &--start {
            .dropdownContent {
                &__item {
                    text-align: start;
                }
            }
        }
    }
}
