$pagination-border: #d0d0d0;
$pagination-hover: #f2f2f2;
$pagination-gap: #d0d0d0;

.b-pagination {
    display: flex;
    background-color: $white;
    overflow: hidden;
    &__page {
        display: flex;
        min-width: 46px;
        min-height: 46px;
        align-items: center;
        justify-content: center;
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 16px;
        background-color: $white;
        border-top: 1px solid $pagination-border;
        border-bottom: 1px solid $pagination-border;
        &:first-child {
            border-left: 1px solid $pagination-border;
            border-radius: 4px 0 0 4px;
        }
        &:last-child {
            border-right: 1px solid $pagination-border;
            border-radius: 0 4px 4px 0;
        }
        &:not(.b-pagination__gap):hover {
            color: $nightrider;
            background-color: $pagination-hover;
            text-decoration: none;
        }
        &.disabled, &.disabled span {
            color: $pagination-gap;
            pointer-events: none;
            text-decoration: none;
            &:hover {
                color: $pagination-gap;
            }
        }
        &.active {
            color: $nightrider;
            background-color: $white;
            position: relative;
            pointer-events: none;
            text-decoration: none;
            border: 1px solid $nightrider;
            z-index: 1;
        }
    }
    &__gap {
        &:before {
            content: '...';
            color: $pagination-gap;
            padding-bottom: 10px;
        }
    }
    &__back, &__next {
        padding: 0 15px;
        position: relative;
        &:after {
            content: '';
            width: 1px;
            height: 32px;
            background-color: $pagination-gap;
            position: absolute;
            top: 5px;
        }
    }
    &__back {
        &:after {
            right: -2px;
        }
        span {
            padding-left: 20px;
        }
    }
    &__next {
        &:after {
            left: -2px;
        }
        span {
            padding-right: 20px;
        }
    }
}

@media screen and (max-width: 768px) {
    .b-pagination {
        &__page {
            display: none;
        }
        &__back, &__next {
            display: flex;
            span {
                font-size: 12px;
            }
        }
        &__back {
            &:after {
                content: none;
            }
        }
        &__next {
            &:after {
                left: 0;
            }
        }
    }
}
