.placemarketChoice {
    &__main {
        display: flex;
        align-items: flex-start;
        padding-bottom: 40px;
        position: relative;
    }
    &__preview {
        width: 100%;
        max-width: 965px;
        padding-right: 175px;
        padding-bottom: 40px;
        &Headline {
            display: flex;
            align-items: center;
        }
        &ImgWrap {
            min-width: 160px;
            min-height: 60px;
            max-width: 160px;
            max-height: 60px;
            margin-right: 30px;
            background-color: $blue;
        }
        &Img {
            max-width: 100%;
            max-height: 100%;
        }
        &Title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            span {
                margin-bottom: 6px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 540px;
            }
        }
        &Title, &Title > span {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 32px;
            line-height: 34px;
        }
        &Rating {
            display: flex;
            align-items: center;
            margin-top: 30px;
            &Click {
                display: flex;
                align-items: center;
                &:before {
                    content: '';
                    display: block;
                    width: 2px;
                    height: 22px;
                    min-width: 2px;
                    min-height: 22px;
                    margin: 0 8px;
                    background-color: #c4c4c4;
                }
                .link {
                    line-height: 18px;
                }
            }
        }
        &Text {
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            line-height: 18px;
            max-height: 180px;
            overflow: hidden;
            margin: 15px 0 5px;
            &.show {
                max-height: 100%;
            }
        }
    }
    &__support {
        &Block {
            width: 100%;
            min-width: 380px;
            max-width: 380px;
            padding: 20px 30px;
            border: 1px solid #ccc;
            border-radius: 6px;
            background-color: #FAFAFA;
        }
        &Title {
            color: $nightrider;
            @extend %roboto-bold;
            font-size: 16px;
            line-height: 18px;
        }
        &Click {
            margin-top: 18px;
        }
        &Phone {
            margin-top: 25px;
            color: $nightrider;
            font-size: 16px;
            &Title {
                @extend %roboto;
            }
            &Value {
                @extend %roboto-bold;
            }
        }
    }
    &__tabs {
    }
    &__feedback {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        .link + .link {
            display: flex;
            align-items: center;
            &:before {
                content: '';
                display: block;
                width: 2px;
                height: 22px;
                min-width: 2px;
                min-height: 22px;
                margin: 0 8px;
                background-color: #c4c4c4;
            }
        }
    }
}

.tabsComponent {
    display: flex;
    flex-direction: column;
    border: 1px solid #d3d3d3;
    border-radius: 1px;
    background-color: $white;
    &__titles {
        display: flex;
        align-items: center;
        background-color: #fafafa;
        border-bottom: 1px solid #D3D3D3;
    }
    &__title {
        min-width: 160px;
        min-height: 60px;
        max-height: 60px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        background-color: #fafafa;
        text-align: center;
        &:hover {
            cursor: pointer;
        }
        &:first-child {
            &.active {
                border-left: none;
            }
        }
        &.active {
            color: $blue;
            @extend %roboto-bold;
            font-size: 14px;
            border-top: 4px solid $blue;
            border-right: 1px solid #d3d3d3;
            border-bottom-color: transparent;
            border-left: 1px solid #d3d3d3;
            background-color: $white;
            position: relative;
            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -2px;
                width: 100%;
                height: 5px;
                background-color: $white;
            }
        }
    }
    &__content {
        width: 100%;
        max-width: 100%;
        min-height: 110px;
    }
    &__tabContent {
        display: none;
        &.active {
            display: block;
        }
    }
}

.tabReview {
    padding: 30px 35px 30px 40px;
    &__reviews {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__list {
        width: 100%;
        max-width: 1090px;
        margin-right: 40px;
        margin-bottom: 80px;
    }
    &Item {
        display: flex;
        align-items: flex-start;
        &__rating {
            margin-right: 20px;
        }
        &__text {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 16px;
            line-height: 20px;
            max-height: 40px;
            overflow: hidden;
        }
        &__author {
            color: #A5A5A5;
            @extend %roboto-medium;
            font-size: 14px;
            line-height: 16px;
        }
        &__text + &__author {
            margin-top: 8px;
        }
        &__more {
            display: flex;
            margin: 6px 0;
        }
        & + & {
            margin-top: 35px;
        }
    }
    &__navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        .link {
            margin: 0 5px;
        }
    }
    &__table {
        max-width: 630px;
        border: 1px solid #CCCCCC;
        margin-top: 60px;
        &Head {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 5px 60px;
            border-bottom: 1px solid #CCCCCC;
            background-color: #F4F4F4;
            &Col {
                color: $nightrider;
                @extend %roboto-bold;
                font-size: 13px;
            }
        }
        &Col {
            min-width: 90px;
            margin-left: 8px;
            text-align: right;
            &:last-child {
                min-width: 150px;
            }
        }
        &Line {
            display: flex;
            align-items: center;
            padding: 5px 60px 5px 15px;
            border-bottom: 1px solid #CCCCCC;
            &:nth-child(odd) {
                background-color: #fafafa;
            }
            &:nth-child(even) {
                background-color: $white;
            }
            &:last-child {
                border-bottom: none;
            }
            &Name {
                color: $nightrider;
                @extend %roboto-bold;
                font-size: 13px;
                min-width: 100px;
                max-width: 100px;
            }
            &Col {
                color: #959595;
                @extend %roboto-medium;
                font-size: 14px;
                margin-left: auto;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .placemarketChoice {
        &__main {
            justify-content: space-between;
        }
        &__preview {
            max-width: 800px;
            padding-right: 40px;
        }
    }

    .tabsComponent {
        &__title {
            min-width: 140px;
            font-size: 12px;
            &.active {
                font-size: 12px;
            }
        }
    }

    .tabReview {
        &__table {
            display: none;
        }
        &__list {
            margin-right: 0;
        }
    }
}

@media screen and (max-width: 1199px) {
    .placemarketChoice {
        &__main {
            flex-direction: column;
        }
    }
    .tabsComponent {
        &__titles {
            flex-wrap: wrap;
        }
        &__title {
            padding: 0 15px;
            &.active {
                &:after { content: none; }
                border-bottom: 1px solid #d3d3d3;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .placemarketChoice {
        &__preview {
            padding-right: 0;
            &Headline {
                flex-direction: column;
            }
            &ImgWrap {
                margin-right: 0;
            }
            &Title {
                margin-top: 10px;
                text-align: center;
                span {
                    font-size: 28px;
                }
                .link {
                    margin: 0 auto;
                }
            }
            &Rating {
                flex-direction: column;
                align-items: flex-start;
                &Click {
                    margin-top: 8px;
                    &:before {
                        content: none;
                    }
                }
            }
        }
    }
    .tabsComponent {
        &__title {
            min-width: 100px;
        }
    }
}

@media screen and (max-width: 575px) {
    .tabReview {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media screen and (max-width: 440px) {
    .placemarketChoice {
        &__preview {
            &Title {
                span {
                    font-size: 24px;
                }
            }
        }
        &__support {
            width: 100%;
            &Block {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }

    .tabReview {
        &Item {
            flex-direction: column;
            &__info {
                margin-top: 6px;
            }
        }
    }
}
