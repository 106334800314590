.goBackToPage {
    display: inline-block;
    &__link {
        display: flex;
        align-items: center;
        span {
            color: $dimgray;
            @extend %roboto-medium;
            font-size: 22px;
            text-transform: uppercase;
            padding-left: 15px;
        }
        &:hover {
            color: $dimgray;
            text-decoration: none;
        }
    }
}

.navigate-back {
    background-color: $white;
    border-bottom: 1px solid #DEDEDE;
    padding: 12px 15px;
    i {
        display: inline-block;
        margin-right: 8px;
    }
}