.tab-container {
    .tab-content {
        display: none;
        position: relative;
        &.active {
            display: block;
        }
    }
}

.tab-inputs {
    display: inline-flex;
    .tab-input {
        padding: 7px;
        border-top: 1px solid $nightrider;
        border-bottom: 1px solid $nightrider;
        min-width: 130px;
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        background-color: $white;
        text-align: center;
        cursor: pointer;
        &:first-child {
            border-left: 1px solid $nightrider;
            border-right: 0.5px solid $nightrider;
            border-radius: 4px 0 0 4px;
        }
        &:last-child {
            border-left: 0.5px solid $nightrider;
            border-right: 1px solid $nightrider;
            border-radius: 0 4px 4px 0;
        }
        &.active {
            background-color: #f9f9f9;
        }
    }
}

.checkoutOptions {
    position: relative;
    margin-top: 20px;
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 22px;
        line-height: 24px;
    }
    &__delivery {
        &Title {
            color: $nightrider;
            @extend %roboto;
            font-size: 18px;
        }
        .radiobutton__description {
            align-items: flex-start;
        }
        .btn-theme + .btn-theme {
            margin-top: 10px;
            margin-left: 10px;
        }
    }
    &__payment {
        margin-top: 35px;
        &.only-b2b {
            display: none;
        }
    }
    .tab-inputs {
        margin-top: 8px;
    }
    .tab-container {
        margin-top: 15px;
    }
}

.checkout{
    &Warnings {
        flex-grow: 1;
        margin-right: 25px;
    }
    &Error {
        margin-top: 0;
    }
}

.checkoutInfo {
    padding: 15px 10px;
    border: 1px solid #DCDCDC;
    border-radius: 6px;
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    &__headline {
        display: flex;
        align-items: center;
        background-color: #f9f9f9;
        border-radius: 4px;
        padding: 5px;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 13px;
        width: 120px;
        min-width: 120px;
        margin-right: 10px;
    }
    &__content {
        padding: 0 5px;
        margin-top: 15px;
    }
    &__total {
        padding: 0 5px;
        margin-top: 10px;
        &:before {
            content: '';
            width: 100%;
            height: 2px;
            background-color: #d9d9d9;
            display: block;
            margin-bottom: 8px;
        }
    }
    &__line {
        display: flex;
        align-items: center;
        & + & {
            margin-top: 2px;
        }
    }
    &__param {
        color: $nightrider;
        @extend %roboto;
        font-size: 12px;
        width: 120px;
        min-width: 120px;
        max-width: 120px;
        margin-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &__value {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        &.rouble {
            &:after {
                color: $nobel;
                font-size: 14px;
            }
        }
    }
    .b2b {
        margin-left: 10px;
    }
    .btn-theme {
        width: 100%;
        margin-top: 30px;
    }
}

.packedPackages {
    margin-top: 70px;
}

// new checkout page
.checkoutInner {
    min-height: 225px;
    position: relative;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    .checkoutTable {
        position: absolute;
        top: 44px;
        right: 0;
    }
    .b2b {
        &:after {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.checkoutTable {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 265px;
    max-width: 300px;
    padding: 30px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    background-color: $white;
    &__params {
        & + div {
            margin-top: 18px;
        }
    }
    &__param {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        overflow: hidden;
        padding: 10px 0;
        & + & {
            border-top: 1px solid #F4F4F4;
        }
        &:last-child {
            border-top: 2px solid #D7D7D7;
        }
    }
    &__name {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 16px;
        width: 130px;
        min-width: 130px;
        white-space: nowrap;
    }
    &__value {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
        line-height: 16px;
        &.rouble:after {
            color: $nobel;
            font-size: 14px;
        }
    }
    &__boldvalue {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 16px;
        line-height: 16px;
    }
}

.checkoutBlock {
    margin-top: 50px;
    .b-title {
        font-size: 20px;
        margin-bottom: 20px;
    }
}

.goodsPackage {
    width: 100%;
    max-width: 760px;
    border-top: 1px solid #CDCDCD;
    background-color: $white;
    &__inner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding: 25px 0 30px;
    }
    &__headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    &__nested {
        display: flex;
        align-items: center;
    }
    &__orderInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 35px;
        width: 320px;
        max-width: 320px;
    }
    &__packageInfo {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-top: 14px;
    }
    &__companyInfo {
        width: 100%;
        padding-right: 20px;
    }
    &__paymentInfo {
        min-width: 320px;
        width: 320px;
        max-width: 320px;
        padding-left: 35px;
        border-left: 1px solid #F3F3F3;
        .goodsPackage {
            &__param {
                justify-content: space-between;
                margin-bottom: 13px;
                &:last-child {
                    margin-bottom: 0;}
            }
        }
    }
    .package-number {
        margin-right: 50px;
    }
    &__paymentType {
        display: flex;
        align-items: center;
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        span {
            max-width: 160px;
            display: block;
            overflow: hidden;
            margin-left: 4px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    &__params {
        & + div {
            margin-top: 18px;
        }
    }
    &__param {
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        & + & {
            margin-top: 8px;
        }
    }
    &__name {
        color: $nobel;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 16px;
        width: 130px;
        min-width: 130px;
        margin-right: 20px;
        white-space: nowrap;
    }
    &__value {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 16px;
    }
    &__thinvalue {
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        line-height: 16px;
        &.rouble:after {
            color: $nobel;
            font-size: 14px;
        }
    }
    &__boldvalue {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 16px;
        line-height: 16px;
    }
    &:last-child {
        border-bottom: 1px solid #CDCDCD;
    }
}

.optionsOfPackage {
    width: 100%;
    max-width: 760px;
    border-top: 1px solid #CDCDCD;
    background-color: $white;
    &__inner {
        padding: 15px 0;
        position: relative;
    }
    &__numbers {
        display: flex;
        flex-wrap: wrap;
        max-width: 600px;
        margin-bottom: 5px;
        .package-number {
            margin-right: 20px;
            margin-bottom: 15px;
        }
    }
    &__param {
        display: flex;
        align-items: flex-start;
        & + & {
            margin-top: 10px;
        }
    }
    &__name {
        color: $nobel;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 18px;
        width: 130px;
        min-width: 130px;
        margin-right: 20px;
        white-space: nowrap;
    }
    &__value, &__shortvalue {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 18px;
        padding-right: 20px;
    }
    &__value {
        min-width: 280px;
        max-width: 280px;
        overflow: hidden;
    }
    &__address, &__paytype, &__deltype {
        display: flex;
        align-items: flex-start;
        position: relative;
    }
    &__radiobuttons {
        margin-top: 10px;
        .optionsOfPackage {
            &__variant {
                padding-left: 6px;
            }
        }
    }
    &__links {
        display: flex;
        align-items: center;
        .link {
            line-height: 18px;
        }
    }
    &__variant {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        line-height: 14px;
        &Value {
            color: #219653;
            @extend %roboto-medium;
            font-size: 14px;
            margin-right: 8px;
        }
        &After {
            color: $nobel;
            @extend %roboto;
            font-size: 14px;
        }
    }
    .link + .link {
        margin-left: 20px;
    }
    &:last-child {
        border-bottom: 1px solid #CDCDCD;
    }
    .b2b {
        position: absolute;
        top: 15px;
        right: 0;
    }
    &Popup {
        display: none;
        width: 540px;
        min-height: 100px;
        max-width: 540px;
        max-height: 260px;
        padding: 25px 10px 25px 20px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $white;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
        border-radius: 2px;
        z-index: 10;
        &__inner {
            width: 100%;
            height: 100%;
            max-height: 210px;
            overflow-y: scroll;
        }
        &__title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 20px;
            line-height: 22px;
        }
        &__list {
            margin-top: 12px;
            list-style: none;
        }
        &__item {
            color: $blue;
            @extend %roboto;
            font-size: 16px;
            line-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            &.active {
                color: $nightrider;
                @extend %roboto-medium;
            }
            & + & {
                margin-top: 8px;
            }
        }
        &__close {
            width: 12px;
            height: 12px;
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;
            &:before, &:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: $nobel;
                position: absolute;
                top: 4px;
            }
            &:before { transform: rotate(-45deg); }
            &:after { transform: rotate(-135deg); }
        }
        &.displayed {
            display: block;
        }
    }
}

.package-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 30px;
    max-width: 100px;
    padding: 0 4px;
    background-color: #F9F9F9;
    border-radius: 4px;
    color: $nightrider;
    @extend %roboto-medium;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
}

.checkout-progress {
    width: 100%;
    max-width: 720px;
    padding: 20px 0;
    position: relative;
    overflow: hidden;
    &__line {
        width: 100%;
        height: 4px;
        margin-bottom: 20px;
        background-color: #D9EEFF;
    }
    &__steps {
        display: flex;
        justify-content: space-between;
    }
    &__step {
        color: $nobel;
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 16px;
        position: relative;
    }
    @for $i from 1 through 4 {
        &.step-#{$i} {
            .checkout-progress__step:nth-child(#{$i}) {
                color: $blue;
                &:after {
                    content: '';
                    min-width: 42px;
                    min-height: 42px;
                    @extend %cart-in-way-icon;
                    position: absolute;
                    top: -21px;
                    left: 50%;
                    margin-right: -50%;
                    transform: translate(-50%, -50%);
                }
            }
            @for $j from 1 through $i {
                $step: $j;
                $prev: $step - 1;
                .checkout-progress__step:nth-child(#{$prev}) {
                    color: $blue;
                }
                .checkout-progress__step:nth-child(#{$step}) {
                    color: $blue;
                    &:before {
                        content: '';
                        width: 720px;
                        height: 4px;
                        max-width: 720px;
                        background-color: $blue;
                        position: absolute;
                        top: -24px;
                        right: 0;
                    }
                }
                .checkout-progress__step:nth-child(#{$prev}) + .checkout-progress__step:last-child:not(:only-child) {
                    &:before {
                        right: 0;
                    }
                    &:after {
                        top: -42px;
                        left: unset;
                        right: 0;
                        margin-right: 0;
                        transform: none;
                    }
                }
            }
        }
    }
    &.step-1 {
        .checkout-progress__step:nth-child(1) {
            &:before {
                right: 24px;
            }
        }
    }
    &.step-2 {
        .checkout-progress__step:nth-child(2) {
            &:before {
                right: 70px;
            }
        }
    }
    &.step-3 {
        .checkout-progress__step:nth-child(3) {
            &:before {
                right: 30px;
            }
        }
    }
}
