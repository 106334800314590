ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

address {
    margin-bottom: 0;
}

.b-brand {
    background: $white;
    padding: 25px 0;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    &__link {
        display: inline-block;
        height: 40px;
        width: 200px;
    }
    &__img {
        width: 100%;
        height: 100%;
    }
}

.b-notification {
    width: 100%;
    max-width: 920px;
    display: inline-flex;
    align-items: center;
    color: $white;
    @extend %roboto-medium;
    font-size: 15px;
    border-radius: 4px;
    &--info {
        background-color: $blue;
        padding: 15px 17px;
    }
    &--success {
        color: $seagreen;
        border: 2px solid $seagreen;
        border-radius: 4px;
        align-items: center;
        padding: 8px 15px;
    }
    &__text {
        margin-left: 15px;
    }
}

.b-error, .b-success {
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 16px 15px 16px 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    &__text {
        margin-left: 15px;
    }
    &__title {
        @extend %roboto-medium;
        font-size: 14px;
        line-height: 14px;
    }
    &__msg {
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        line-height: 18px;
        margin-top: 6px;
    }
}

.b-error {
    border: 1px solid $radicalred;
    box-shadow: 0px 0px 4px $radicalred;
    &__title {
        color: $radicalred;
    }
}

.b-success {
    border: 1px solid $seagreen;
    box-shadow: 0px 0px 4px $seagreen;
    &__title {
        color: $seagreen;
    }
}

.js-radioGroupWrapper {
    overflow: hidden;
    &.open {
        max-height: 9999px !important;
        margin-bottom: 20px;
    }
    &.open + .js-openRadioGroup {
        display: none;
    }
}

.b-select {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
