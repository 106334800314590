.field {
    position: relative;
    &--w80 { width: 80px; }
    &--w150 { width: 150px; }
    &--w160 { width: 160px; }
    &--w190 { width: 190px; }
    &--w200 { width: 200px; }
    &--w240 { width: 240px; }
    &--w280 { width: 280px; }
    &--w330 { width: 330px; }
    &--w350 { width: 350px; }
    &--w380 { width: 380px; }
    &--w410 { width: 410px; }
    &--w540 { width: 540px; }
    &--w630 { width: 630px; }
    &--fluid { width: 100%; }
    &__label {
        color: $dimgray;
        @extend %roboto;
        font-size: 10px;
        text-transform: uppercase;
        margin-bottom: 6px;
    }
    &__input {
        border: 1px solid $darkgray;
        border-radius: 2px;
        color: $nightrider;
        @extend %roboto;
        font-size: 12px;
        height: 30px;
        padding-left: 10px;
        width: 100%;
        &:focus {
            outline: none;
            border-color: $blue;
        }
        &::-webkit-input-placeholder {
            color: $darkgray;
        }
    }
    &__textarea {
        border: 1px solid $darkgray;
        border-radius: 2px;
        resize: none;
        color: $nightrider;
        @extend %roboto;
        font-size: 12px;
        width: 100%;
        min-height: 75px;
        padding: 10px;
        &:focus {
            outline: none;
            border-color: $blue;
        }
        &::-webkit-input-placeholder {
            color: $darkgray;
        }
    }
    &-group {
        &--row {
            display: flex;
        }
        & + & {
            margin-top: 20px;
        }
        .field {
            margin-right: 30px;
        }
    }
}