.bill {
    color: $nightrider;
    width: 740px;

    &__notice {
        font-family: Roboto;
        font-size: 12px;
        line-height: 16px;
        padding-bottom: 15px;
        border-bottom: 2px solid $nightrider;
    }

    &__textTitle {
        margin-bottom: 4px;
    }

    &__text, &__textTitle {
        font-family: Roboto-Medium;
        font-size: 12px;
    }

    &__wrapper {
        margin-bottom: 40px;
        font-size: 0;
    }

    &__info, &__qr {
        display: inline-block;
        vertical-align: text-top;
        width: 49%;
    }

    &__qr {
        text-align: right;
        margin-left: auto;
    }

    &__title {
        display: inline-block;
        font-family: Roboto-Medium;
        font-size: 16px;
        position: relative;
        margin-top: 20px;
        margin-right: 15px;
    }

    &__payed {
        max-width: 240px;
    }

    &__provider {
        margin: 20px 0;
    }

    &__seller {
        margin: 20px 0;
    }

    &__consignee {
        margin-bottom: 20px;
    }

    &__list {
        margin-top: 15px;
        width: 740px;

        &Params {
            padding-bottom: 5px;
            font-size: 0;
        }

        &Param {
            display: inline-block;
            font-family: Roboto;
            font-size: 12px;

            &:nth-child(1) {
                min-width: 20px;
            }

            &:nth-child(2) {
                min-width: 230px;
                margin-left: 28px;
            }

            &:nth-child(3) {
                margin-left: 64px;
            }

            &:nth-child(4) {
                margin-left: 86px;
            }

            &:nth-child(5) {
                margin-left: 52px;
                min-width: 71px;
            }

            &:nth-child(6) {
                margin-left: 48px;
            }
        }
    }

    &ProductItem {
        padding: 5px 0;
        border-top: 1px solid $nightrider;
        font-family: Roboto;
        font-size: 0;

        & > div {
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
        }

        &__index {
            min-width: 20px;
        }

        &__title {
            min-width: 230px;
            max-width: 230px;
            margin-left: 28px;
        }

        &__vendorCode {
            min-width: 100px;
            max-width: 100px;
            margin-left: 40px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
        }

        &__perItem {
            min-width: 90px;
            max-width: 90px;
            margin-left: 0;
            text-align: right;
            white-space: nowrap;
        }

        &__amount {
            min-width: 50px;
            max-width: 50px;
            margin-left: 38px;
            text-align: right;
            white-space: nowrap;
        }

        &__sum {
            min-width: 70px;
            max-width: 70px;
            margin-left: 50px;
            text-align: right;
            white-space: nowrap;
        }

        &:last-child {
            border-bottom: 1px solid $nightrider;
        }
    }

    &__price {
        margin-top: 15px;
        width: 740px;
    }

    &__total {
        &Line {
            width: 100%;
            line-height: 18px;
            text-align: right;

            & + & {
                margin-top: 5px;
            }
        }

        &Text, &Price {
            display: inline-block;
            min-width: 150px;
            font-size: 12px;
            text-align: right;
            line-height: 18px;
        }

        &Text {
            font-family: Roboto;
        }

        &Price {
            font-family: Roboto-Medium;

        }
    }

    &__result {
        margin-top: 20px;
        font-size: 12px;

        &Price {
            font-family: Roboto;

            &Text {
                font-family: Roboto-Bold;
                margin-top: 3px;
            }
        }
    }

    &__signature {
        position: relative;
        margin-top: 50px;
        min-height: 225px;
    }

    &__stamp {
        position: absolute;
        width: 200px;
        height: 200px;
        top: 22px;
        left: 200px;
    }

    &Signature {
        &__title {
            display: inline-block;
            vertical-align: top;
            font-family: Roboto-Medium;
            font-size: 12px;
        }

        &__field {
            display: inline-block;
            vertical-align: bottom;
            position: relative;
            min-width: 170px;
            max-width: 170px;
            border-bottom: 1px solid $nightrider;
            margin: 0 20px 0 20px;
        }

        &__sign {
            position: absolute;
            top: -55px;
            left: 50px;
        }

        &__text {
            display: inline-block;
            vertical-align: top;
            font-family: Roboto;
            font-size: 12px;
        }

        & + & {
            margin-top: 50px;
        }
    }
}

@media print {
    .bill {
        color: $nightrider;
        width: 740px;

        &__notice {
            font-family: Roboto;
            font-size: 12px;
            line-height: 16px;
            padding-bottom: 15px;
            border-bottom: 2px solid $nightrider;
        }

        &__textTitle {
            margin-bottom: 4px;
        }

        &__text, &__textTitle {
            font-family: Roboto-Medium;
            font-size: 12px;
        }

        &__wrapper {
            margin-bottom: 40px;
            font-size: 0;
        }

        &__info, &__qr {
            display: inline-block;
            vertical-align: text-top;
            width: 49%;
        }

        &__qr {
            text-align: right;
            margin-left: auto;
        }

        &__title {
            display: inline-block;
            font-family: Roboto-Medium;
            font-size: 16px;
            position: relative;
            margin-top: 20px;
            margin-right: 15px;
        }

        &__payed {
            max-width: 240px;
        }

        &__provider {
            margin: 20px 0;
        }

        &__seller {
            margin: 20px 0;
        }

        &__consignee {
            margin-bottom: 20px;
        }

        &__list {
            margin-top: 15px;
            width: 740px;

            &Params {
                padding-bottom: 5px;
                font-size: 0;
            }

            &Param {
                display: inline-block;
                font-family: Roboto;
                font-size: 12px;

                &:nth-child(1) {
                    min-width: 20px;
                }

                &:nth-child(2) {
                    min-width: 230px;
                    margin-left: 28px;
                }

                &:nth-child(3) {
                    margin-left: 64px;
                }

                &:nth-child(4) {
                    margin-left: 86px;
                }

                &:nth-child(5) {
                    margin-left: 52px;
                    min-width: 71px;
                }

                &:nth-child(6) {
                    margin-left: 48px;
                }
            }
        }

        &ProductItem {
            padding: 5px 0;
            border-top: 1px solid $nightrider;
            font-family: Roboto;
            font-size: 0;

            & > div {
                display: inline-block;
                vertical-align: middle;
                font-size: 12px;
            }

            &__index {
                min-width: 20px;
            }

            &__title {
                min-width: 230px;
                max-width: 230px;
                margin-left: 28px;
            }

            &__vendorCode {
                min-width: 100px;
                max-width: 100px;
                margin-left: 40px;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
            }

            &__perItem {
                min-width: 90px;
                max-width: 90px;
                margin-left: 0;
                text-align: right;
                white-space: nowrap;
            }

            &__amount {
                min-width: 50px;
                max-width: 50px;
                margin-left: 38px;
                text-align: right;
                white-space: nowrap;
            }

            &__sum {
                min-width: 70px;
                max-width: 70px;
                margin-left: 50px;
                text-align: right;
                white-space: nowrap;
            }

            &:last-child {
                border-bottom: 1px solid $nightrider;
            }
        }

        &__price {
            margin-top: 15px;
            width: 740px;
        }

        &__total {
            &Line {
                width: 100%;
                line-height: 18px;
                text-align: right;

                & + & {
                    margin-top: 5px;
                }
            }

            &Text, &Price {
                display: inline-block;
                min-width: 150px;
                font-size: 12px;
                text-align: right;
                line-height: 18px;
            }

            &Text {
                font-family: Roboto;
            }

            &Price {
                font-family: Roboto-Medium;

            }
        }

        &__result {
            margin-top: 20px;
            font-size: 12px;

            &Price {
                font-family: Roboto;

                &Text {
                    font-family: Roboto-Bold;
                    margin-top: 3px;
                }
            }
        }

        &__signature {
            position: relative;
            margin-top: 50px;
            min-height: 225px;
        }

        &__stamp {
            position: absolute;
            width: 200px;
            height: 200px;
            top: 22px;
            left: 200px;
        }

        &Signature {
            &__title {
                display: inline-block;
                vertical-align: top;
                font-family: Roboto-Medium;
                font-size: 12px;
            }

            &__field {
                display: inline-block;
                vertical-align: bottom;
                position: relative;
                min-width: 170px;
                max-width: 170px;
                border-bottom: 1px solid $nightrider;
                margin: 0 20px 0 20px;
            }

            &__sign {
                position: absolute;
                top: -55px;
                left: 50px;
            }

            &__text {
                display: inline-block;
                vertical-align: top;
                font-family: Roboto;
                font-size: 12px;
            }

            & + & {
                margin-top: 50px;
            }
        }
    }
}
