.popup {
    margin: 0 auto;
    position: relative;
    &-address {
        width: 445px;
        margin: 0 auto;
    }
    &-action {
        width: 410px;
        background-color: $white;
        border-radius: 4px;
    }
    &-title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
    }
    &-header {
        border-bottom: 1px solid $verylightgrey;
        padding: 12px 30px;
    }
    &-body {
        padding: 30px;
        .accountParamPhone {
            margin-top: 20px;
        }
    }
    &-footer {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 25px;
        margin-left: 30px;
        margin-right: 30px;
        border-top: 1px solid $verylightgrey;
        .btn-theme {
            width: 160px;
        }
    }
}

.msg-dev {
    background-color: $white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    width: 480px;
    max-width: 480px;
    height: 340px;
    max-height: 340px;
    margin: 0 auto;
    position: relative;
    padding: 80px 40px;
    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 22px;
        margin-top: 20px;
        line-height: 24px;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        text-align: center;
        margin: 8px 0 25px;
        line-height: 20px;
    }
}

.smallPopup {
    background-color: $white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    width: 480px;
    max-width: 480px;
    height: 340px;
    max-height: 340px;
    margin: 0 auto;
    position: relative;
    padding: 80px 40px;
    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    &__text {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 18px;
        text-align: center;
        margin: 20px 0 35px;
        line-height: 22px;
        max-width: 250px;
    }
    &.mfp-hide {
        display: none;
    }
}

.msgPopup {
    background-color: $white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    width: 420px;
    max-width: 420px;
    margin: 0 auto;
    position: relative;
    padding: 50px 10px;
    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    &__text {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 18px;
        text-align: center;
        margin: 20px 0 35px;
        line-height: 22px;
        max-width: 280px;
    }
}

.invoiceHasBeenSentPopup,
.shareInvoicePopup,
.cancelOrderPopup,
.changePaytypePopup,
.addPackageCommentPopup,
.formPopup,
.smallPopup,
.msgPopup,
.msg-dev {
    .mfp-close {
        font-size: 0;
        opacity: 1;
        cursor: pointer;
        background: 0 0;
        border: 0;
        padding: 0;
        width: 20px;
        height: 20px;
        outline: none;
        top: 10px;
        right: 10px;
        &:before, &:after {
            content: '';
            width: 20px;
            height: 2px;
            background-color: #4d4d4d;
            position: absolute;
            top: 10px;
            left: 0;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(135deg);
        }
    }
}

.cancelOrderPopup {
    width: 440px;
    max-width: 440px;
    min-width: 440px;
    min-height: 290px;
    background-color: $white;
    border-radius: 6px;
    &__headline {
        padding: 15px 20px;
        background-color: #F4F4F4;
        border-radius: 6px 6px 0 0;
    }
    &__content {
        display: flex;
        flex-direction: column;
        padding: 15px 20px 20px;
    }
    &__title, &__subject, &__value {
        color: $nightrider;
        @extend %roboto-bold;
    }
    &__title {
        font-size: 20px;
        line-height: 22px;
    }
    &__subject {
        font-size: 16px;
        line-height: 18px;
    }
    &__value {
        font-size: 22px;
        line-height: 24px;
        margin-top: 8px;
    }
    &__dropdown {
        margin-top: 20px;
        &Text {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
            line-height: 18px;
        }
        &Elem {
            margin-top: 6px;
        }
    }
    &__area {
        display: none;
        margin-top: 20px;
        textarea {
            width: 100%;
            height: 110px;
            resize: none;
            padding: 8px 10px;
            border: 1px solid $nobel;
            color: $nobel;
            @extend %roboto;
            font-size: 14px;
        }
        &.displayed {
            display: block;
        }
    }
    &__btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        .btn-theme:first-child {
            margin-right: 20px;
        }
    }
}

.shareInvoicePopup {
    max-width: 450px;
    min-height: 340px;
    padding: 60px 25px 40px;
    background-color: $white;
    border-radius: 4px;
    &__subject {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 20px;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        margin-top: 12px;
    }
    &__field {
        min-height: 72px;
        margin-top: 24px;
        &-title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
        }
        &-input {
            width: 100%;
            height: 32px;
            max-height: 32px;
            padding: 8px 10px;
            border: 1px solid $nobel;
            background-color: $white;
            border-radius: 2px;
            outline: none;
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            margin-top: 2px;
            &:focus, &:active {
                border-width: 2px;
                border-color: $blue;
            }
            &--notempty {
                background-color: $solitude;
                &:focus, &:active {
                    border: 1px solid $nobel;
                }
            }
        }
        &-error {
            color: #FF494F;
            @extend %roboto;
            font-size: 12px;
            margin-top: 2px;
            display: none;
        }
        &.has-error {
            .shareInvoicePopup__field-error {
                display: block;
            }
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 24px;
        .btn-theme:first-child {
            margin-right: 20px;
        }
    }
    &__submit {
        flex-grow: 1;
    }
}

.invoiceHasBeenSentPopup {
    max-width: 360px;
    min-height: 280px;
    padding: 60px 25px 40px;
    background-color: $white;
    border-radius: 4px;
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__subject {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 20px;
        text-align: center;
        margin-top: 14px;
    }
    &__text {
        max-width: 220px;
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        text-align: center;
        margin: 10px auto 0;
    }
    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
    }
}

.changePaytypePopup {
    max-width: 440px;
    min-height: 430px;
    padding: 60px 20px 25px;
    background-color: $white;
    &__title {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 20px;
        line-height: 26px;
        max-width: 220px;
    }
    &__info {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }
    &__order {
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        margin-right: 5px;
    }
    &__invoice {
        color: $dimgray;
        @extend %roboto;
        font-size: 13px;
        margin-top: 5px;
    }
    &__paytypes {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 35px;
    }
    &__radio {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 0;
        &Input {
            display: none;
        }
        &Area {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            min-width: 22px;
            min-height: 22px;
            border: 1px solid $dimgray;
            margin-right: 10px;
            background-color: $white;
            border-radius: 50%;
            &:before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                border: 1px solid #CBCBCB;
                border-radius: 50%;
            }
        }
        &Input:checked + &Area {
            &:before {
                border-color: $blue;
                background-color: $blue;
            }
        }
        &Text {
            color: $nightrider;
            @extend %roboto;
            font-size: 16px;
        }
        & + & {
            margin-top: 20px;
        }
    }
    &__btns {
        display: flex;
        align-items: center;
        margin-top: 45px;
        .btn-theme {
            &:first-child {
                min-width: 135px;
                margin-right: 25px;
            }
            &:last-child {
                width: 100%;
            }
        }
    }
    &__bottom {
        max-width: 290px;
        margin: 25px auto 0;
        color: $nightrider;
        @extend %roboto;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
    }
}

.addPackageCommentPopup {
    max-width: 480px;
    padding: 45px 40px 25px;
    background-color: $white;
    border-radius: 4px;
    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    &__textarea {
        width: 400px;
        height: 180px;
        padding: 18px;
        border: 1px solid #A2A2A2;
        border-radius: 2px;
    }
    &__btn {
        margin-top: 20px;
    }
}

.formPopup {
    width: 100%;
    max-width: 540px;
    padding: 20px 25px 40px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    background-color: $white;
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 22px;
        margin-bottom: 12px;
    }
    &__field {
        min-height: 72px;
        &-title {
            color: $nightrider;
            @extend %roboto-bold;
            font-size: 14px;
        }
        &-input {
            height: 32px;
            max-height: 32px;
        }
        &-textarea {
            height: 45px;
            max-height: 45px;
            margin-bottom: 10px;
            resize: none;
        }
        &-input, &-textarea {
            width: 100%;
            padding: 8px 10px;
            border: 1px solid $nobel;
            background-color: $white;
            border-radius: 4px;
            outline: none;
            color: $nobel;
            @extend %roboto;
            font-size: 13px;
            line-height: 14px;
            &:focus, &:active {
                border-width: 2px;
                border-color: $blue;
            }
            &--notempty {
                background-color: $solitude;
                &:focus, &:active {
                    border: 1px solid $nobel;
                }
            }
        }
        &-error {
            color: #FF494F;
            @extend %roboto;
            font-size: 12px;
            max-width: 380px;
            display: none;
        }
        &-wrap {
            display: flex;
            align-items: center;
            & + & {
                margin-top: 15px;
            }
        }
        &-additional {
            display: flex;
            align-items: center;
            margin-left: 15px;
            &:before {
                content: 'Доб.';
                color: #444;
                @extend %roboto;
                font-size: 13px;
                margin-right: 4px;
            }
            input {
                min-width: 100px;
                max-width: 100px;
            }
        }
        &--group {
            min-height: 136px;
        }
        & + & {
            margin-top: 2px;
        }
        &.has-error {
            .formPopup__field-error {
                display: block;
            }
        }
    }
    &__submit {
        margin-top: 15px;
    }
}

.locationPopup, .shipAheadPopup {
    display: block;
    width: 340px;
    max-width: 340px;
    margin-right: auto;
    margin-left: auto;
    z-index: 100;
    &__headline {
        padding: 10px 15px;
        border-bottom: 1px solid $nobel;
    }
    &__content {
        padding: 10px 15px 20px;
    }
    &__fluid {
        margin: 16px 0 8px;
        .btn-theme {
            width: 100%;
        }
    }
    &__address {
        width: 100%;
        margin-bottom: 10px;
        padding: 8px 10px;
        border: 1px solid #ACACAC;
        border-radius: 2px;
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        &.active {
            border-color: $darkblue;
        }
    }
    &__seporator {
        position: relative;
        padding-bottom: 30px;
        margin: 0 2px;
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid $nobel;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
        }
    }
    &__index {
        display: flex;
        justify-content: space-between;
        margin: 12px 0 8px;
        button {
            width: 140px;
            padding: 4px 20px;
            margin-left: 20px;
        }
    }
    &__select {
        margin: 10px 0;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 16px;
    }
    &__text {
        color: $dimgray;
        @extend %roboto;
        font-size: 14px;
        position: relative;
        margin: 0 2px;
        &--decorate {
            text-align: center;
            background-color: $white;
            padding: 0 5px;
            &:before, &:after {
                content: '';
                display: block;
                width: 120px;
                height: 1px;
                position: absolute;
                top: 50%;
                border-bottom: 1px solid $nobel;
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        }
    }
    &__btn {
        text-align: right;
        margin-top: 5px;
        button {
            padding: 6px 20px;
            margin-right: 1px;
        }
    }
}

.shipAheadPopup {
    &ShopItem {
        &__content {
            display: none;
        }
        &__title {
            width: 100%;
            color: $nightrider;
            @extend %roboto;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            margin: 4px 2px 0;
            &.active{
                @extend %roboto-medium;
            }
        }
        &__fluid {
            margin: 12px 1px 0;
        }
        &__address {
            width: 100%;
            margin-bottom: 10px;
            padding: 8px 10px;
            border: 1px solid #ACACAC;
            border-radius: 2px;
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 14px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            &.active {
                border-color: $darkblue;
                background-color: $aliceblue;
            }
        }
        &__more {
            display: flex;
            align-items: baseline;
            margin: 13px 1px 0 2px;
        }
        &__divider {
            margin: 0 8px;
            color: $nobel;
            line-height: 10px;
        }
        &__select {
            margin-top: 10px;
            cursor: pointer;
            &.hide {
                display: none;
            }
            &.link {
                margin: 2px 2px 0;
            }
        }
        & + & {
            margin-top: 15px;
        }
    }
    &__subtitle {
        color: $nobel;
        @extend %roboto-medium;
        font-size: 18px;
        margin-top: 10px;
    }
    &__shops {
        padding-top: 20px;
    }
    &__devideTitle {
        border-top: 1px solid $nobel;
        padding-top: 4px;
        color: $dimgray;
        @extend %roboto;
        font-size: 14px;
        margin-top: 10px;
        text-align: center;
    }
    &__favShops {
        margin-top: 16px;
    }
    &__link {
        margin: 20px 2px;
    }
    &__clearButton {
        margin: 0 1px 18px 1px;
        padding: 8px 10px;
        border: 1px solid #ACACAC;
        border-radius: 2px;
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__btn {
        margin-top: 20px;
    }
}

.popup-invite-employee {
    width: 460px;
    max-width: 460px;
    padding: 60px;
    background-color: $white;
    border-radius: 4px;
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 22px;
    }
    &__fieldWrap {
        margin-top: 30px;
    }
    &__radioGroup {
        margin-top: 30px;
    }
    &__btnWrap {
        margin-top: 20px;
    }
}

.delete-confirmation-popup {
    position: relative;
    box-shadow: 0 0 6px rgba(0,0,0,.25);
    border-radius: 4px;
    background: #fff;
    padding: 30px 20px;
    width: 360px;
    margin: auto;
    color: #666;
    &__separator {
        border-bottom: 1px solid $whisper;
    }
    &__text {
        font-size: 14px;
        line-height: 18px;
        color: $nightrider;
        margin-top: 15px;
    }
    &__buttons {
        margin-top: 25px;
        display: flex;
        justify-content: center;
    }
    .b-title {
        font-size: 18px;
    }
    .mfp-close {
        font-size: 0;
        &:before, &:after {
            content: '';
            width: 20px;
            height: 2px;
            background-color: $nightrider;
            position: absolute;
            top: 20px;
            left: 10px;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(135deg);
        }
    }
}

.galleryPopup {
    width: 100%;
    height: 800px;
    max-width: 1820px;
    max-height: 950px;
    padding: 20px 50px 60px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 6px;
    background-color: $white;
    position: relative;
    &__inner {
        height: 100%;
        position: relative;
    }
    &__tabs {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 50px;
        border-bottom: 3px solid #DDDDDD;
        position: absolute;
        top: 15px;
        left: 0;
    }
    &__tab {
        color: $nightrider;
        @extend %roboto;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        margin-right: 50px;
        &.active {
            &:after {
                content: '';
                width: 100%;
                height: 3px;
                background-color: $blue;
                position: absolute;
                bottom: -13px;
                left: 0;
            }
        }
    }
    &__content {
        position: relative;
        display: none;
        align-items: flex-start;
        height: 100%;
        &.active {
            display: flex;
        }
    }
    &__imagebox {
        width: 100%;
        height: 100%;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;
    }
    &__img {
        max-width: 100%;
        max-height: 95%;
        cursor: zoom-in;
        &.zoom-in {
            transform: scale(2);
            cursor: zoom-out;
        }
    }
    &__zoomText {
        display: inline-block;
        max-width: 100%;
        max-height: 5%;
        margin: 0;
        color: gray;
    }
    &__thumbbox {
        min-width: 400px;
        width: 100%;
        max-width: 400px;
        margin-top: 40px;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 20px;
        margin-bottom: 25px;
    }
    &__thumbs {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        margin-right: 15px;
        margin-bottom: 15px;
    }
    &__item {
        display: block;
        width: 70px;
        height: 70px;
        max-width: 70px;
        max-height: 70px;
        text-align: center;
        padding: 3px;
        border: 1px solid #C6C6C6;
        cursor: pointer;
        border-radius: 4px;
        &:hover {
            border-color: $blue;
        }
        &.active {
            border-width: 3px;
            border-color: $blue;
        }
        &img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__videobox {
        width: 100%;
        height: 100%;
        margin-right: 20px;
    }
    &__video {
        width: 100%;
        height: 100%;
        max-width: 1040px;
        max-height: 600px;
    }
    &__notifytext {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 34px;
        position: absolute;
        top: 200px;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%,0);
    }
    .mfp-close {
        font-size: 0;
        &:before, &:after {
            content: '';
            width: 20px;
            height: 2px;
            background-color: $nightrider;
            position: absolute;
            top: 20px;
            left: 10px;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(135deg);
        }
    }
}

.galleryScreen {
    width: 100%;
    height: 98vh;
    overflow: hidden;
    background-color: $white;
    padding: 20px 15px;
    &__inner {
        position: relative;
        height: 100%;
    }
    &__close {
        width: 110px;
        padding: 14px 12px;
        color: $nightrider;
        @extend %roboto;
        font-size: 20px;
        border: 1px solid #B0B0B0;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.6);
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 1;
    }
    &__picbox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        max-height: 750px;
        padding-bottom: 80px;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
    }
    &__thumbs {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 20px;
        left: 0;
    }
    &__item {
        display: block;
        width: 60px;
        height: 60px;
        max-width: 60px;
        max-height: 60px;
        text-align: center;
        padding: 3px;
        border: 1px solid #eee;
        margin-right: 12px;
        cursor: pointer;
        background-color: $white;
        &:hover {
            border-color: $blue;
        }
        &.active {
            border-width: 2px;
            border-color: $blue;
        }
        &img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .mfp-close {
        font-size: 0;
    }
}

.requestSentPopup {
    width: 480px;
    min-height: 340px;
    margin-right: auto;
    margin-left: auto;
    background-color: $white;
    border-radius: 4px;
    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 60px 40px 48px;
    }
    &__title {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 22px;
        margin-top: 25px;
    }
    &__text {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        line-height: 18px;
        margin-top: 8px;
        text-align: center;
    }
    &__btns {
        display: flex;
        align-self: center;
        margin-top: 30px;
        .btn-theme {
            min-width: 180px;
            margin: 7px;
        }
    }
}

@media screen and (max-width: 1300px) {
    .galleryPopup {
        width: 1080px;
        height: 620px;
    }
}

@media screen and (max-width: 1120px) {
    .galleryPopup {
        width: 960px;
        height: 500px;
        &__notifytext {
            top: 160px;
        }
    }
}

@media screen and (max-width: 500px) {
    .msg-dev, .smallPopup {
        width: 420px;
        max-width: 420px;
        padding: 80px 20px;
    }

    .requestSentPopup {
        width: 100%;
    }
}

@media screen and (max-width: 440px) {
    .msg-dev {
        width: 280px;
        max-width: 280px;
        padding: 50px 20px;
        &__title {
            font-size: 20px;
        }
        &__text {
            padding: 0 10px;
        }
    }

    .smallPopup {
        width: 280px;
        max-width: 280px;
    }

    .msgPopup {
        width: 100%;
        max-width: 100%;
    }

    .popup-action {
        width: 100%;
        .popup-header {
            padding: 10px 20px;
        }
        .popup-body {
            padding: 20px;
        }
        .popup-footer {
            padding: 20px 0;
            margin: 0 20px;
            .btn-theme {
                min-width: 120px;
                width: 120px;
            }
        }
    }

    .cancelOrderPopup {
        min-width: 1px;
        width: 100%;
        max-width: 100%;
        .btn-theme {
            min-width: 100px;
        }
    }

    .requestSentPopup {
        &__inner {
            padding-right: 15px;
            padding-left: 15px;
        }
        &__title {
            font-size: 18px;
        }
        &__btns {
            .btn-theme {
                min-width: 100px;
                margin: 5px;
            }
        }
    }

    .changePaytypePopup {
        width: 100%;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        &__btns {
            flex-direction: column;
            width: 100%;
            .btn-theme {
                width: 100%;
                &:first-child {
                    margin-right: 0;
                }
                &:last-child {
                    margin-top: 10px;
                }
            }
        }
    }

    .delete-confirmation-popup {
        width: 100%;
    }

    .addPackageCommentPopup {
        padding-right: 15px;
        padding-left: 15px;
        &__textarea {
            width: 100%;
        }
    }

    .shareInvoicePopup {
        padding: 40px 15px 30px;
        &__actions {
            flex-direction: column;
            .btn-theme {
                width: 100%;
                &:first-child {
                    margin-right: 0;
                }
            }
        }
        &__submit {
            display: block;
            margin-top: 12px;
        }
    }
}

@media screen and (max-width: 360px){
    .locationPopup, .shipAheadPopup {
        width: 310px;
        max-width: 310px;
    }
}
