.myContractsHeadline {
    padding: 10px 0;
    position: relative;
    .btn-theme {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.myContracts {
    margin-top: 20px;
    &Params {
        display: flex;
        align-items: center;
        padding: 18px 20px;
        background-color: #F8F8F8;
        border-radius: 4px;
    }
    &Param {
        color: $nightrider;
        @extend %roboto-medium;
        font-size: 14px;
        &:nth-child(1) {
            min-width: 260px;
        }
        &:nth-child(2) {
            min-width: 130px;
        }
        &:nth-child(3) {
            min-width: 200px;
        }
        &:nth-child(4) {
            min-width: 140px;
        }
    }
}

.myContractItem {
    display: flex;
    align-items: flex-start;
    padding: 25px 20px;
    color: $nightrider;
    font-size: 14px;
    &__seller {
        display: flex;
        flex-direction: column;
        @extend %roboto-medium;
        min-width: 260px;
        max-width: 260px;
        padding-right: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__company {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__link {
        line-height: 22px;
    }
    &__params {
        display: flex;
        align-items: center;
    }
    &__param {
        @extend %roboto;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:nth-child(1) {
            min-width: 130px;
        }
        &:nth-child(2) {
            min-width: 200px;
            max-width: 200px;
        }
        &:nth-child(3) {
            min-width: 140px;
        }
        &:nth-child(4) {
            min-width: 160px;
        }
    }
    &__time {
        margin-right: 6px;
    }
    &__pre {
        display: none;
        @extend %roboto-medium;
    }
    &__paymentType {
        white-space: normal;
    }
    &__request {
        display: flex;
        align-items: center;
        @extend %roboto;
    }
    & + & {
        border-top: 1px solid #ccc;
    }
    &:last-child {
        border-bottom: 1px solid #ccc;
    }
}