%roboto-light {
    font-family: 'Roboto-Light';
}

%roboto {
    font-family: 'Roboto';
}

%roboto-medium {
    font-family: 'Roboto-Medium';
}

%roboto-bold {
    font-family: 'Roboto-Bold';
}

%montserrat-extrabold {
    font-family: 'Montserrat-ExtraBold';
}

$nightrider: #333;
$blue: #3674B4;
$darkblue: #215891;
$white: #fff;
$color-btn: #2D9CDB;
$whisper: #e7e7e7;
$dimgray: #666;
$aliceblue: #eaf4ff;
$solitude: #dbedff;
$darkgray: #a4a4a4;
$nobel: #999;
$lightgrey: #d5d5d5;
$whitesmoke: #f3f3f3;
$verylightgrey: #cecece;
$dodgerblue: #007DFF;
$radicalred: #FF494F;
$seagreen: #219653;
$pinkswan: #B3B3B3;
$cornflower: #F9FCFF;
$hintofgreen: #EFFFF6;
$pumpkin: #ff6a16;

%hovered-lighter {
    opacity: 1;
    &:hover {
        opacity: 0.6;
    }
}


