@media screen and (max-width: 1199px) {
    .completedOrder {
        &__shortTitle {
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__groups {
            display: flex;
            flex-direction: column;
        }
        &__group {
            &:first-child {
                margin-left: 0;
                .completedOrder {
                    &__infoWrap {
                        width: auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .completedOrder {
        &__content {
            position: relative;
            .b2b {
                position: absolute;
                top: 0;
                right: 0;
                &:after {
                    width: 50px;
                    height: 30px;
                    font-size: 16px;
                    line-height: 30px;
                }
            }
            .completedOrder {
                &__inner:first-child {
                    width: 100%;
                    flex-direction: column;
                }
            }
        }
        &__company {
            margin-top: 10px;
        }
        &__companyInfo {
            align-items: flex-start;
        }
        &__title, &__company, &__companyName, &__shortTitle, &__infoWrap {
            width: auto;
            max-width: 100%;
            white-space: normal;
        }
        &__companyName {
            margin-right: 14px;
        }
        .text-shadow:after {
            content: none;
        }
        &__groups {
            .completedOrder {
                &__paramWrap {
                    flex-direction: column;
                }
            }
        }
        &__btns {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}

@media screen and (max-width: 480px) {
    .completedOrder {
        &__content {
            padding-bottom: 50px;
        }
        &__btns {
            width: 100%;
            .btn-theme {
                width: 100%;
            }
        }
    }
}