.tooltip {
    position: relative;
    &:hover {
        &:before {
            content: '';
            position: absolute;
            top: 24px;
            left: 2px;
            border: 8px solid transparent;
            border-bottom: 8px solid #eee;
        }
        &:after {
            content: attr(data-title);
            color: $nightrider;
            @extend %roboto;
            font-size: 12px;
            background-color: #eee;
            border-radius: 4px;
            position: absolute;
            top: 40px;
            right: -9px;
            white-space: nowrap;
            z-index: 10;
            padding: 2px 8px;
        }
    }
}

.tooltipLink {
    width: 14px;
    height: 14px;
    display: block;
    @extend %question-icon;
    cursor: pointer;
    position: relative;
    &:hover {
        .tooltipBody {
            display: block;
        }
    }
}

.tooltipBody {
    display: none;
    background-color: $white;
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: 50%;
    min-width: 200px;
    z-index: 100;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.45);
    border-radius: 2px;
    padding: 15px;
    transform: translate(-50%, 0);
    &.show {
        display: block;
    }
    &:before {
        content: "\25b2 ";
        position: absolute;
        top: -18px;
        left: 50%;
        margin-left: -27px;
        font-size: 16px;
        color: $white;
        text-shadow: 0 -2px 3px rgba(0,0,0,.2);
        z-index: -1;
        transform: scaleX(2) translate(50%, 0);
    }
    &__text {
        & + & {
            margin-top: 5px;
        }
        color: $nightrider;
        @extend %roboto;
        font-size: 12px;
        &--red {
            color: #ff4646;
        }
        &--bold {
            @extend %roboto-medium;
        }
    }
}