.js-scrolled-list {
    .scroll-element div {
        background: 0 0;
        border: none;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
    }
    .scroll-element.scroll-y.scroll-scrolly_visible {
        width: 6px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: 0 0;
        border: none;
        margin: 0;
        padding: 0;
        z-index: 1;
    }
    .scroll-element_size {
        background-color: #f9f9f9 !important;
        opacity: 1;
        -webkit-transition: all .2s ease 0s;
        -o-transition: all .2s ease 0s;
        transition: all .2s ease 0s;
        border-radius: 4px;
    }
    .scroll-element_track {
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
    }
    .scroll-bar {
        width: 6px !important;
        height: 60px !important;
        border-radius: 4px !important;
        pointer-events: all !important;
        position: relative !important;
        z-index: 100 !important;
        cursor: pointer !important;
        background-color: #ababab !important;
    }
}