.burger {
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
        display: block;
        width: 18px;
        height: 2px;
        background-color: $blue;
        border-radius: 2px;
        position: relative;
        &:before, &:after {
            content: '';
            display: block;
            width: 18px;
            height: 2px;
            background-color: $blue;
            border-radius: 2px;
            position: absolute;
        }
        &:before {
            top: -5px;
        }
        &:after {
            top: 5px;
        }
    }
    &--text {
        span {
            background-color: $white;
            &:before, &:after {
                background-color: $white;
            }
        }
        &:after {
            content: attr(data-text);
            color: $white;
            @extend %roboto-medium;
            font-size: 14px;
            position: absolute;
            top: 3px;
            left: 30px;
            line-height: 14px;
            width: 120px;
        }
    }
    &--white {
        span {
            background-color: $white;
            &:before, &:after {
                background-color: $white;
            }
        }
        &:after {
            color: $white;
        }
    }
    &--blue {
        span {
            background-color: $blue;
            &:before, &:after {
                background-color: $blue;
            }
        }
        &:after {
            color: $blue;
        }
    }
}

.menu {
    position: absolute;
    background-color: $white;
    z-index: 100;
    margin: 0;
    padding: 20px 0 60px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    width: 230px;
    max-width: 230px;
    &:before {
        content: "\25b2 ";
        position: absolute;
        top: -18px;
        font-size: 16px;
        color: $white;
        text-shadow: 0 -2px 3px rgba(0,0,0,.3);
        z-index: -1;
        transform: scaleX(2);
    }
    &-item {
        list-style-type: none;
        user-select: none;
        &:hover {
            box-shadow: -2px 0px 1px rgba(0, 0, 0, 0.5);
            .submenu {
                display: flex;
            }
        }
    }
    &-link {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        white-space: nowrap;
        display: block;
        padding: 6px 20px;
        position: relative;
        &:hover {
            color: $nightrider;
            text-decoration: none;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: -1px;
                width: 5px;
                height: 100%;
                z-index: 1000;
                background-color: $white;
            }
        }
    }
}

.submenu {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
    z-index: 100;
    background-color: $white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    border-radius: 0px 2px 2px 0px;
    width: 674px;
    height: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    &-category {
        padding: 20px 5px 30px 30px;
        width: 224px;
        max-width: 224px;
        line-height: 14px;
    }
    &-categoryTitle {
        color: #2165ab;
        @extend %roboto-medium;
        font-size: 14px;
        display: inline-block;
        line-height: 18px;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 10px;
        &:hover {
            color: #2165ab;
            text-decoration: none;
        }
    }
    &-categoryList {
        list-style: none;
        margin: 10px 0 0;
        padding: 0;
    }
    &-categoryItem {
        & + & {
            margin-top: 6px;
        }
    }
    &-categoryLink {
        color: $nightrider;
        @extend %roboto;
        font-size: 14px;
        display: block;
        line-height: 18px;
        &:hover {
            text-decoration: none;
        }
    }
}

.onHeader {
    .menu {
        top: 31px;
        left: -144px;
        &:before {
            left: 146px;
        }
    }
}
.onPage {
    .menu {
        top: 10px;
        left: 0;
        &:before {
            left: 75px;
        }
    }
}

.dropdown-menu {
    margin: 0;
    padding: 0;
    border: 0;
}

.pageMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    .pageNav {
        &__list {
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: flex;
            align-items: center;
        }
        &__item {
            & + .pageNav__item {
                margin-left: 30px;
            }
        }
        &__link {
            color: $white;
            @extend %roboto-medium;
            font-size: 13px;
            display: flex;
            align-items: center;
            &:hover {
                color: $white;
                text-decoration: none;
            }
            i {
                margin-left: 10px;
            }
        }
    }
    .pageLinks {
        display: flex;
        align-items: center;
        &__link {
            display: block;
            & + .pageLinks__link {
                margin-left: 20px;
            }
        }
    }
}