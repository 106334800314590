.catalog {
    max-width: 1920px;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    padding: 30px 25px;
    margin-right: auto;
    margin-left: auto;
    &__title {
        color: $nightrider;
        @extend %roboto-bold;
        font-size: 26px;
        line-height: 26px;
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        justify-items: center;
        margin-top: 50px;
        overflow: hidden;
    }
    &Filters {
        width: 315px;
        min-width: 315px;
        max-width: 315px;
        border-right: 1px solid #efefef;
    }
    &Filters + &Content {
        padding-left: 40px;
    }
    &Content {
        width: 100%;
    }
    &Filter {
        &__title {
            color: $nightrider;
            @extend %roboto-bold;
            font-size: 16px;
            margin-bottom: 15px;
            line-height: 100%;
        }
        &__categories {
            & + & {
                margin-left: 15px;
                margin-top: 10px;
            }
        }
        &__category {
            display: flex;
            flex-direction: column;
            position: relative;
            @extend %roboto;
            &Link {
                display: flex;
                align-items: center;
                color: $nightrider;

                font-size: 16px;
                line-height: 18px;
                &:hover {
                    text-decoration: none;
                }
                i {
                    margin-right: 15px;
                }
            }
            &Group {
                & + & {
                    margin-left: 36px;
                    margin-top: 11px;
                }
            }
            &Current {
                @extend %roboto-bold;
                margin: 7px 0 7px;
                line-height: 18px;
            }
            & + & {
                margin-top: 10px;
            }
        }
        &__brands {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        &__showAll {
            display: inline-block;
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 16px;
            line-height: 20px;
            margin-top: 15px;
            cursor: pointer;
        }
        .rangeView {
            &__group {
                display: flex;
            }
            &__field {
                display: flex;
                align-items: center;
                margin-right: 15px;
                &:before {
                    content: attr(data-text);
                    color: $nobel;
                    @extend %roboto-bold;
                    font-size: 14px;
                    margin-right: 10px;
                }
            }
            &__input {
                max-width: 95px;
                padding: 3px 12px;
                border-radius: 4px;
                border: 1px solid #B3B3B3;
                background-color: $white;
                outline: none;
                color: $nightrider;
                @extend %roboto;
                font-size: 16px;
            }
            &__btn {
                width: 232px;
                margin-top: 15px;
                margin-left: 25px;
            }
        }
        .ratingView {
            &__item {
                display: flex;
                align-items: flex-start;
                margin-bottom: 10px;
                .stars {
                    margin-left: 12px;
                }
                &:after {
                    content: attr(data-text);
                    color: $dimgray;
                    @extend %roboto;
                    font-size: 14px;
                    line-height: 18px;
                    margin-left: 10px;
                }
            }
        }
        .checkView + .checkView {
            margin-top: 10px;
        }
        & + & {
            margin-top: 40px;
        }
    }
    &Item {
        width: 320px;
        max-width: 320px;
        padding: 40px 20px 40px;
        border-bottom: 1px solid #dcdcdc;
        margin-bottom: 40px;
        position: relative;
        &__imgWrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 240px;
            max-width: 240px;
            max-height: 240px;
            margin-left: auto;
            margin-right: auto;
        }
        &__img {
            max-width: 100%;
            max-height: 100%;
        }
        &__info {
            margin-top: 30px;
        }
        &__title {
            display: flex;
            color: $nightrider;
            @extend %roboto;
            font-size: 16px;
            line-height: 22px;
            min-height: 44px;
            max-height: 44px;
            overflow: hidden;
            &:hover {
                color: $nobel;
                text-decoration: none;
            }
        }
        &__rating {
            display: flex;
            align-items: center;
            margin-top: 10px;
        }
        &__count {
            color: #5f5f5f;
            @extend %roboto;
            font-size: 13px;
            margin-left: 12px;
            max-width: 80px;
            overflow: hidden;
            &:hover {
                color: $nobel;
                text-decoration: none;
            }
        }
        &__purchase {
            display: flex;
            align-items: center;
            margin-top: 20px;
        }
        &__price {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: 25px;
            &New {
                color: $nightrider;
                @extend %roboto-bold;
                font-size: 17px;
                line-height: 20px;
                max-width: 100px;
                overflow: hidden;
            }
            &Old {
                color: #929292;
                @extend %roboto-medium;
                font-size: 14px;
                line-height: 16px;
                display: inline-block;
                position: relative;
                padding: 0 4px;
                margin-top: 2px;
                max-width: 100px;
                overflow: hidden;
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: #c2c2c2;
                    position: absolute;
                    top: 50%;
                    left: 0;
                }
            }
        }
        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $nightrider;
            border-radius: 4px;
            min-width: 54px;
            max-width: 54px;
            min-height: 36px;
            max-height: 36px;
        }
    }
    &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    //TODO: перенести в модальные окна
    &WarehouseSelect {
        background-color: $white;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.35);
        border-radius: 6px;
        width: 530px;
        margin: 0 auto;
        position: relative;
        padding: 50px 30px;
        &__warehouseSelector {
            margin-top: 25px;
        }
        &__button {
            margin-top: 30px;
        }
        &__productCount {
            margin-top: 20px;
            display: flex;
            align-items: center;
            .numUpDown {
                margin-left: 20px;
            }
        }
    }
}

.itemLabel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px 17px;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    background-color: $white;
    position: absolute;
    top: 0;
    left: 15px;
    &:before {
        content: attr(data-text);
        @extend %roboto;
        font-size: 12px;
        text-transform: uppercase;
    }
    &--hit {
        border-color: #00458A;
        color: #0C5CAD;
    }
    &--discount {
        border-color: #219653;
        color: #219653;
    }
}

@media screen and (max-width: 1024px) {
    .catalog {
        &Filters {
            display: none;
        }
        &Filters + &Content {
            padding-left: 0;
        }
    }
}

@media screen and (max-width: 360px) {
    .catalog {
        padding-left: 15px;
        padding-right: 15px;
    }
}
