$menu-item-border-color: $pinkswan;
$text-color: $nightrider;

%menu-font {
    font-family: 'Roboto';
    font-size: 14px;
    line-height: normal;
    color: $text-color;
} 

%menu-font-title {
    @extend %menu-font;
    font-weight: 500;
    font-size: 18px;
}

.lkMenu {   
    border-bottom: 1px solid $nobel;
    &Item {       
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 30px;
        padding: 27px 20px; 
        border: 1px solid $pinkswan;
        border-radius: 4px;
        transition: all .3s ease;
        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        &--disabled {
            opacity: 0.4;
        }
        &__text {
            margin-left: 20px;
        }
        &__title {
            @extend %menu-font-title;
        }
        &__description {
            @extend %menu-font;
            margin-top: 8px;
        }
        &__link {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            display: block;
            text-decoration: none;
            color: $text-color;
            &:hover, &:active {
                text-decoration: none;
                color: $text-color;
            }
        }
        &__icon {
            position: relative;
        }
        &__count {
            position: absolute;
            top: 4px;
            left: 33px;
            color: #ff6a16;
            min-width: 16px;
            max-width: 18px;
            text-align: end;
            overflow: hidden;
            font-size: 16px;
            font-style: normal;
        }
    }
}
