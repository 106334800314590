.breadcrumbs {
    padding: 20px 0;
    &__items {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    &__item {
        margin-right: 10px;
        & + & {
            display: flex;
            align-items: center;
            &:before {
                content: '';
                display: flex;
                width: 7px;
                min-width: 7px;
                height: 12px;
                margin-top: 2px;
                background: url('../img/sprite.svg') 0 -1424px;
            }
            .breadcrumbs__link {
                padding-left: 8px;
            }
        }
    }
    &__link, &__item {
        color: #b1b1b1;
        @extend %roboto;
        font-size: 13px;
        &:hover {
            text-decoration: none;
        }
    }
    &--border {
        border-bottom: 1px solid #dedede;
        padding: 15px;
    }
    & + .b-success,
    & + .b-error {
        margin-top: 0;
    }
}

@media screen and (max-width: 480px) {
    .breadcrumbs {
        &--border {
            padding: 15px 0;
        }
    }
}
