.mainSlider {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    position: relative;
    height: 100%;

    &Slide {
        overflow: hidden;
        height: 100%;
        width: 100%;
        display: none;
        position: relative;

        &__background {
            &__img {
                max-width: 100%;
                max-height: 100%;
                height: 100%;
            }
        }
        &__link {
            display: flex;
            width: 100%;
            height: 100%;
        }
        &__image {
            position: absolute;
            top: 50%;
            right: 110px;
            transform: translate(0, -50%);
            &__img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &__content {
            position: absolute;
            top: 50%;
            left: 170px;
            transform: translate(0, -50%);
        }
        &__title {
            max-width: 570px;
            color: $white;
            @extend %montserrat-extrabold;
            font-size: 40px;
            line-height: 52px;
            margin-bottom: 30px;
            text-transform: uppercase;
        }
        &__text {
            color: $white;
            @extend %montserrat-extrabold;
            font-size: 18px;
            line-height: 24px;
            text-transform: uppercase;
        }
    }

    &:hover {
        .nav-arrow {
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=@ms)";
            filter: alpha(opacity=100);
            -khtml-opacity: 1;
            -moz-opacity: 1;
            opacity: 1;
        }
    }

    &:hover, &:active {
        cursor: pointer;
    }

    .preloader-svg {
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        margin: -12px 0 0 -12px;
    }

    &-viewport, .slider-viewport {
        overflow: hidden;
        position: relative;
        height: 100%;
    }

    .touch {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 99;
    }

    &-container {
        height: inherit;
        position: relative;
    }

    .slider-container {
        &.grabbing {
            cursor: grabbing;
        }
        display: flex;
        height: 100%;
    }

    .nav-arrow {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=@ms)";
        filter: alpha(opacity=0);
        -khtml-opacity:0;
        -moz-opacity: 0;
        opacity: 0;
        -webkit-transition: opacity .2s ease;
        -moz-transition: opacity .2s ease;
        transition: opacity .2s ease;
        position: absolute;
        top: 50%;
        width: 100%;
        margin-top: -(25px);
        z-index: 999;

        &.top {
            top: 0;
            margin-top: -(50px);
            .next,
            .prev {
                background: transparent;
            }
        }

        &.bottom {
            top: auto;
            bottom: 0;
            .next,
            .prev {
                background: transparent;
            }
        }
    }

    .next,
    .prev {
        cursor: pointer;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        behavior: url(pie.htc);
        -webkit-transition: all .2s ease;
        -moz-transition: all .2s ease;
        transition: all .2s ease;
        display: block;
        position: absolute;
        top: 0;
        z-index: 99;
        width: 52px;
        height: 52px;

        &:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            margin: 10px;
            width: 100%;
            height: 100%;
        }
    }

    .next {
        right: 10px;
        &:after {
            background: url('../img/next-slide.svg') no-repeat;
            -webkit-transition: margin-right .2s ease;
            -moz-transition: margin-right .2s ease;
            transition: margin-right .2s ease;
            right: 0;
        }

        &:hover {
            &:after {
                background: url('../img/next-slide.svg') no-repeat;
                margin-left: 10px;
            }
        }
    }

    .prev {
        left: 10px;
        &:after {
            background: url('../img/prev-slide.svg') no-repeat;
        }

        &:hover {
            &:after {
                background: url('../img/prev-slide.svg') no-repeat;
            }
        }
    }
}

.nav-point {
    display: flex;
    z-index: 99;
    position: absolute; right: 20px;
    transition: opacity .3s ease;
    padding: 0;

    &.top {
        top:0;
        margin-top: 8px;
    }

    &.bottom {
        bottom:0;
        margin-bottom: 8px;
    }

    &.left {
        margin-left: 0;
    }

    &.right {
        margin-right: 0;
    }

    li {
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.35);
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.35);
        behavior: url(pie.htc);
        position: relative;
        background: #E6E6E6;
        width: 12px;
        height: 12px;
        margin: 10px;
        display: block;
        transition: background .2s ease;

        &:hover {
            &:not(.active) {
                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                    behavior: url(pie.htc);
                    width: 6px;
                    height: 6px;
                    top: 3px;
                    left: 3px;
                    background: #cacaca;
                }
            }
        }

        &.active {
            -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
            -moz-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
            behavior: url(pie.htc);
            background: #C4C4C4;
            background-image: -o-linear-gradient(bottom, #C4C4C4 0%, darken(#C4C4C4, 10%) 100%);
            background-image: -moz-linear-gradient(bottom, #C4C4C4 0%, darken(#C4C4C4, 10%) 100%);
            background-image: -webkit-linear-gradient(bottom, #C4C4C4 0%, darken(#C4C4C4, 10%) 100%);
            background-image: -ms-linear-gradient(bottom, #C4C4C4 0%, darken(#C4C4C4, 10%) 100%);
            background-image: linear-gradient(to bottom, #C4C4C4 0%, darken(#C4C4C4, 10%) 100%);
            -pie-background-image: linear-gradient(to bottom, #C4C4C4 0%, darken(#C4C4C4, 10%) 100%);
        }
    }
}
