.showHistoryLimitedPopup {
    display: block;
    margin-right: auto;
    margin-left: auto;
    z-index: 100;
    position: relative;
    width: 100%;
    height: 350px;
    padding: 10px 40px;
    background: $whitesmoke;
    &__items{
        overflow: hidden;
    }
    &Carousel{
        display: flex;
        height: 350px;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        transition: margin-left 250ms;
        font-size: 0;
    }
    &Item{
        display: inline-block;
        width: 210px;
        height: 250px;
        background: $white;
        margin: 30px;
        max-height: 350px;
        &__wrap{
            display: block;
        }
        &__last{
            display: inline-block;
            width: 210px;
            height: 250px;
            margin: 30px;
            background: $whitesmoke;
        }
        &__imgWrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 130px;
            max-width: 130px;
            max-height: 130px;
            margin: 20px auto 24px;
        }
        &__img{
            position: relative;
            width: 130px;
            height: 130px;
            display: block;
        }
        &__inCart{
            position: absolute;
            width: 104px;
            height: 30px;
            background: rgba(124, 124, 124, 0.9);
            border-radius: 4px;
            @extend %roboto;
            font-size: 12px;
            line-height: 30px;
            text-align: center;
            color: $white;
        }
        &__title {
            display: flex;
            color: $nightrider;
            @extend %roboto;
            font-size: 16px;
            line-height: 22px;
            min-height: 44px;
            max-height: 44px;
            overflow: hidden;
            max-width: 170px;
            margin-left: 20px;
            &:hover {
                color: $nobel;
                text-decoration: none;
            }
        }
        &__round {
            display: block;
            position: relative;
            width: 130px;
            height: 130px;
            background: #3674B4;
            border-radius: 50%;
            text-align: center;
            margin: 60px auto auto;

            &:hover{
                background: #194E82;
            }
        }
        &__roundText{
            position: absolute;
            @extend %roboto;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: $white;
            width: 105px;
            height: 28px;
            left: 10%;
            bottom: 40%;
        }
        &__line{
            width: 100%;
            height: 24px;
            position: relative;
            &Line{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                height: 10px;
                background: $white;
                margin: auto;
            }
            &Round{
                width: 10px;
                height: 10px;
                background: #1365AB;
                border-radius: 50%;
                margin: auto;
                &Last{
                    width: 10px;
                    height: 10px;
                    background: #1365AB;
                    border-radius: 50%;
                    margin: auto;
                    &:hover{
                        background: #194E82;
                    }
                }
                &:hover{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 24px;
                    height: 24px;
                    margin: auto;
                    background: url("../img/showhistory-limited-remove.svg") 0 0;
                }
            }
            .b-paragraph {
                color: #333;
                font-size: 16px;
                line-height: 20px;
                position: absolute;
                top: 80%;
                left: 38%;
            }
        }
    }
}

.limitedShowHistorySelectorComponent{
    margin-right: 30px;
    margin-left: 30px;
}

.limitedShowHistoryDropdownModalComponent {
    position: relative;
    width: 100%;
    &__wrap {
        z-index: 2043;
        position: absolute;
        outline: 0!important;
        &--right {
            @media screen and (min-width: 660px) {
                right: -70px;
            }
        }
    }
    &__container {
        margin-top: 16px;
        box-sizing: border-box;
        transition: all 0.3s ease;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
    &__content {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin: 0 auto;
        text-align: left;
        z-index: 2045;
        box-shadow: 0 0 6px rgba(0,0,0,.35);
        border-radius: 4px;
        padding: 20px 5px 20px 20px;
        right: 610px;
        background: $whitesmoke;
        width: 100vw;
    }
}

.headerMaskComponent{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: $nightrider;
    opacity: 0.6;
    transition: opacity 0.3s ease;
}
