@media screen and (min-width: 993px) and (max-width: 1920px) {
    .magnifier {
        height: 640px !important;
    }
}

@media screen and (min-width: 993px) and (max-width: 1560px) {
    .slider {
        width: 600px;
        &__wrap {
            max-height: 270px;
        }
        &__item {
            width: 75px;
            height: 75px;
            max-width: 75px;
            max-height: 75px;
        }
        &__picbox {
            width: 500px;
            height: 500px;
        }
    }
    .magnifier {
        height: 500px !important;
    }
}

@media screen and (min-width: 993px) and (max-width: 1460px) {
    .slider {
        width: 540px;
        &__picbox {
            width: 440px;
            height: 440px;
        }
    }
    .shortInfo {
        width: 280px;
        .productAvailability {
            flex-direction: column;
        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1340px) {
    .slider {
        width: 420px;
        &__wrap {
            max-height: 165px;
        }
        &__item {
            width: 40px;
            height: 40px;
            max-width: 40px;
            max-height: 40px;
        }
        &__picbox {
            width: 360px;
            height: 360px;
        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .productSlider {
        padding-right: 15px;
    }

    .productPayment {
        padding-left: 15px;
    }
    .productInfo {
        &__title {
            font-size: 22px;
            line-height: 28px;
        }
        &__links {
            flex-wrap: wrap;
            div {
                margin-top: 8px;
            }
        }
        &__reviews {
            margin: 0 12px;
        }
        &__contract {
            .selectPopup {
                margin-right: 0;
            }
        }
        &__palette {
            &Toggle {
                display: none;
            }
        }
        .shortInfoPrice {
            align-items: flex-start;
            &__wrap {
                align-items: flex-start;
                margin-top: 2px;
            }
            &__discount {
                margin-left: 20px;
            }
        }
    }

    .palette {
        &Wrap {
            max-height: 210px !important;
            &.scroll-wrapper>.scroll-content {
                overflow: scroll !important;
            }
            .scroll-element.scroll-y.scroll-scrolly_visible {
                display: block;
            }
        }
        &Item {
            width: 70px;
            max-width: 70px;
            &__link {
                width: 70px;
                height: 70px;
                min-width: 70px;
                min-height: 70px;
            }
        }
    }

    .shortInfo {
        width: 240px;
        padding: 15px 12px;
        &__amount {
            align-items: flex-start;
            flex-direction: column;
            &Value {
                margin-top: 10px;
                margin-left: 0;
            }
        }
        &__reviews {
            margin-top: 5px;
        }
        &__storeSelect {
            .selectPopup {
                padding: 5px 0;
            }
        }
    }

    .productAvailability {
        flex-direction: column;
        &__text {
            margin-top: 2px;
            margin-left: 0;
        }
    }

    .anotherSellers {
        .anotherSeller {
            flex-direction: column;
            &__btn {
                margin-top: 8px;
            }
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1140px) {
    .slider {
        width: 380px;
        &__picbox {
            width: 320px;
            height: 320px;
        }
    }
    .productCharacteristic {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .productCard {
        position: relative;
    }
}

@media screen and (max-width: 992px) {
    .product-container {
        max-width: 960px;
    }

    .productCard {
        align-items: center;
        flex-direction: column;
        &__wrapper {
            position: static;
        }
        .productSlider {
            width: 100%;
            max-width: 460px;
            height: 600px;
            padding-top: 40px;
            padding-right: 0;
            margin-right: auto;
            .slider { display: none; }
            .slider-mobile { display: block; }
        }
        .productCharacteristic {
            width: 100%;
            padding-top: 40px;
            padding-right: 0;
            padding-left: 0;
        }
        .productPayment {
            width: 100%;
            padding-left: 0;
            position: absolute;
            top: 40px;
            right: 40px;
            max-width: 350px;
        }
    }

    .fotorama {
        &__stage, &__stage__shaft, &__stage__frame {
            width: 430px !important;
            height: 430px !important;
            .fotorama__img {
                width: auto !important;
                height: auto !important;
                max-width: 100% !important;
                max-height: 100% !important;
                margin: 0 auto;
            }
        }
        &__thumb {
            text-align: center;
            background-color: $white;
            &-border {
                display: none;
            }
        }
        &__nav {
            &-wrap {
                margin-top: 25px;
            }
            &__frame {
                width: 74px !important;
                height: 74px !important;
                border: 1px solid #C6C6C6;
                border-radius: 4px;
                box-sizing: border-box;
                .fotorama__img {
                    width: auto !important;
                    height: auto !important;
                    max-width: 100% !important;
                    max-height: 100% !important;
                    position: static;
                }
            }
            &__frame.fotorama__active {
                border: 3px solid #3674B4;
            }
            &__frame + &__frame {
                margin-left: 15px;
            }
        }
    }

    .productInfo {
        width: 100%;
        max-width: 100%;
        &__parametersWrap {
            display: flex;
            align-items: center;
        }
        &__parametersGroup {
            margin-left: 15px;
        }
        &__palette {
            &Content {
                width: 100%;
            }
            &Title {
                padding-right: 0;
            }
            &Toggle {
                display: none;
            }
            .palette {
                flex-wrap: nowrap;
                overflow: scroll;
                &:after {
                    content: '';
                    display: inline-block;
                    background-image: linear-gradient(to right,rgba(255,255,255,0),#fff 100%);
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 100%;
                }
            }
        }
    }

    .productProperties {
        padding: 20px 0;
    }

    .productProperty {
        &__title {
            min-width: 300px;
            max-width: 300px;
        }
    }
}

@media screen and (max-width: 960px) {
    .product-container {
        max-width: 720px;
    }

    .productCard {
        padding-bottom: 0;
        .productSlider {
            max-width: 305px;
        }
        .productPayment {
            right: 0;
        }
    }

    .productInfo {
        &__groupParams {
            .selectWindow {
                left: -80px;
            }
        }
    }

    .fotorama {
        &__wrap {
            width: 305px !important;
        }
        &__stage, &__stage__shaft, &__stage__frame {
            width: 300px !important;
            height: 300px !important;
        }
        &__nav {
            &__frame {
                width: 65px !important;
                height: 65px !important;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .product-container {
        max-width: 100%;
    }

    .properties-container {
        max-width: 100%;
        padding-right: 35px;
        padding-left: 35px;
        .productProperties:first-child {
            padding-top: 0;
        }
    }

    .comments-container {
        padding-right: 0;
        padding-left: 0;
    }

    .productCard {
        align-items: flex-start;
        padding-top: 18px;
        &__wrapper {
            flex-direction: column;
        }
        .productSlider {
            max-width: 100%;
            height: auto;
            border-top: 1px solid #D8D8D8;
        }
        .productPayment {
            position: static;
            max-width: 100%;
            .shortInfo {
                width: 100%;
                padding: 0;
                box-shadow: none;
                &__price, &__delivery {
                    display: none;
                }
                &__storeSelect {
                    .selectWindow {
                        left: 0;
                    }
                }
                &__anotherSellers {
                    border-bottom: 1px solid #d8d8d8;
                    padding-bottom: 15px;
                }
            }
        }
    }

    .fotorama {
        &__wrap {
            margin-right: auto;
            margin-left: auto;
        }
    }

    .productInfo {
        &__parametersWrap, &__palette { display: none; }
        &__contract {
            margin-top: 0;
        }
        &__packedItems, &__typesItems {
            overflow: auto;
        }
        &__packedItem, &__typesItem {
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F4F4F4;
        }
    }

    .anotherSellers {
        .anotherSeller {
            &__name {
                max-width: 440px;
            }
        }
    }

    .mobileOnly { display: block; }
}

@media screen and (max-width: 575px) {
    .properties-container {
        padding-right: 15px;
        padding-left: 15px;
    }

    .productInfo {
        &__title {
            font-size: 20px;
            line-height: 26px;
        }
    }

    .shortInfo {
        padding-right: 0;
        padding-left: 0;
        &Price {
            align-items: flex-start;
            &__wrap {
                align-items: flex-start;
                flex-direction: column;
            }
        }
        &__storeLinks {
            .link {
                font-size: 16px;
            }
        }
        &__storeSelect {
            .b-select {
                width: 100%;
            }
            .selectWindow {
                width: 100%;
                padding-top: 35px;
                &__list {
                    width: 100%;
                    &--col1 {
                        min-width: 130px;
                    }
                    &--col2 {
                        min-width: 120px;
                    }
                }
                &__item {
                    flex-direction: column;
                }
            }
        }
        &__anotherSellers {
            min-height: 150px;
        }
    }

    .locationPopup {
        left: 0;
        &__text {
            &--decorate {
                &:before, &:after {
                    width: 110px;
                }
            }
        }
        &__index {
            .field {
                min-width: 100px;
                margin-right: 20px;
            }
        }
        .btn-theme {
            padding-right: 10px;
            padding-left: 10px;
        }
    }

    // Блоки
    .infoDelivery {
        &Price {
            max-width: 100%;
            line-height: 20px;
            &Value, &Text {
                font-size: 16px;
            }
            & + & {
                margin-top: 8px;
            }
            .link {
                font-size: 16px;
            }
        }
    }
    .deliveryTo, .shipAhead {
        &__text {
            max-width: 100%;
            font-size: 16px;
        }
        &__value {
            margin-top: 5px;
            .link {
                font-size: 16px;
            }
        }
    }

    .productAvailability {
        flex-wrap: wrap;
        &__status, &__text {
            font-size: 16px;
        }
    }

    .anotherSellers {
        &Headline {
            &__title {
                font-size: 18px;
            }
            .link {
                font-size: 16px;
            }
        }
        .anotherSeller {
            &__content, .link {
                font-size: 16px;
            }
            &__name {
                max-width: 160px;
            }
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }

    .selectPopup {
        &__text {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .storeMarker {
        font-size: 16px;
    }
    // /Блоки

    .productComments {
        padding: 30px 15px;
        &__content {
            padding-top: 35px;
        }
        &__btn {
            margin: 40px 0 0;
        }
        .productComment {
            &__content {
                margin-left: 0;
            }
            &__author {
                flex-wrap: wrap;
                &Name {
                    line-height: 30px;
                }
                &Rating {
                    position: static;
                    display: flex;
                    align-items: center;
                }
            }
            &__date {
                margin-top: 0;
                margin-left: 10px;
                line-height: 24px;
            }
            &__aboutPurchase {
                margin-top: 15px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .productInfo {
        &__title {
            font-size: 18px;
            line-height: 24px;
        }
        &__links {
            flex-wrap: wrap;
            & > div {
                padding: 5px 0;
            }
        }
        &__contract {
            width: 100%;
        }
    }

    .properties-container {
        .productPropertiesWrap {
            max-height: 280px;
            overflow: hidden;
            position: relative;
            margin-bottom: 10px;
            &.open { max-height: 100%; }
            &.open:after { content: none; }
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100px;
                background-image: linear-gradient(to bottom, rgba(255,255,255,0),#fff 100%);
                z-index: 1;
            }
        }
        .btn-theme { display: block; }
    }

    .selectWindow {
        &--single {
            width: 100%;
        }
    }

    .productProperties {
        .b-title {
            margin-bottom: 12px;
            font-size: 20px;
        }
    }

    .productProperty {
        flex-wrap: wrap;
        &__title, &__value {
            padding: 5px 0;
        }
        &__title {
            font-family: Roboto-Medium;
            width: 100%;
            max-width: 100%;
            display: flex;
            &:after {
                content: ':';
                width: auto;
                border: none;
                margin-left: 2px;
            }
            span {
                padding: 0;
            }
        }
        &__value {
            span {
                line-height: 22px;
            }
        }
        & + & {
            margin-top: 10px;
        }
    }
}
