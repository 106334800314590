.help {
    &Content {
        max-width: 1015px;
    }
    &Search {
        &__text {
            display: flex;
            align-items: center;
        }
        &__title {
            color: $nightrider;
            @extend %roboto-bold;
            font-size: 18px;
            margin-right: 50px;
        }
        &__hint {
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            font-style: italic;
        }
        &__form {
            display: flex;
            align-items: center;
            height: 38px;
            margin-top: 5px;
        }
        &__input {
            width: 100%;
            height: 100%;
            padding: 5px 20px;
            border: 1px solid #949494;
            margin-right: 30px;
            border-radius: 2px;
            color: $nightrider;
            @extend %roboto;
            font-size: 14px;
            outline: none;
            &:focus, &:active {
                border-color: $blue;
            }
        }
        &__submit {
            height: 100%;
        }
    }
    &Section {
        margin-top: 40px;
        &__title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
    &Nav {
        display: flex;
        align-items: flex-start;
        min-height: 340px;
        height: 340px;
        border: 1px solid #ddd;
        background-color: $white;
        border-radius: 5px;
        &__left {
            min-width: 255px;
            height: 100%;
            padding: 10px 15px;
            border-right: 1px solid #e2e2e2;
            background-color: #f3f3f3;
            &Title {
                color: $nightrider;
                @extend %roboto-bold;
                font-size: 15px;
            }
        }
        &__right {
            padding: 20px 30px;
            &Title {
                color: $nightrider;
                @extend %roboto;
                font-size: 18px;
            }
        }
        &__list {
            margin-top: 10px;
        }
        &__item {
            &Link {
                color: $nightrider;
                @extend %roboto;
                font-size: 14px;
            }
            & + & {
                margin-top: 6px;
            }
        }
        &__links {
            margin-top: 10px;
        }
    }
    &Clients {
        &__title {
            color: $nightrider;
            @extend %roboto-bold;
            font-size: 24px;
            margin: 0;
        }
        &__inner {
            display: flex;
            align-items: flex-start;
            margin-top: 20px;
        }
        &__content {
            width: 100%;
            max-width: 730px;
        }
    }
    &Sidebar {
        width: 100%;
        max-width: 255px;
        padding-right: 30px;
        &Theme {
            border: 1px solid #E1E1E1;
            background-color: $white;
            border-radius: 5px;
        }
        &Parts {
            &__headline {
                padding: 10px 12px 12px;
                border-bottom: 1px solid #ddd;
            }
            &__back {
                display: flex;
                align-items: center;
                color: #004B91;
                @extend %roboto;
                font-size: 13px;
                &:before {
                    content: '';
                    display: block;
                    width: 6px;
                    height: 9px;
                    min-width: 6px;
                    min-height: 9px;
                    background: url("../img/sprite.svg") -70px 0;
                    margin-right: 7px;
                }
            }
            &__content {
                padding: 15px 25px;
            }
            &__title {
                color: $nightrider;
                @extend %roboto-medium;
                font-size: 16px;
                line-height: 20px;
            }
            &__list {
                margin-top: 12px;
            }
            &__item {
                display: flex;
                & + & {
                    margin-top: 7px;
                }
            }
            &__link {
                color: $nightrider;
                @extend %roboto;
                font-size: 13px;
                &.active {
                    @extend %roboto-medium;
                }
            }
        }
        &Quick {
            padding: 15px 25px;
            margin-top: 35px;
            &__title {
                color: $nightrider;
                @extend %roboto-medium;
                font-size: 18px;
                line-height: 20px;
            }
            &__list {
                margin-top: 18px;
            }
            &__item {
                display: flex;
                align-items: flex-start;
                position: relative;
                &ImgWrap {
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    background-color: #ddd;
                    margin-right: 28px;
                }
                &Title {
                    color: $nightrider;
                    @extend %roboto-medium;
                    font-size: 13px;
                    line-height: 15px;
                }
                &Text {
                    color: #767676;
                    @extend %roboto;
                    font-size: 12px;
                    line-height: 14px;
                    margin-top: 5px;
                }
                &Link {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                & + & {
                    margin-top: 25px;
                }
            }
        }
    }
    &Article {
        margin-top: 35px;
        &__title {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 28px;
            line-height: 32px;
            margin: 0;
        }
        &__breadcrumbs {
            padding: 10px 0;
        }
        &__link {
            display: flex;
            align-items: center;
            color: #004B91;
            @extend %roboto;
            font-size: 12px;
            &:after {
                content: '';
                display: flex;
                width: 7px;
                min-width: 7px;
                height: 12px;
                margin: 2px 0 0 8px;
                background: url("../img/sprite.svg") 0 -1424px;
            }
        }
        &__description {
            color: #555;
            @extend %roboto;
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 15px;
            margin-top: 15px;
            border-bottom: 1px solid #ddd;
        }
        &__text {
            color: $nightrider;
            @extend %roboto;
            font-size: 13px;
            margin: 12px 0 15px;
        }
        &__subTitle {
            color: $nightrider;
            @extend %roboto-medium;
            font-size: 21px;
            margin-top: 20px;
        }
        &__list {
            padding-left: 40px;
            margin: 10px 0 30px;
        }
        &__item {
            list-style-type: disc;
            color: $nightrider;
            @extend %roboto;
            font-size: 13px;
            & + & {
                margin-top: 5px;
            }
        }
    }
    &Delivery {
        &Rules {
            margin: 30px 0 15px;
        }
        &RuleItem {
            padding: 14px 0 30px;
            border-top: 1px solid #ddd;
            &__inner {
                display: flex;
                align-items: flex-start;
            }
            &__title {
                color: $nightrider;
                @extend %roboto-medium;
                font-size: 13px;
                line-height: 16px;
                min-width: 180px;
                margin-right: 20px;
            }
            &__links {
                display: flex;
                flex-direction: column;
                .link + .link {
                    margin-top: 4px;
                }
            }
        }
        &TrackNotice {
            width: 100%;
            max-width: 630px;
            padding: 10px 65px 15px;
            border: 1px solid #AFCBD6;
            margin-bottom: 18px;
            background-color: #FAFBFC;
            border-radius: 5px;
            &__title {
                color: $nightrider;
                @extend %roboto;
                font-size: 13px;
                line-height: 18px;
                margin-bottom: 10px;
            }
            .btn-theme {
                min-width: 160px;
            }
        }
    }
}

.isHelpful {
    width: 100%;
    max-width: 350px;
    padding: 15px 18px;
    border: 1px solid $nobel;
    color: $nightrider;
    @extend %roboto;
    font-size: 13px;
    background-color: $white;
    border-radius: 2px 2px 0px 0px;
    &__title {
        line-height: 16px;
    }
    &__btns {
        display: flex;
        align-items: center;
        margin-top: 14px;
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 43px;
        padding: 5px;
        border: 1px solid #ACB0B6;
        margin-right: 10px;
        background-color: #F3F4F6;
        border-radius: 2px;
        color: $nightrider;
        &:hover {
            color: $nightrider;
            background-color: #e1e8f6;
        }
    }
}