@media screen and (max-width: 1200px) {
    .shopsContent {
        padding-right: 40px;
        padding-left: 40px;
    }

    .shopsProductItem {
        .shortInfoPrice {
            align-items: flex-start;
            &__discount {
                margin-left: 10px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .shopsProductItem {
        &__imgWrap {
            min-width: 240px;
            max-width: 240px;
        }
        .shortInfoPrice {
            &__wrap {
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 959px) {
    .shopsContent {
        padding-right: 0;
        padding-left: 0;
    }

    .shopsProductItem {
        &__inner {
            position: relative;
        }
        &__imgWrap {
            min-width: 210px;
            max-width: 210px;
            min-height: 130px;
            max-height: 130px;
        }
        .productInfo {
            &__reviews {
                .link {
                    font-size: 14px;
                }
            }
        }
        .shortInfoPrice {
            align-items: center;
            flex-wrap: nowrap;
            position: absolute;
            bottom: 20px;
            left: 0;
            &__wrap {
                margin-top: 0;
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .shopsBrand {
        display: none;
    }

    .shopsHeadline {
        &__shop {
            display: none;
        }
        &__dropdown {
            max-width: 100%;
            .dropdownComponent {
                width: auto;
                &__item {
                    padding: 5px 10px;
                    text-align: left;
                }
            }
        }
        .dropdownComponent.mobile {
            display: block;
            margin-right: 40px;
        }
    }

    .shopsProductItem {
        &__imgWrap {
            min-height: 210px;
            max-height: 210px;
        }
        .shortInfoPrice {
            align-items: center;
            flex-wrap: wrap;
            position: static;
            &__wrap {
                flex-wrap: wrap;
                padding: 10px 0;
            }
        }
    }
}

@media screen and (max-width: 539px) {
    .shopsProductItem {
        &__inner {
            padding-bottom: 10px;
        }
        &__imgWrap {
            min-width: 130px;
            max-width: 130px;
            min-height: 130px;
            max-height: 130px;
        }
        .productInfo {
            &__title {
                font-size: 14px;
                line-height: 20px;
            }
            &__reviews {
                margin-left: 0;
            }
            .rating {
                margin-right: 15px;
            }
        }
        .shortInfoPrice {
            &__value {
                font-size: 24px;
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .shopsProductItem {
        &__inner {
            padding-bottom: 50px;
        }
        &__imgWrap {
            min-width: 110px;
            max-width: 110px;
            min-height: 110px;
            max-height: 110px;
            margin-right: 20px;
        }
        .productInfo {
            &__title {
                font-size: 13px;
                line-height: 20px;
            }
        }
        .shortInfoPrice {
            align-items: center;
            flex-wrap: nowrap;
            position: absolute;
            bottom: 20px;
            left: 0;
            &__value {
                font-size: 20px;
            }
            &__oldValue {
                font-size: 14px;
            }
            &__wrap {
                margin-top: 0;
            }
        }
    }
}