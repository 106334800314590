@media screen and (max-width: 1600px) {
    .myOrders {
        &Params {
            &__title {
                &:nth-child(2) {
                    width: 345px;
                }
            }
        }
    }
    .myOrderItem {
        &__seller {
            width: 280px;
            max-width: 280px;
            margin-right: 80px;
        }
    }
}

@media screen and (max-width: 1440px) {
    .myOrders {
        &Params {
            &__title {
                &:nth-child(2) {
                    width: 265px;
                }
                &:nth-child(5) {
                    margin-left: 38px;
                }
            }
        }
    }
    .myOrderItem {
        &__seller {
            width: 200px;
            max-width: 200px;
        }
        &__payment {
            margin-right: 20px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .myOrders {
        &Headline {
            margin-bottom: 90px;
        }
        &LatePayment {
            right: auto;
            bottom: -70px;
            left: 0;
        }
        &Filters {
            &__dropdowns {
                bottom: 50px;
            }
        }
        &Params {
            &__title {
                &:nth-child(2) {
                    width: 225px;
                }
                &:nth-child(4) {
                    margin-left: 40px;
                }
            }
        }
    }
    .myOrderItem {
        &__inner {
            padding-bottom: 55px;
        }
        &__seller {
            margin-right: 40px;
        }
        &__delivery {
            margin-right: 20px;
        }
        &__details {
            position: absolute;
            left: 0;
            bottom: 20px;
        }
        &__return {
            margin-left: 240px;
        }
    }
}

@media screen and (max-width: 991px) {
    .myOrders {
        &Headline {
            .b-title {
                white-space: nowrap;
            }
        }
        &Search {
            max-width: 100%;
            &__input {
                max-width: 100%;
            }
            .btn-theme {
                min-width: 140px;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .myOrders-container {
        max-width: 768px;
        padding-right: 10px;
        padding-left: 10px;
    }
    .myOrders {
        &Headline {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
            .b-title {
                margin-bottom: 20px;
            }
        }
        &LatePayment {
            bottom: -78px;
            left: 2px;
            z-index: 1;
        }
        &Filters {
            margin-bottom: 20px;
            .b-navigation {
                display: none;
            }
            &__dropdowns {
                display: none;
            }
            .mobileFilters {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #eee;
                border-radius: 2px;
                padding: 20px 30px;
            }
        }
        &Params {
            display: none;
        }
    }
    .myOrderItem {
        &__inner {
            flex-direction: column;
        }
        &__text {
            padding-right: 20px;
            white-space: normal;
            & + div {
                margin-top: 0;
            }
        }
        &__numbers {
            width: 100%;
            max-width: 100%;
            margin: 0 0 10px;
        }
        &__seller {
            width: 100%;
            max-width: 100%;
            margin: 0 0 10px;
            &:before {
                content: 'Продавец';
                display: block;
                color: #333;
                font-family: Roboto-Medium;
                font-size: 13px;
            }
            .b2b {
                right: auto;
                left: 70px;
            }
        }
        &__delivery {
            width: 100%;
            max-width: 100%;
            margin-bottom: 10px;
            & > div:first-child {
                font-family: Roboto-Medium;
            }
        }
        &__payment {
            width: 100%;
            max-width: 100%;
            margin-bottom: 10px;
            & > div:first-child {
                font-family: Roboto-Medium;
            }
        }
        &__postponement {
            &:before {
                content: 'Отсрочка';
                display: block;
                color: #333;
                font-family: Roboto-Medium;
                font-size: 13px;
            }
            .postponementIndicator {
                &:before {
                    margin-bottom: 0;
                }
            }
        }
        &__details {
            width: 100%;
            .btn-theme {
                width: 100%;
            }
        }
        &__return {
            margin: 0 0 60px;
        }
    }
}

@media screen and (max-width: 440px) {
    .myOrders {
        &LatePayment {
            bottom: -148px;
        }
        &Filters {
            margin-bottom: 60px;
        }
    }
    .myOrderItem {
        &__return {
            &Item {
                flex-direction: column;
                align-items: flex-start;
            }
            &Link {
                width: 100%;
                .btn-theme {
                    margin-top: 10px;
                }
            }
        }
    }
}