.category{
    &File {
        margin: 30px 0;
        &__wrap {
            display: flex;
        }
    }
    &Tree {
        width: 50%;
        &__header {
            @extend %roboto-medium;
            font-size: 18px;
        }
    }
    &Nodes {
        display: none;
        &.open {
            display: block;
        }
    }
    &Node {
        margin-left: 15px;
    }
    &Selected{
        width: 50%;
        display: none;
        padding: 0 40px;
        border-left: 1px solid $lightgrey;
        &.open {
            display: block;
        }
        &__name {
            @extend %roboto-medium;
            font-size: 26px;
        }
        &__group{
            & + & {
                margin-top: 15px;
            }
        }
        &__link {
            margin-top: 5px;
        }
        &__text {
            @extend %roboto;
        }
    }
}

.reportLog {
    display: inline-block;
    min-width: 100%;
    .divLog {
        border: 2px solid #000;
        width: fit-content;
        text-align: center;
        cursor: pointer;
        &:after {
            content: "\21D3";
        }
    }
    .listLog {
        border: 1px solid $darkblue;
        display: none;
        &.open {
            display: block;
        }
        &:after {

        }
    }
}
