@media screen and (max-width: 1199px) {
    .checkoutBlock {
        margin-top: 20px;
    }
    .optionsOfPackage {
        max-width: 560px;
        &__numbers {
            max-width: 500px;
        }
        &__address {
            flex-direction: column;
            .optionsOfPackage {
                &__links {
                    margin-top: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 919px) {
    .checkoutInfo {
        position: static;
        margin-top: 20px;
    }
    .checkoutBlock {
        margin-top: 40px;
    }
}

@media screen and (max-width: 767px) {
    .checkoutOptions {
        padding-top: 180px;
        .checkoutInfo {
            width: 100%;
            z-index: 100;
        }
    }
    .packedPackages {
        .cartPackage {
            &__inner {
                padding-bottom: 80px;
            }
            &__toggle {
                bottom: 20px;
            }
        }
    }
    .optionsOfPackage {
        &Popup {
            left: -150px;
        }
    }
}

@media screen and (max-width: 600px) {
    .optionsOfPackage {
        max-width: 510px;
        &__numbers {
            max-width: 440px;
        }
        &Popup {
            width: 400px;
            max-width: 400px;
        }
    }
}

@media screen and (max-width: 539px) {
    .optionsOfPackage {
        &__name {
            width: 100px;
            min-width: 110px;
        }
        &__links {
            align-items: flex-start;
            flex-direction: column;
            .link + .link {
                margin-top: 5px;
                margin-left: 0;
            }
        }
        &Popup {
            left: -130px;
        }
    }
}

@media screen and (max-width: 479px) {
    .orderProcessingHeadline {
        &__apply {
            padding: 15px 20px;
            font-family: Roboto-Medium;
            font-size: 14px;
            width: 100%;
            top: 80px;
        }
    }
    .checkoutOptions {
        padding-top: 230px;
        &__delivery {
            .btn-theme {
                margin-top: 10px;
            }
            .btn-theme + .btn-theme {
                margin-left: 0;
            }
        }
        &__addAddress {
            display: block;
            max-width: 182px;
        }
        .checkoutInfo {
            margin-top: 60px;
        }
    }
}

@media screen and (max-width: 439px) {
    .optionsOfPackage {
        &__numbers {
            max-width: 280px;
        }
        &__value {
            min-width: 0;
            max-width: 100%;
        }
        &__paytype, &__deltype {
            flex-direction: column;
            .optionsOfPackage {
                &__links {
                    margin-top: 10px;
                }
            }
        }
        &__radiobuttons {
            margin-left: -22px;
        }
        &Popup {
            width: 300px;
            max-width: 300px;
            left: -135px;
        }
    }
    .checkoutInfo {
        &__line {
            & + & {
                margin-top: 8px;
            }
        }
        &__param {
            white-space: normal;
            width: 100px;
            min-width: 100px;
            max-width: 100px;
        }
        &__title {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
        }
        .b2b {
            &:after {
                width: 34px;
                height: 22px;
                font-size: 12px;
            }
        }
    }
    .checkout-progress {
        display: none;
    }
}
